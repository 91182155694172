import React, {FC, useContext} from "react";
import {useToggle} from "../../../utils/hooks/useToggle";
import {ModalContext} from "../../../components/modal/ModalContext/ModalContext";
import {FeedbackPopup} from "./FeedbackPopup";

type TestModeNotificationProps = {};

export const TestModeNotification: FC<TestModeNotificationProps> = (props) => {
	const [isOpen, toggleIsOpen] = useToggle(true);
	//@ts-ignore

	const {handleModal} = useContext(ModalContext);

	if (!isOpen || localStorage.getItem("test-mode-info")) return null;
	return (
		<div className='bg-blue pt-12 pb-14 pl-32 pr-8 T-white T14-bold'>
			<div className='row between middle'>
				<p>
					Atips работает в тестовом режиме.{" "}
					<span
						style={{display: "inline"}}
						className='link-underline pointer'
						onClick={() => handleModal(<FeedbackPopup />)}>
						Напишите нам
					</span>{" "}
					и помогите стать еще лучше!
				</p>
			</div>
		</div>
	);
};
