import React, {FC, InputHTMLAttributes} from "react";
import cn from "classnames";
import {Tooltip} from "../tooltip/Tooltip";
export type LabelProps = InputHTMLAttributes<HTMLLabelElement> & {
	label?: string;
	className?: string;
	forgotPass?: boolean;
	labelTip?: any;
};

export const Label: FC<LabelProps> = (props) => {
	const {name, label, className, forgotPass, labelTip} = props;

	if (!label) return null;

	return (
		<label
			htmlFor={name}
			className={cn("label flex justify-justify ", className)}>
			{label && label}

			{forgotPass && (
				<a className='form-field-label__link' href={"/forgot-password"}>
					Забыли пароль?
				</a>
			)}
			{labelTip && (
				<div className='tooltip-wrap' style={{height: "20px"}}>
					<Tooltip text={labelTip} />
					<svg
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<g clipPath='url(#clip0_8463_51755)'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M17.1666 10.0002C17.1666 13.9582 13.958 17.1668 9.99992 17.1668C6.04188 17.1668 2.83325 13.9582 2.83325 10.0002C2.83325 6.04212 6.04188 2.8335 9.99992 2.8335C13.958 2.8335 17.1666 6.04212 17.1666 10.0002ZM19.1666 10.0002C19.1666 15.0628 15.0625 19.1668 9.99992 19.1668C4.93731 19.1668 0.833252 15.0628 0.833252 10.0002C0.833252 4.93755 4.93731 0.833496 9.99992 0.833496C15.0625 0.833496 19.1666 4.93755 19.1666 10.0002ZM10.3556 6.8335C9.81573 6.8335 9.4079 7.15297 9.26836 7.5212L8.91399 8.4563L7.04378 7.74757L7.39815 6.81246C7.84735 5.6271 9.0281 4.8335 10.3556 4.8335C12.0254 4.8335 13.4999 6.11215 13.4999 7.8335C13.4999 8.52406 13.3225 9.20638 12.8723 9.76633C12.4248 10.3229 11.7885 10.6582 11.0556 10.8091C11.0378 10.8701 11.0207 10.9673 11.0108 11.118C11.0003 11.2772 10.9999 11.4518 10.9999 11.6668V12.6668H8.99992V11.6668L8.99987 11.608C8.99939 11.2368 8.99869 10.6845 9.15 10.2013C9.23723 9.92276 9.38756 9.61717 9.65384 9.35735C9.92645 9.09134 10.2652 8.92749 10.6393 8.85284C11.0305 8.77475 11.2173 8.63291 11.3136 8.51316C11.4122 8.39051 11.4999 8.18606 11.4999 7.8335C11.4999 7.3457 11.0544 6.8335 10.3556 6.8335ZM10.8333 14.1668C10.8333 14.6271 10.4602 15.0002 9.99992 15.0002C9.53968 15.0002 9.16659 14.6271 9.16659 14.1668C9.16659 13.7066 9.53968 13.3335 9.99992 13.3335C10.4602 13.3335 10.8333 13.7066 10.8333 14.1668Z'
								fill='#94A0B1'
							/>
						</g>
						<defs>
							<clipPath id='clip0_8463_51755'>
								<rect width='20' height='20' fill='white' />
							</clipPath>
						</defs>
					</svg>
				</div>
			)}
		</label>
	);
};
