export const onInputTextAreaChange = (evt, objName, state, setState) => {
	// evt - event, objName - значение поля, следующего изменить (предусмотрена вложенность, два значения передаются черед запятую)
	// state, setstate - изменение самого состояния
	const value = evt.target.value; // присваимваем значение инпута;
	const arr = objName.split(",");
	if (arr?.length === 1) {
		setState({ ...state, [arr[0]]: value });
	} else if (arr?.length === 2) {
		setState({ ...state, [arr[0]]: { ...state[arr[0]], [arr[1]]: value } });
	}
};

export const handleFilteredClick = (type, value, mainFilterState, setMainFilterState) => {
	if (type === "search") {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, title: value },
		});
	} else if (type === "sort") {
		if (mainFilterState?.order_by === value && !!mainFilterState?.order_dir) {
			if (mainFilterState.order_dir == "up") {
				setMainFilterState({
					...mainFilterState,
					order_by: value,
					order_dir: "down",
					page: 1,
				});
			} else {
				setMainFilterState({
					...mainFilterState,
					order_by: value,
					order_dir: "up",
					page: 1,
				});
			}
		} else {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				order_dir: "down",
				page: 1,
			});
		}
	} else if (value.length === 0) {
		if (!!mainFilterState.filters && type in mainFilterState.filters) {
			let objectCopy = { ...mainFilterState, page: 1 };
			delete objectCopy.filters[type];
			setMainFilterState(objectCopy);
		}
	} else if (type === "format" || type === "genres" || type === "area" || type === "areaId") {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	} else if (type === "year") {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	}
};

export const handleSelectFilters = (
	event,
	filterKey,
	item,
	deleteState = null,
	setDeleteState = null,
	dropDownState,
	setDropdownState,
	mainState,
	setMainState
) => {
	event.stopPropagation();
	if (deleteState !== null && setDeleteState !== null) {
		setDeleteState({ ...deleteState, currentValue: [] });
	}
	handleFilteredClick(filterKey, item, mainState, setMainState);
	setDropdownState({ ...dropDownState, [filterKey]: !dropDownState[filterKey] });
};

export const handleMultiSelectActiveClick = (evt, localState, setLocalState, item) => {
	evt.stopPropagation();
	const current = evt.currentTarget;
	let timeArray = localState.currentValue;
	let newArray = timeArray;
	if (current.classList.contains("selected")) {
		!!item.id
			? (newArray = timeArray.filter((elem) => item.id !== elem))
			: (newArray = timeArray.filter((elem) => item !== elem));
	} else {
		!!item.id ? newArray.push(item.id) : newArray.push(item);
	}
	// current.classList.toggle("selected");
	setLocalState({ ...localState, currentValue: newArray });
};

export const handleToggleOpenClick = (evt) => {
	evt.stopPropagation();
	const current = evt.currentTarget;
	const parrent = current.parentNode;
	parrent.classList.toggle("open");
};

export const handleToggleActiveClick = (e) => {
	const elem = e.target;
	elem.classList.toggle("active");
};
