import cn from "classnames";
import React, {ButtonHTMLAttributes, FC, createElement} from "react";
import {IconsMap, createIcon} from "../Icons/IconsMap";
import {Link} from "react-router-dom";

//

export type IconBtnProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	icon: keyof typeof IconsMap;
	svgProps?: any;
	className?: string;
	link?: string;
	size?: "xxs" | "xs" | "s" | "m";
	isRound?:boolean;
};

export const IconButton: FC<IconBtnProps> = (props) => {
	const {icon, svgProps, className, link, size,isRound, ...rest} = props;

	const baseClass = "icon-btn";

	return createElement(
		link ? Link : "button",
		{
			type: "button",
			...Object.assign(rest),
			className: cn("icon-button", baseClass, className, {
				[`${baseClass}--${size}`]: size,
				'circle':isRound
			}),
			to: link,
		},
		createIcon({icon, svgProps}),
	);
};
