// import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { AuthorizationReducer, AuthUserReducer } from "./AuthorizationReducer.js";
import {
	LoadingReducer,
	MainLoadingReducer,
	SaveReducer,
	StatisticLoaderReducer,
} from "./LoadingReducer.js";
import {
	MainStatisticCitiesByCountryReducer,
	MainStatisticUsersAgesReducer,
	MainStatisticUsersCitiesReducer,
	MainStatisticUsersCountriesReducer,
	MainStatisticUsersGenderReducer,
} from "./MainStatisticReducer.js";
import { AttributesReducer } from "./AttributesReducer.js";

const createRootReducer = (history) =>
	combineReducers({
		// router: connectRouter(history), // состояние роутера
		login: AuthorizationReducer, // состояние авторизации

		loadingStatus: LoadingReducer, // статус загрузки (true / false)
		mainLoader: MainLoadingReducer, // статус главной подгрузки (получкние, обновление) списков данных (список видео, список статистики и т.д....)
		saveMoment: SaveReducer, // Индикатор сохранения (true/false)
		statisticLoadingStatus: StatisticLoaderReducer, // статус загруки списка статистики (true / false)

		mainStatisticUsersAges: MainStatisticUsersAgesReducer,
		mainStatisticUsersGenderReducer: MainStatisticUsersGenderReducer,
		mainStatisticUsersCountries: MainStatisticUsersCountriesReducer,
		mainStatisticUsersCities: MainStatisticUsersCitiesReducer,
		// TODO
		mainStatisticCitiesByCountry: MainStatisticCitiesByCountryReducer,

		attributesReducer: AttributesReducer,

		authUser: AuthUserReducer, // сущность авторизованного пользователя
	});

export default createRootReducer;
