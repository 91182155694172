import React, {FC, InputHTMLAttributes} from "react";
import cn from "classnames";
import {InputActions} from "./Input";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
	inputActions: InputActions;
	label: string;
	className?: string;
	onClick?: () => void;
};
export const Checkbox: FC<CheckboxProps> = ({
	label,
	className,
	onClick,
	inputActions,
	...rest
}) => {
	return (
		<div className={cn(`checkbox-wrap`, className)}>
			<input
				id={rest.name}
				type='checkbox'
				className='checkbox'
				{...inputActions.register(rest?.name, {
					...rest,
				})}
			/>
			{/* {label && (
				<label
					className='checkbox-label'
					htmlFor={rest.name}
					dangerouslySetInnerHTML={{
						__html: label,
					}}
				/>
			)} */}
			{label && (
				<>
					{typeof label === "string" ? (
						<label
							className='checkbox-label'
							htmlFor={rest.name}
							dangerouslySetInnerHTML={{
								__html: label,
							}}
						/>
					) : (
						<label className='checkbox-label'>{label}</label>
					)}
				</>
			)}
		</div>
	);
};
