import * as React from "react";
import cn from "classnames";

interface ITextareaProps {
	[prop: string]: any;
}

export const Textarea: React.FunctionComponent<ITextareaProps> = ({
	inputClass,
	inputActions,
	pattern = false,
	error,
	placeholder = "",
	style = {},
	...rest
}) => {
	return (
		<textarea
			type='text'
			className={cn("input textarea pv-16 ph-20", inputClass)}
			{...(inputActions
				? inputActions?.register(rest?.name, {
						...rest,
				  })
				: rest)}
			style={style}
			autoComplete='off'
			placeholder={placeholder}
		/>
	);
};
