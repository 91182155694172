import {useEffect, useState, useCallback} from "react";

export const useMounted = (): [boolean, any] => {
	const [mounted, setMounted] = useState(false);

	// const toggle = useCallback(() => {
	// 	setMounted((v) => !v);
	// }, []);

	useEffect(() => {
		setMounted(true);
	}, []);

	return [mounted, setMounted];
};
