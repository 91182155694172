import {getUserId} from "../constants";
import {User} from "../../ts/interfaces/index";
import {useCustomMutation} from "../useCustomMutation";

export const useUpdateUser = (props: any) => {
	const {mutation} = useCustomMutation<User, unknown, Partial<User>>({
		baseURLKey: "seller",
		url: `/users/${getUserId()}`,
		method: "patch",
		...props,
	});

	const updateUser = (data: Partial<User>) => {
		mutation.mutate(data);
	};

	return {updateUser};
};
