import {InputWithMask} from "../../ui-kit/forms/InputWithMask";

const PASS_FIELD = {
	type: "password",
	required: true,
};
export const {EMAIL, PASS, PASS_REPEAT, PASS_NEW} = {
	EMAIL: {
		label: "Email",
		name: "email",
		required: true,
	},
	PASS: {
		label: "Пароль",
		name: "password",
		forgotPass: true,
		...PASS_FIELD,
	},
	PASS_NEW: {
		label: "Новый пароль",
		name: "password",
		...PASS_FIELD,
	},
	PASS_REPEAT: {
		label: "Подтвердите пароль",
		name: "password_repeat",
		...PASS_FIELD,
	},
};

export const getUserFields = ({isProfilePage}: {isProfilePage?: boolean}) => {
	let sellerFields: any = {
		step1: {
			title: "Контактные данные",
			fields: [
				{
					label: "Название организации",
					name: "name",
				},
				{
					label: "ИНН",
					name: "inn",
					size: "1/2",
					type: "number",
					bottomLink: {
						text: "Заполнить по ИНН",
						link: "#",
					},
					component: InputWithMask,
					minLength: 10,
					mask: "9999999999",
				},
				{
					label: "ОГРН или ОГРНИП",
					name: "ogrn",
					size: "1/2",
					mask: "999999999999999",
					component: InputWithMask,
					minLength: 15,
				},
				{
					label: "Юридический адрес",
					name: "legal_address",
				},
				{
					label: "Фактический адрес",
					name: "actual_address",
				},
				{
					label: "ФИО гендиректора или представителя по доверенности",
					name: "ceo_name",
				},
				{
					label: "ФИО контактного лица",
					name: "contact_name",
					size: isProfilePage && "1/2",
				},
				{
					label: "Телефон контактного лица",
					name: "contact_phone_number",
					component: InputWithMask,
					minLength: 14,
					mask: "+7\\ 999 9999999",
					placeholder: "+7",
					maskPlaceholder: "+7",
					size: isProfilePage && "1/2",
				},
			],
		},
		step2: {
			title: "Аккаунт",
			fields: [
				{
					label: isProfilePage ? "Email" : "Email для входа",
					name: "email",
				},
				!isProfilePage && {
					label: "Придумайте пароль",
					name: "password",
					type: "password",
				},
				!isProfilePage && {
					label: "Повторите пароль",
					name: "password_repeat",
					type: "password",
				},
			],
		},
		step3: {
			title: "Банковские реквизиты",
			fields: [
				{
					label: "ИНН",
					name: "bank_inn",
					type: "number",
					size: isProfilePage && "1/2",
					mask: "9999999999",
					component: InputWithMask,
					minLength: 10,
				},
				{
					label: "КПП",
					name: "kpp",
					type: "number",
					size: isProfilePage && "1/2",

					mask: "999999999",
					component: InputWithMask,
					minLength: 9,
				},
				{
					label: "Расчетный счет",
					name: "checking_account",
					size: "1/2",
					type: "number",
					mask: "99999999999999999999",
					component: InputWithMask,
					minLength: 20,
				},
				{
					label: "Банк",
					name: "bank_name",
					size: "1/2",
				},
				{
					label: "Корреспондентский счет",
					name: "correspondent_account",
					size: "1/2",
					type: "number",
					mask: "99999999999999999999",
					component: InputWithMask,
					minLength: 20,
				},
				{
					label: "БИК",
					name: "bik",
					size: "1/2",
					type: "number",
					mask: "999999999",
					component: InputWithMask,
					minLength: 9,
				},
			],
		},
	};

	if (isProfilePage) {
		sellerFields.step4 = {
			title: "Кабинет продавца Ozon",
			fields: [
				{
					label: "Client ID",
					name: "ozon_client_id",
					type: "string",
					size: "1/2",
					required: false,
				},
				{
					label: "API key",
					name: "ozon_api_key",
					type: "text",
					size: "1/2",
					required: false,
				},
			],
		};
	}

	return sellerFields;
};

export const profilePageFields = getUserFields({isProfilePage: true});
