import React from "react";
// import Lottie from "react-lottie";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

import SmallLoader from "./small_loader.json";

type LoaderProps = {
	size?: number;
};
export const Loader = ({size = 20}: LoaderProps) => {
	return (
		<Lottie
			height={size}
			width={size}
			animationData={SmallLoader}
			loop={true}
			autoPlay={true}
			rendererSettings={{preserveAspectRatio: "xMidYMid slice"}}
			play
			style={{width: 20, height: 20}}
			className='loader'
		/>
	);
};
