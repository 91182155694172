import { gql } from "@apollo/client";

export const GET_STATISTIC = gql`
	query getStatisticPage(
		$page: Int
		$limit: Int
		$user_id: Int!
		$lte: DateTime
		$gte: DateTime
		$format_id: Int
		$category_id: Int
		$seller_id: Int
		$order_by: StatisticSortFields!
		$direction: SortDirection!
		$sex_id: Int
		$age_id: Int
		$country_id: Int
		$city_id: Int
		$click: Int
		$video_id: Int
		$genre_id: Int
		$product_id: Int
	) {
		getStatisticPage(
			pagination: {page: $page, limit: $limit}
			filter: {
				owner_id: $user_id
				seller_id: $seller_id
				category_id: $category_id
				format_id: $format_id
				created_at: {gte: $gte, lte: $lte}
				sex_id: $sex_id
				age_id: $age_id
				country_id: $country_id
				city_id: $city_id
				click: $click
				video_id: $video_id
				genre_id: $genre_id
				product_id: $product_id
			}
			sort: {field: $order_by, direction: $direction}
		) {
			items {
				tips_id
      price
      income
      percent
      platform
      platform_id
      seller
      seller_id
      seller_external
      product
      product_id
      category
      category_id
      video
      video_id
      format
      format_id
      genre
      genre_id
      moviegoer
      moviegoer_id
      moviegoer_external
      sex
      sex_id
      age
      age_id
      country
      country_id
      blogger
      blogger_id
      blogger_external
      city
      city_id
      click
      create_date
			}
			pageInfo {
				currentPage
				currentLimit
				itemsCount
				nextPageExist
			}
			aggregation {
				total_sum
			}
		}

		getSummaryItem(filter: {owner_id: $user_id}) {
			total_balance
		}
	}
`;
