import React, {useContext, FC, ReactNode, CSSProperties} from "react";
import cn from "classnames";

import {ModalContext} from "../../modal/ModalContext/ModalContext";

import {IconButton} from "../../../ui-kit/buttons/IconButton";
import {Button, ButtonProps} from "../../../ui-kit/buttons/Button";
import {Error, ErrorProps} from "../../../ui-kit/forms/Error";

import "./styles/index";

type ModalContentWrapperProps = ErrorProps & {
	className?: string;
	title?: string;
	headerChildren?: any;
	primaryButton?: ButtonProps;
	withCancelBtn?: boolean;
	children?: ReactNode;
	isAlignCenter?: boolean;
	style: CSSProperties;
	id?: string;
};
export const ModalContentWrapper: FC<ModalContentWrapperProps> = ({
	children,
	title,
	primaryButton,
	isAlignCenter,
	withCancelBtn,
	className,
	style = {},
	headerChildren,
	id,
	...rest
}) => {
	const {handleModal} = useContext<any>(ModalContext);

	return (
		<div
			style={style}
			id={cn(id)}
			className={cn(
				"modal-content__wrapper",
				{
					"modal-content__wrapper--centered": isAlignCenter,
				},
				className,
			)}>
			<div
				className={cn("modal-header", {
					"modal-header--with-title": title,
				})}>
				{title && (
					<h3 className='modal-title'>
						{title}
						{headerChildren}
					</h3>
				)}
				<IconButton
					icon='close'
					onClick={() => handleModal()}
					className='modal-close-btn'
					size='xs'
				/>
			</div>

			{children}
			{(primaryButton || withCancelBtn) && (
				<div
					className='modal__bottom-btns'
					style={rest?.error ? {flexWrap: "wrap"} : {}}>
					<Error {...rest} />
					{withCancelBtn && (
						<Button
							onClick={() => handleModal()}
							variant='secondary'
							text='Отменить'
							size='m'
						/>
					)}
					{primaryButton && (
						<Button size='m' variant='primary' {...primaryButton} />
					)}
				</div>
			)}
		</div>
	);
};
