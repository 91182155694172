import cn from "classnames";
import React, {
	FC,
	InputHTMLAttributes,
	useEffect,
	useState,
	useRef,
} from "react";
import { useToggle } from "../../utils/hooks/useToggle";
import { useClickOutside } from "../../utils/hooks/useClickOutside";

import { useForm, useFormState, useFieldArray } from "react-hook-form";
import { IconButton } from "../buttons/IconButton";
import { isArr } from "../../utils/helpers";
type OptionItem = {
	title: string;
	id: any;
};
export type SelectProps = InputHTMLAttributes<HTMLInputElement> & {
	inputActions: {
		register?: any;
		control?: any;
		remove?: any;
		append?: any;
		setValue?: any;
	};

	options: OptionItem[];
	isMultiple?: boolean;
	isSearch?: boolean;
	placeholder?: string;
	isLoading?: boolean;
	withRemoveBtn?: boolean;
	withCheckboxes?: boolean;
	value?: any;
};

export const Select: FC<SelectProps> = ({
	options,
	isMultiple,
	inputActions,
	isSearch,
	placeholder,
	isLoading,
	withRemoveBtn,
	withCheckboxes,
	...rest
}) => {
	const inputName = rest?.name;
	const localInputName = `${inputName}-local`;
	const inputValue = rest?.value;
	const { append, remove } = useFieldArray({
		control: inputActions.control,
		name: inputName,
	});
	const { control: localControl, watch, setValue: setLocalValue } = useForm();
	const localValues = watch();
	const localValue = localValues[localInputName];
	const { append: appendLocal, remove: removeLocal } = useFieldArray({
		control: localControl,
		name: localInputName,
	});
	const selectedOptionsArr: OptionItem[] = withCheckboxes
		? isArr(localValue) && localValue
		: isArr(inputValue) && inputValue;

	const [isOptionsOpen, toggleIsOptionsOpen] = useState(false);
	const ref = useRef();
	useClickOutside(ref, () => toggleIsOptionsOpen(false));

	const [searchValue, setSearchValue] = useState("");

	const setSelected = (option: OptionItem) => {
		!withCheckboxes && toggleIsOptionsOpen(!isOptionsOpen);
		if (isMultiple) {
			const isSelected =
				isArr(selectedOptionsArr) &&
				selectedOptionsArr?.find(({ id }) => id === option.id);

			!isSelected && withCheckboxes ? appendLocal(option) : append(option);
			setSearchValue("");
		} else {
			inputActions.setValue(inputName, option);
			setSearchValue(option?.title);
		}
	};

	function search(items) {
		return items?.filter(({ title }) => {
			return (
				title &&
				searchValue &&
				typeof searchValue === "string" &&
				title?.toString()?.toLowerCase()?.indexOf(searchValue?.toLowerCase()) >
				-1
			);
		});
	}

	const selectedTitle = withCheckboxes
		? localValue && isArr(localValue) && localValue?.length > 0
			? localValue?.map((option) => option?.title)?.join(", ")
			: placeholder
		: isMultiple
			? ""
			: inputValue?.title || inputValue;
	const opt =
		isSearch && searchValue !== selectedTitle && searchValue
			? options && search(options)
			: options;

	const getLocalSelectedIndex = (option): number => {
		const ids = localValue?.map((item: any) => item?.id);
		const index = ids.indexOf(option?.id);
		return index;
	};
	useEffect(() => {
		selectedTitle && setSearchValue(selectedTitle);
	}, [selectedTitle]);

	useEffect(() => {
		if (
			withCheckboxes &&
			(!localValue || localValue?.length === 0) &&
			inputValue
		) {
			setLocalValue(localInputName, inputValue);
		}
	}, [inputValue]);
	return (
		<>
			<div
				ref={ref}
				onClick={(e) => {
					toggleIsOptionsOpen(!isOptionsOpen);
				}}
				style={rest?.name === "gender" ? { paddingRight: "36px" } : null}
				className={cn("chosen-container chosen-container-single select", {
					active: isOptionsOpen,
					"input-disabled": rest?.disabled,
				})}>
				{((!isMultiple && !isSearch) || withCheckboxes) && (
					<button
						className={cn("chosen-single", {
							"chosen-multiple": withCheckboxes,
						})}
						type='button'
						aria-haspopup='listbox'
						aria-expanded={isOptionsOpen}>
						{/* @ts-ignore */}
						<span>
							{withCheckboxes ? selectedTitle : inputValue?.title || inputValue}
						</span>
					</button>
				)}
				{isMultiple && inputValue && !withCheckboxes && (
					<ul className='select__chosen-options'>
						{selectedOptionsArr?.map((option: OptionItem, i) => {
							return (
								<li className={cn("select__chosen-tag")} key={option.id}>
									<span
										dangerouslySetInnerHTML={{
											__html: option.title,
										}}
									/>
									<button
										onClick={(e) => {
											e.stopPropagation();
											remove(i);
										}}
										className='select__chosen-tag_remove-btn'
										type='button'
									/>
								</li>
							);
						})}
					</ul>
				)}
				{isSearch && (
					<input
						type='text'
						// autoFocus
						value={inputName === "country" ? options?.[0]?.title : searchValue}
						onChange={(e) => setSearchValue(e?.target?.value)}
						className='select-search'
						placeholder={selectedOptionsArr?.length > 0 ? "" : placeholder}
						style={{
							position: isMultiple ? "unset" : "absolute",
							background: "transparent",
							height: isMultiple ? "33px" : "unset",
							flex: "1",
							minWidth: "35%",
						}}
					/>
				)}

				{(!withCheckboxes && selectedOptionsArr?.length > 0
					? isMultiple || withRemoveBtn
					: withRemoveBtn && (inputValue?.length > 0 || inputValue?.title)) && (
						<IconButton
							size='xxs'
							className='select__remove-all-btn'
							icon='close_small'
							onClick={(e) => {
								e.stopPropagation();
								remove();
								inputActions.setValue(inputName, []);
							}}
						/>
					)}
				{/* <div
					className={cn("select-options-wrapper relative", {
						open: true, //isOptionsOpen
					})}> */}
				<ul
					className={`select-options ${isOptionsOpen ? "show" : "" //isOptionsOpen
						}`}
					role='listbox'
					tabIndex={-1}>
					<div>
						<div
							className={cn("select-options__inner scrollbar", {
								"pt-16 pb-24": withCheckboxes,
							})}>
							{isLoading && (
								<li className='select-options__item T-grey T-center T14'>
									Загрузка…
								</li>
							)}
							{!isLoading &&
								isArr(options) &&
								opt?.map((option, i) => {
									const isSelected =
										isArr(selectedOptionsArr) &&
										selectedOptionsArr?.find(({ id }) => id === option.id);
									return (
										<li
											id={option?.id}
											key={i}
											className={cn("select-options__item one-line", {
												"select-options__item--checkbox": withCheckboxes,
												selected: withCheckboxes && isSelected,
											})}
											role='option'
											tabIndex={0}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();

												const index = getLocalSelectedIndex(option);
												isSelected && withCheckboxes && removeLocal(index);

												!isSelected && setSelected(option);
											}}
											dangerouslySetInnerHTML={{
												__html: `<span>${option?.title}</span>`,
											}}
										/>
									);
								})}
						</div>
						{withCheckboxes && (
							<div className='row between select-options__fixed-bottom T15-list'>
								<span
									onClick={(e) => {
										e.stopPropagation();
										inputActions?.setValue(inputName, null);
										setLocalValue(localInputName, null);
									}}>
									Очистить
								</span>
								<span
									onClick={() => {
										inputActions.setValue(inputName, localValue);
										toggleIsOptionsOpen(false);
									}}
									className='T-blue'>
									Применить
								</span>
							</div>
						)}
					</div>
				</ul>

				{/* </div> */}
			</div>
		</>
	);
};
