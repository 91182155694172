import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getProduct } from "../../../../Actions/ProductsActions";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import "./styles/ModalBrightboxInfo.scss";
import { useGetProduct } from "../../../../api/product/index";

const ModalBrightboxInfo = ({
	itemState = {},
	paramsState,
	setParamsState,
	setStatisticOrderType,
	getProduct,
	currentProduct = {},
}) => {
	const { handleModal } = useContext(ModalContext);
	const navigate = useNavigate();
	const [productState, setProductState] = useState();
	const handleSelectOrderClick = (value) => {
		setStatisticOrderType(value);
		handleModal();
	};

	// useEffect(() => {
	// 	if (
	// 		!!itemState?.product?.id &&
	// 		currentProduct.id !== itemState?.product?.id
	// 	) {
	// 		getProduct(itemState?.product.id);
	// 	}
	// }, [itemState]);

	// useEffect(() => {
	// 	setProductState(currentProduct);
	// }, [currentProduct]);
	const { product, refetchProduct } = useGetProduct({ id: itemState?.product_id });
	const date = itemState?.create_date && new Date(itemState?.create_date);
	const moscowDate = date && new Date(date);
	const localeStringDate =
		moscowDate &&
		new Date(moscowDate).toLocaleTimeString([], {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
	return (
		<>
			{itemState ? (
				<div className="modal-field brightbox-info-modal active">
					<div className="modal-field-title">
						<b>Типс ID {itemState?.tips_id}</b>{" "}
						<small className={`brightbox-info-status ${itemState?.click ? "is-click" : ""}`}>
							{itemState?.click ? "Клик" : "Показ"}
						</small>{" "}
						<small>{localeStringDate}</small>
					</div>
					<div className="modal-field-close" onClick={() => handleModal()}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
								fill="#212121"
							></path>
						</svg>
					</div>
					<a
						href={product?.URL_SOURCE}
						onClick={() => handleModal()}
						target="_blank"
						className="brightbox-info-modal-content"
					>
						{product?.URL_HD_IMAGE && <img src={product?.URL_HD_IMAGE} alt="превью" />}
						<div className="brightbox-info-modal-content-data">
							{/* <span>Одежда / Женщинам / Верхняя одежда</span> */}
							<span>{product?.category?.title}</span>
							{itemState?.product}
							<b>{product?.price} ₽</b>
						</div>
					</a>
					<div className="brightbox-info-modal-info">
						<div className="brightbox-info-modal-info-item">
							<span>Владелец</span>{" "}
							<p>
								{itemState?.blogger && itemState?.blogger?.length > 0 ? itemState.blogger : "Atips"}
							</p>
						</div>
						<div className="brightbox-info-modal-info-item">
							<span>{itemState?.format}</span> <p>{itemState?.video}</p>
						</div>
						{/* <div
							className='brightbox-info-modal-info-item'
							onClick={() => (
								navigate(`/viewers-statistic?id=${itemState?.viewer?.id}`),
								handleModal()
							)}>
							<span>Зритель</span> <p>{itemState?.viewer?.title}</p>
						</div> */}
					</div>
					<div className="brightbox-info-modal-buttons">
						{/* {!!itemState?.product?.link ? (
							<a href={itemState?.product?.link} onClick={() => handleModal()} target="_blank">
								{" "}
								Объявление
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M15.0002 16.9999L15.0002 15.9999V10.4141L8.70738 16.707L8.00027 17.4141L6.58606 15.9999L7.29317 15.2928L13.586 8.99995L8.00028 8.99997L7.00028 8.99997L7.00027 6.99997L8.00027 6.99997L16.0002 6.99994C16.2655 6.99994 16.5198 7.10529 16.7073 7.29283C16.8949 7.48037 17.0002 7.73472 17.0002 7.99994L17.0002 15.9999V16.9999L15.0002 16.9999Z"
										fill="#212121"
									></path>
								</svg>
							</a>
						) : ( */}
						<a href={product?.URL_SOURCE} onClick={() => handleModal()} target="_blank">
							Объявление
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.0002 16.9999L15.0002 15.9999V10.4141L8.70738 16.707L8.00027 17.4141L6.58606 15.9999L7.29317 15.2928L13.586 8.99995L8.00028 8.99997L7.00028 8.99997L7.00027 6.99997L8.00027 6.99997L16.0002 6.99994C16.2655 6.99994 16.5198 7.10529 16.7073 7.29283C16.8949 7.48037 17.0002 7.73472 17.0002 7.99994L17.0002 15.9999V16.9999L15.0002 16.9999Z"
									fill="#212121"
								></path>
							</svg>{" "}
						</a>
						{/* )} */}

						{/* <Link
							to={`statistic`}
							onClick={() => (
								setParamsState({id: itemState?.atips, limit: 15, page: 1}),
								handleSelectOrderClick(-1)
							)}>
							Статистика типса{" "}
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M5 18V19H7V18L7 6V5H5V6L5 18ZM9 18V19H11V18L11 9V8L9 8V9L9 18ZM13 19V18L13 12V11H15V12L15 18V19H13ZM17 18V19H19V18V15V14H17V15V18Z'
									fill='#212121'></path>
							</svg>
						</Link> */}
					</div>
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
		currentProduct: state.currentProduct,
	};
};

const mapDispatchToProps = {
	getProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBrightboxInfo);
