import {useCallback, useState, Dispatch} from "react";

export const useToggle = (initialState = false): [boolean, () => void] => {
	const [value, setValue] = useState<boolean>(initialState);

	const toggle = useCallback(() => {
		setValue((v) => !v);
	}, []);

	return [value, toggle];
};
