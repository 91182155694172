// import TokenService from "./token.service";
// import { refreshToken } from "../actions/auth";

import axios from "axios";

export const setupInterceptors = (store) => {
	axios.interceptors.request.use(
		(config) => {
			// const token = TokenService.getLocalAccessToken();
			const token = localStorage.getItem("token");
			if (token) {
				// config.headers["Authorization"] = `Bearer ${token}`; // for Spring Boot back-end
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	// const { dispatch } = store;
	// axiosInstance.interceptors.response.use(
	//   (res) => {
	//     return res;
	//   },
	//   async (err) => {
	//     const originalConfig = err.config;

	//     if (originalConfig.url !== "/auth/login" && err.response) {
	//       // Access Token was expired
	//       if (err.response.status === 401 && !originalConfig._retry) {
	//         originalConfig._retry = true;

	//         try {
	//           const rs = await axiosInstance.post("/auth/refreshtoken", {
	//             refreshToken: TokenService.getLocalRefreshToken(),
	//           });

	//           const { accessToken } = rs.data;

	//           dispatch(refreshToken(accessToken));
	//           TokenService.updateLocalAccessToken(accessToken);

	//           return axiosInstance(originalConfig);
	//         } catch (_error) {
	//           return Promise.reject(_error);
	//         }
	//       }
	//     }

	//     return Promise.reject(err);
	//   }
	// );
};
