import { useQuery } from "react-query";
import axios from "axios";
import { ATIPS_ROOT_URL, getAccessToken, getSellerId, getUserId } from "../constants";

export const useGetSeller = () => {
	const { data, ...query } = useQuery(
		"seller",
		() => axios.get(`${ATIPS_ROOT_URL}/domain/sellers/${getUserId()}`, {
			headers: {
				"Authorization": `Bearer ${getAccessToken()}`,
			}
		}),
		{
			notifyOnChangeProps: "tracked",
			keepPreviousData: true,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			enabled: Boolean(getUserId()),
			onSuccess: ({ data }) => {
				localStorage.setItem("seller_id", data?.["id"]);
			},
		},
	);

	const { data: sellerByDashboardId } = useQuery(
		"seller-by-dashboardId",
		() => axios.get(`${ATIPS_ROOT_URL}/seller/${getSellerId()}`, {
			headers: {
				"Authorization": `Bearer ${getAccessToken()}`,
			}
		}),
		{
			notifyOnChangeProps: "tracked",
			keepPreviousData: true,
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			enabled: Boolean(getSellerId()),
			// onSuccess: ({data}) => {
			// 	co
			// },
		},
	);
	const sellerPrice = data?.data?.platforms?.[0]?.price;

	return { seller: data?.data || {}, sellerPrice, ...query };
};
