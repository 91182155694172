import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { getVideoFormats } from "../../../Actions/FormatActions";

import { getVideoGenres, getVideos } from "../../../Actions/videos-actions/VideosActions";
import { getSellers } from "../../../Actions/SellersActions";
import { getAreas } from "../../../Actions/UsersActions.js";
import { useGetPlatforms, useGetFormats } from "../../../api/shared/index";
import { handleMultiSelectActiveClick, handleSelectFilters } from "../scripts/PersonalAreaScripts";

import { customStyles } from "../static";

const initialDropDownState = {
	areaId: false,
	calendar: false,
	genres: false,
};

const StatisticFiltersComponent = ({
	videosList,
	getVideos,
	sellersList,
	getSellers,
	getAreas,
	areasList,
	filterState,
	setFilterState,
	videoGenresList,
	getVideoGenres,
	getVideoFormats,
	videoFormatsList,
}) => {
	const { platforms } = useGetPlatforms();
	const { formats } = useGetFormats();

	const [searchParams, setSearchParams] = useSearchParams();
	const [videosState, setVideosState] = useState({
		currentValue: "",
		list: [],
	});
	const [videoFormatsState, setVideoFormatsState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.formats_ids
			? JSON.parse(searchParams.get("filters")).format_ids
			: [],
		list: [],
	});
	const [sellersState, setSellersState] = useState({
		currentValue: "",
		list: [],
	});

	const [areaState, setAreaState] = useState({
		currentValue: [],
		list: [],
	});
	const [videoGenresState, setVideoGenresState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.genres
			? JSON.parse(searchParams.get("filters")).genres
			: [],
		list: [],
	});
	const calendarRef = useRef(null);

	const [dropDownState, setDropdownState] = useState(initialDropDownState);
	const [startDate, setStartDate] = useState(
		filterState?.filters?.date_from ? new Date(filterState?.filters?.date_from * 1000) : null
	);
	const [endDate, setEndDate] = useState(
		filterState?.filters?.date_to ? new Date(filterState?.filters?.date_to * 1000) : null
	);

	const onChange = (dates, e) => {
		e.stopPropagation();
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		if (end) {
			// const startTimeStamp = new Date(start).toISOString();
			// const endTimeStamp = new Date(end).toISOString();
			//new
			const startTimeStamp = new Date(start);

			const endTimeStamp = new Date(end);
			const datePlus24h = endTimeStamp && endTimeStamp.setHours(endTimeStamp.getHours() + 23);

			setFilterState({
				...filterState,
				filters: {
					...filterState.filters,
					from_datetime: startTimeStamp.toISOString(),
					to_datetime: endTimeStamp.toISOString(),
					// from_datetime: `${startTimeStamp.getFullYear()}-${
					// 	String(startTimeStamp.getMonth()).length === 1
					// 		? "0" + startTimeStamp.getMonth()
					// 		: startTimeStamp.getMonth()
					// }-${startTimeStamp.getDate()}T00:00:00.000Z`,

					// to_datetime: `${endTimeStamp.getFullYear()}-${
					// 	String(endTimeStamp.getMonth()).length === 1
					// 		? "0" + endTimeStamp.getMonth()
					// 		: endTimeStamp.getMonth()
					// }-${endTimeStamp.getDate()}T23:59:59.000Z`,
				},
			});
			setDropdownState(initialDropDownState);
		}
	};

	const handleGetVideosClick = () => {
		if (videosList?.list?.length === 0) {
			getVideos();
		}
	};

	useEffect(() => {
		getVideoFormats();
	}, []);

	useEffect(() => {
		getAreas();
		getSellers();
		if (searchParams.get("sellerId") && searchParams.get("sellerName")) {
			getVideoGenres();
		}
	}, []);

	useEffect(() => {
		setVideosState({
			...videosState,
			list: videosList?.list,
			currentValue:
				!!filterState.filters?.video && videosList?.list?.length
					? videosList?.list?.find((elem) => elem.id === filterState.filters.video)
					: "",
		});
	}, [videosList]);
	useEffect(() => {
		setVideoGenresState({
			...videoGenresState,
			list: videoGenresList,
			currentValue: !!filterState.filters?.genres ? filterState.filters.genres : [],
		});
	}, [videoGenresList]);

	useEffect(() => {
		setSellersState({
			...sellersState,
			list: sellersList?.list,
			currentValue:
				!!filterState.filters?.seller && sellersList?.list?.length
					? sellersList?.list?.find((elem) => elem.id === filterState.filters.seller)
					: "",
		});
	}, [sellersList]);

	useEffect(() => {
		setVideoFormatsState({
			...videoFormatsState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.formats_ids
				? JSON.parse(searchParams.get("filters")).formats_ids
				: [],
			list: formats,
		});
	}, [videoFormatsList, formats]);

	useEffect(() => {
		setAreaState({
			...areaState,
			list: [
				{ id: 1, title: "Клик" },
				{ id: 2, title: "Показ" },
			],
			currentValue: !!filterState.filters?.area ? filterState.filters.area : [],
		});
	}, [filterState]);

	useEffect(() => {
		// setAreaState({
		// 	...areaState,
		// 	currentValue: !!filterState.filters?.area ? filterState.filters.area : [],
		// });

		// setVideoGenresState({
		// 	...videoGenresState,
		// 	currentValue: !!filterState.filters?.genres
		// 		? filterState.filters.genres
		// 		: [],
		// });
		// setVideosState({
		// 	...videosState,
		// 	list: videosList?.list,
		// 	currentValue:
		// 		!!filterState.filters?.video && videosList?.list?.length
		// 			? videosList?.list?.find(
		// 					(elem) => elem.id === filterState.filters.video,
		// 			  )
		// 			: "",
		// });
		filterState?.filters?.from_datetime
			? setStartDate(new Date(filterState?.filters?.from_datetime))
			: setStartDate(null);
		// setEndDate(new Date(filterState?.filters?.to_datetime));
		filterState?.filters?.to_datetime
			? setEndDate(new Date(filterState?.filters?.to_datetime?.split("T")[0]))
			: setEndDate(null);
	}, [filterState]);

	const handleOnBlur = (event) => {
		setDropdownState({ ...dropDownState, calendar: !dropDownState.calendar });
	};

	const handleToggleCalendar = (event) => {
		event.stopPropagation();
		const current = event.currentTarget;
		if (!!calendarRef && event.target === calendarRef.current)
			setDropdownState({ ...dropDownState, calendar: !dropDownState.calendar });
	};

	const removeAllDate = (e) => {
		e.stopPropagation();
		// const timeObject = {...filterState};
		// timeObject.filters.from_datetime && delete timeObject.filters.from_datetime;
		// timeObject.filters.to_datetime && delete timeObject.filters.to_datetime;
		setFilterState({
			...filterState,
			filters: {
				...filterState.filters,
				from_datetime: null, // "2021-01-01T00:00:00.878Z",
				to_datetime: null, // new Date().toISOString(),
			},
		});
	};

	return (
		<div className="list-filter flex-block" style={{ marginBottom: "38px" }}>
			<div className="form-field">
				<div className="form-field-label">Статус</div>
				<div className="input-field">
					<div
						className={`select-block multiselect ${dropDownState.area ? "active" : ""}`}
						onFocus={() => null}
						onClick={() => setDropdownState({ ...dropDownState, area: !dropDownState.area })}
						onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="1"
					>
						<div className="select-block-button">
							<span>
								{areaState.currentValue.length > 0 &&
								areaState?.list?.length > 0 &&
								areaState.currentValue.length !== areaState?.list?.length
									? areaState.currentValue.map((elem, index) =>
											index !== areaState.currentValue.length - 1
												? `${areaState?.list?.find((item) => item.id == elem)?.title}, `
												: `${areaState?.list?.find((item) => item.id == elem)?.title}`
									  )
									: "Все"}
							</span>
						</div>
						<div className="select-block-list">
							<div className="select-block-list-scroll">
								{areaState?.list?.length > 0
									? areaState?.list?.map((area) => (
											<div
												key={area.id}
												className={`select-block-item ${
													areaState.currentValue.includes(area.id) ? "selected" : ""
												}`}
												onClick={(e) =>
													handleMultiSelectActiveClick(e, areaState, setAreaState, area)
												}
											>
												<span>{area?.title}</span>
											</div>
									  ))
									: null}
							</div>
							<div className="select-block-control">
								<div
									className="select-block-cancel button-select"
									onClick={(event) =>
										handleSelectFilters(
											event,
											"area",
											[],
											areaState,
											setAreaState,
											dropDownState,
											setDropdownState,
											filterState,
											setFilterState
										)
									}
								>
									Очистить
								</div>
								<div
									className="select-block-select button-select"
									onClick={(event) =>
										handleSelectFilters(
											event,
											"area",
											areaState.currentValue,
											null,
											null,
											dropDownState,
											setDropdownState,
											filterState,
											setFilterState
										)
									}
								>
									Применить
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className='form-field'>
				<div className='form-field-label'>Формат</div>
				<div className='input-field'>
					<div
						className={`select-block multiselect ${
							dropDownState.formats_ids ? "active" : ""
						}`}
						onFocus={() => null}
						onClick={() =>
							setDropdownState({
								...dropDownState,
								formats_ids: !dropDownState.formats_ids,
							})
						}
						onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex='1'>
						<div className='select-block-button'>
							<span>
								{videoFormatsState?.currentValue?.length > 0 &&
								videoFormatsState.currentValue.length !==
									videoFormatsState.list.length
									? videoFormatsState.currentValue.map((elem, index) =>
											index !== videoFormatsState.currentValue.length - 1
												? `${
														videoFormatsState.list.find(
															(item) => item.id == elem,
														)?.title
												  }, `
												: `${
														videoFormatsState.list.find(
															(item) => item.id == elem,
														)?.title
												  }`,
									  )
									: "Все"}
							</span>
						</div>
						<div className='select-block-list'>
							<div className='select-block-list-scroll'>
								{videoFormatsState?.list?.length > 0
									? videoFormatsState.list.map((format) => (
											<div
												key={format.id}
												className={`select-block-item ${
													videoFormatsState?.currentValue?.includes(format.id)
														? "selected"
														: ""
												}`}
												onClick={(e) =>
													handleMultiSelectActiveClick(
														e,
														videoFormatsState,
														setVideoFormatsState,
														format,
													)
												}>
												<span>{format?.title}</span>
											</div>
									  ))
									: null}
							</div>
							<div className='select-block-control'>
								<div
									className='select-block-cancel button-select'
									// onClick={(event) =>
									// 	handleSelectFilters(
									// 		event,
									// 		"formats_ids",
									// 		[],
									// 		videoFormatsState,
									// 		setVideoFormatsState,
									// 		dropDownState,
									// 		setDropdownState,
									// 		mainFilterState,
									// 		setMainFilterState
									// 	)
									// }
								>
									Очистить
								</div>
								<div
									className='select-block-select button-select'
									// onClick={(event) =>
									// 	handleSelectFilters(
									// 		event,
									// 		"formats_ids",
									// 		videoFormatsState.currentValue,
									// 		null,
									// 		null,
									// 		dropDownState,
									// 		setDropdownState,
									// 		mainFilterState,
									// 		setMainFilterState
									// 	)
									// }
								>
									Применить
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div className="form-field">
				<div className="form-field-label">Дата</div>

				<div className={`input-field date-range-field `}>
					<div
						id="statistics-range-visible"
						className={`statistics-range-visible ${dropDownState.calendar ? "active" : ""}`}
						onClick={(e) => handleToggleCalendar(e)}
						// onBlur={handleOnBlur}
						// onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="0"
					>
						<div className="calendar-icon" ref={calendarRef}></div>
						<input
							readOnly
							placeholder="Выберите даты"
							value={
								startDate > 0
									? `${startDate > 0 ? new Date(startDate).toLocaleDateString() : ""}${
											!!endDate > 0 ? ` - ${new Date(endDate).toLocaleDateString()}` : ""
									  } `
									: ""
							}
						/>
						<div
							className={`statistics-range-calendar-clear ${!!startDate ? "active" : ""}`}
							onClick={(e) => removeAllDate(e)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.70701 7.2928L7.99991 6.58569L6.58569 7.99991L7.2928 8.70701L10.5857 11.9999L7.2928 15.2928L6.58569 15.9999L7.99991 17.4141L8.70701 16.707L11.9999 13.4141L15.2928 16.707L15.9999 17.4141L17.4141 15.9999L16.707 15.2928L13.4141 11.9999L16.707 8.70701L17.4141 7.99991L15.9999 6.58569L15.2928 7.2928L11.9999 10.5857L8.70701 7.2928Z"
									fill="#212121"
								></path>
							</svg>
						</div>
						<div
							style={{
								position: "fixed",
								left: "0",
								top: "0%",
								height: "100vh",
								width: "100vw",
								zIndex: "1",
							}}
							className="backdrop"
							onClick={() => setDropdownState({ ...dropDownState, calendar: false })}
						/>

						<div className={`date-picker-wrapper `}>
							<DatePicker
								onChange={onChange}
								startDate={startDate}
								endDate={endDate}
								// onBlur={handleOnBlur}
								dateFormat="dd.MM.yyyy"
								selectsRange
								locale={ru}
								inline
								preventOpenOnFocus
								placeholderText="Выберите дату"
							/>
						</div>
					</div>
				</div>
			</div>

			{/* {searchParams.get("sellerId") && searchParams.get("sellerName") ? (
				<div className='form-field'>
					<div className='form-field-label'>Жанр</div>
					<div className='input-field'>
						<div
							className={`select-block multiselect ${
								dropDownState.genres ? "active" : ""
							}`}
							onFocus={() => null}
							onClick={() =>
								setDropdownState({
									...dropDownState,
									genres: !dropDownState.genres,
								})
							}
							onBlur={() => setDropdownState(initialDropDownState)}
							tabIndex='2'>
							<div className='select-block-button'>
								<span>
									{videoGenresState.currentValue.length > 0 &&
									videoGenresState.currentValue.length !==
										videoGenresState?.list?.length
										? videoGenresState.currentValue.map((elem, index) =>
												index !== videoGenresState.currentValue.length - 1
													? `${
															videoGenresState?.list?.find(
																(item) => item.id == elem,
															)?.title
													  }, `
													: `${
															videoGenresState?.list?.find(
																(item) => item.id == elem,
															)?.title
													  }`,
										  )
										: "Все"}
								</span>
							</div>
							<div className='select-block-list'>
								<div className='select-block-list-scroll'>
									{videoGenresState?.list?.length > 0
										? videoGenresState?.list?.map((genres) => (
												<div
													key={genres.id}
													className={`select-block-item ${
														videoGenresState.currentValue.includes(genres.id)
															? "selected"
															: ""
													}`}
													onClick={(e) =>
														handleMultiSelectActiveClick(
															e,
															videoGenresState,
															setVideoGenresState,
															genres,
														)
													}>
													<span>{genres?.title}</span>
												</div>
										  ))
										: null}
								</div>
								<div className='select-block-control'>
									<div
										className='select-block-cancel button-select'
										onClick={(event) =>
											handleSelectFilters(
												event,
												"genres",
												[],
												videoGenresState,
												setVideoGenresState,
												dropDownState,
												setDropdownState,
												filterState,
												setFilterState,
											)
										}>
										Очистить
									</div>
									<div
										className='select-block-select button-select'
										onClick={(event) =>
											handleSelectFilters(
												event,
												"genres",
												videoGenresState.currentValue,
												null,
												null,
												dropDownState,
												setDropdownState,
												filterState,
												setFilterState,
											)
										}>
										Применить
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null} */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videosList: state.videosList,
		sellersList: state.sellersList,
		areasList: state.areasList,
		videoGenresList: state.videoGenresList,
		videoFormatsList: state.videoFormatsList,
	};
};

const mapDispatchToProps = {
	getVideos,
	getSellers,
	getAreas,
	getVideoGenres,
	getVideoFormats,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticFiltersComponent);
