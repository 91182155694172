import { FrameAliasEnum } from "./types";
import { ReadyState } from "react-use-websocket";

// DEV/PROD ссылки хранятся в настройках репоизтория в гитлабе Settings > 	CI/CD > Variables
export const ATIPS_SELLER_URL = process.env.REACT_APP_ATIPS_SELLER_URL;
export const ATIPS_ROOT_URL = process.env.REACT_APP_ATIPS_DASHBOARD_URL;
export const ATIPS_IO = "https://atips.io";
export const ATIPS_CREATORS_API_URL = process.env.REACT_APP_ATIPS_CREATORS_URL; //process.env.REACT_APP_ATIPS_CREATORS_URL
export const STAT_API_URL = process.env.REACT_APP_STAT_ATIPS_URL;
export const ADVERTISING_API_URL = process.env.ADVERTISING_API_URL;
export const CHAT_HOST = process.env.CHAT_HOST;
export const SOCKET_BASE_URL = `wss://${CHAT_HOST}/ws`;
export const CATEGORIES_PATH = process.env.REACT_APP_CATEGORIES_PATH;

export const PARSE_URL = "https://parser.testatips.ru";

export const CREATORS_API_KEY = "Tv3ArgceSrbfRXpKfmuNKAKI6cjJpScI";
export const SELLER_API_KEY = "asd90jmq0987jrsuiodfhyui6215342a";

export const SOCKET_CONNECTION_STATUSES = {
	[ReadyState.CONNECTING]: "Connecting",
	[ReadyState.OPEN]: "Open",
	[ReadyState.CLOSING]: "Closing",
	[ReadyState.CLOSED]: "Closed",
	[ReadyState.UNINSTANTIATED]: "Uninstantiated",
};

export const getUserId = () => localStorage?.getItem("userid");
export const getSellerId = () => localStorage?.getItem("seller_id");
export const getAccessToken = () => localStorage.getItem("token");
export const getRefreshToken = () => localStorage.getItem("refresh_token");

export const FRAMES_ID: { [key in FrameAliasEnum]: number } = {
	colors: 1,
	sizes: 2,
	region: 5,
	ages: 10,
	country: 11,
	city: 12,
	cities: 12,
	gender: 13,
	regions: 36,
};
export const RUSSIA_ID = 366;
export const ALL_GENDERS_ID = 153;
