import axios from "axios";
import { ATIPS_SELLER_URL, TOKEN, } from "../api/constants";

import { useMutation } from "react-query";

export const postLogin = (datas) => {
	var bodyFormData = new FormData();
	bodyFormData.append("username", datas.login);
	bodyFormData.append("password", datas.password);
	return async (dispatch) => {
		await axios
			.post(`${ATIPS_SELLER_URL}/auth/login`, bodyFormData)
			.then((login) => {
				dispatch({
					type: "POST_LOGIN",
					login,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};


// new code from Kate
export const useRegistermutation = ({ onSuccess, onError }) => useMutation((data) => axios
	.post(`${ATIPS_SELLER_URL}/auth/register`, data), {
	onSuccess: async (res) => {
		onSuccess && onSuccess(res)
	},
	onError: async (res) => {
		onError && onError(res)
	},
});