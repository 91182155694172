import React from "react";
import {Navigate, Outlet} from "react-router-dom";

const ChechAuth = ({login}) => {
	return localStorage.getItem("token") && localStorage.getItem("userid") ? (
		<Navigate to='/' />
	) : (
		<Outlet />
	);
};

export {ChechAuth};
