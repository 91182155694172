import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useBaseMutation } from "../../../Actions/shared";

type Params = {
	inn: number;
};

const postQuery = (data: any = {}) =>
	axios.post(
		"https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
		data,
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Token " + "48dcdf37f3b52e1e0d38ae7d207132c1aa40eb69",
				mode: "cors",
				origin: "https://dadata.ru",
				referer: "https://dadata.ru/api/find-party/",
				"sec-fetch-site": "same-origin",
				"x-csrftoken": "2c213f03285c609e9014d3e9a0d398c900055320",
			},
		},
	);
export type Maybe<T> = T | undefined;

export type ApiReponse = {
	data: {
		address: {
			data: any;
			unrestricted_value: string;
			value: string;
		};
		ogrn: string;
		management: {
			name: string;
			post: string;
			disqualified: Maybe<any>;
		};
		inn: string;
		kpp: string;
		fio: any;
	};
	unrestricted_value?: string;
	value?: string;
};
export const useRegisterWithInn = ({ INN, onSuccess }: any = {}) => {
	const mutation = useMutation((data: any) => postQuery(data), {
		onSuccess: async (res) => {
			const firstItemInResponseArr: ApiReponse =
				res?.data?.suggestions[0] || {};
			const resultData: ApiReponse["data"] =
				res?.data?.suggestions[0]?.data || {};

			const fio = resultData?.fio ? `${resultData?.fio?.surname} ${resultData?.fio?.name} ${resultData?.fio?.patronymic}` : "";
			const regData = {
				name:
					firstItemInResponseArr?.value ||
					firstItemInResponseArr?.unrestricted_value,
				inn: resultData?.inn,
				ogrn: resultData?.ogrn,
				legal_address: resultData?.address?.unrestricted_value,
				actual_address: resultData?.address?.unrestricted_value,
				ceo_name: resultData?.management?.name || fio,
				contact_name: resultData?.management?.name || fio,
				kpp: resultData?.kpp,
			};
			onSuccess && onSuccess(regData);
		},
		onError: async (res) => {
		},
	});
	return mutation;
};
