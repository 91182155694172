import {useBaseMutation} from "../../Actions/shared";
import {Seller, User} from "../types";
import {useCustomMutation} from "../useCustomMutation";

export const useCreateSeller = (props: any) => {
	const {mutation} = useCustomMutation<Seller, unknown, any>({
		baseURLKey: "root",
		url: `/domain/sellers`,
		method: "post",
		...props,
	});

	return {createSeller: mutation};
};
