import { useQuery } from "react-query";
import { numberWithSpaces } from "../../utils/helpers/index";
import { useAxios } from "../useAxios";
import { User } from "../../ts/interfaces";
import { getUserId } from "../constants";

type Result = {
	user: User;
	balance: number | string;
	isZeroBalance: boolean;
	isLoadingUser?: boolean;
	refetchUser: () => void;
};
export const useGetUser = (): Result => {
	const { data, ...query } = useQuery(
		"user",
		() => useAxios({ baseURLKey: "seller", url: `/users/${getUserId()}` }),
		{
			notifyOnChangeProps: "tracked",
			keepPreviousData: true,
			// refetchOnMount: false,
			refetchOnMount: true,

			refetchOnWindowFocus: false,
		},
	);
	const user = data?.data;

	const balance = user?.balance ? Number(user?.balance) / 100 : 0;
	const isZeroBalance: any = Number(balance) <= 0;
	return {
		user: user || {},
		balance: balance > 1000 ? numberWithSpaces(balance) : balance,
		isZeroBalance,
		isLoadingUser: query?.isLoading,
		refetchUser: query?.refetch,
		...query,
	};
};
