const initialState = null;

export const nativeMapBy = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const MainStatisticUsersAgesReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_MAIN_USERS_AGES":
			return action.stat.data;
		default:
			return state;
	}
};
export const MainStatisticUsersCountriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_MAIN_USERS_COUNTRIES":
			if (action.stat.data.attributes) {
				const mapped = nativeMapBy(action.stat.data.attributes, "id");
				return mapped;
			}
			return state;
		default:
			return state;
	}
};
export const MainStatisticUsersCitiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_MAIN_USERS_CITIES":
			if (action.stat.data.attributes) {
				const mapped = nativeMapBy(action.stat.data.attributes, "id");
				return mapped;
			}
			return state;
		default:
			return state;
	}
};

export const MainStatisticCitiesByCountryReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_CITIES_BY_COUNTRY":
			return action.stat.data;
		default:
			return state;
	}
};

export const MainStatisticUsersGenderReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_MAIN_USERS_GENDER":
			return action.stat.data;
		default:
			return state;
	}
};
