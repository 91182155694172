import React, {FC} from "react";

interface IAppProps {}

const SuccessIcon: FC<IAppProps> = (props) => {
	return (
		<svg
			width='112'
			height='112'
			viewBox='0 0 112 112'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_7034_41486)'>
				<rect x='16' y='16' width='96' height='96' rx='16' fill='#FD4AA7' />
				<rect width='96' height='96' rx='16' fill='#005BFF' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M65.4144 37.9999L64.7073 38.707L44.7072 58.7071C44.5197 58.8946 44.2654 59 44.0001 59C43.7349 59 43.4806 58.8946 43.293 58.7071L31.293 46.707L30.5859 45.9999L32.0002 44.5857L32.7073 45.2928L44.0001 56.5857L63.293 37.2928L64.0001 36.5857L65.4144 37.9999Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7034_41486'>
					<rect width='112' height='112' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SuccessIcon;
