import React, {FC} from "react";
import cn from "classnames";
import {Link, useLocation} from "react-router-dom";
import "./styles.scss";

export const Footer: FC = () => {
	let location = useLocation();

	const isFooterHidden =
		location.pathname === "/statistic" || location.pathname.includes("/chat");

	if (isFooterHidden) return null;
	return (
		<footer
			className={cn("footer pb-22 ph-40", {
				"bg-grey":
					location.pathname === "/product" ||
					location.pathname === "/service" ||
					location.pathname.includes("profile") ||
					location.pathname === "/" ||
					location.pathname === "/blogger",
			})}>
			<div className='footer-list'>
				<Link to='/legal' target='_blank'>
					Пользовательское соглашение — оферта
				</Link>
				<Link to='/privacy' target='_blank'>
					Политика конфиденциальности
				</Link>
				<Link to='/personal' target='_blank'>
					Политика в области обработки и обеспечения безопасности персональных
					данных
				</Link>
			</div>
		</footer>
	);
};
