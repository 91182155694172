import React, {FC} from "react";
import cn from "classnames";
import "./styles/Tooltip.scss";

type TooltipProps = {
	text: string;
	style?: {};
	arrowPosition?: "center" | "right";
	position?: "center";
};

export const Tooltip: FC<TooltipProps> = ({
	text,
	style = {},
	arrowPosition,
	position,
}) => {
	return (
		<div
			className={cn(`tooltip bg-white`, {
				[`tooltip--${position}`]: position,
			})}
			style={style}>
			<p className={`tooltip-text T-center pv-12 ph-16  T13-list `}>{text}</p>
			<div
				className={cn("tooltip-arrow", {
					[`tooltip-arrow--${arrowPosition}`]: arrowPosition,
				})}
			/>
		</div>
	);
};
