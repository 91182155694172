import axios from "axios";
import { getFormData, jsonToFormData, removeEmpty } from "../ActionsScirpts";
import { ATIPS_SELLER_URL } from "../../api/constants";

export const getVideos = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/movie`)
			.then((video) => {
				dispatch({
					type: "GET_VIDEOS",
					video,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getSortedVideos = (arg) => {
	if (!!arg.filters && arg.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	// const clearParams = removeEmpty(arg);
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${ATIPS_SELLER_URL}/movie`, {
				params: {
					...arg,
				},
			})
			.then((video) => {
				dispatch({
					type: "GET_VIDEOS",
					video,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideoGenres = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/genre`)
			.then((videoGenres) => {
				dispatch({
					type: "GET_VIDEO_GENRES",
					videoGenres,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideoAges = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/rating`)
			.then((videoAges) => {
				dispatch({
					type: "GET_VIDEO_AGES",
					videoAges,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideoHashtags = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/hashtag`)
			.then((hashtags) => {
				dispatch({
					type: "GET_VIDEO_HASHTAGS",
					hashtags,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getFilmCrew = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/character`)
			.then((filmCrew) => {
				dispatch({
					type: "GET_FILM_CREW",
					filmCrew,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postUploadImages = (imgFile, type, width = null, height = null, crop = null) => {
	var bodyFormData = new FormData();
	const params = {};
	if (width && height && crop !== null) {
		params["width"] = width;
		params.height = height;
		params.crop = crop;
	}
	bodyFormData.append("file", imgFile);
	return async (dispatch) => {
		if (type === "gallery") {
			dispatch({
				type: "SHOW_LOADER",
			});
		}
		await axios
			.post(`https://atips.io/uploads/upload.php`, bodyFormData, {
				params: {
					...params,
				},
			})
			.then((uploadImg) => {
				if (type === "preview") {
					dispatch({
						type: "POST_UPLOAD_IMG",
						uploadImg,
					});
				} else if (type === "gallery") {
					dispatch({
						type: "POST_UPLOAD_GALLERY_IMG",
						uploadImg,
					});
				} else if (type === "vertical") {
					dispatch({
						type: "POST_UPLOAD_VERTICAL_IMG",
						uploadImg,
					});
				} else if (type === "horizontal") {
					dispatch({
						type: "POST_UPLOAD_HORIZONTAL_IMG",
						uploadImg,
					});
				}
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postFilmCrew = (val) => {
	return async (dispatch) => {
		await axios
			.post(`${ATIPS_SELLER_URL}/character`, val)
			.then((filmCrew) => {
				dispatch({
					type: "POST_FILM_CREW",
					filmCrew,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putFilmCrew = (state, id) => {
	const filteredState = removeEmpty(state);
	return async (dispatch) => {
		await axios
			.put(`${ATIPS_SELLER_URL}/character/${id}`, state)
			.then((filmCrew) => {
				dispatch({
					type: "PUT_FILM_CREW",
					filmCrew,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};