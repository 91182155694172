import {useBaseMutation} from "../../Actions/shared";
import {Product} from "../types";

type useUpdateProductProps = {
	id: any;
	noDomainInPath?: boolean;
};
export const useSaveProduct = ({
	id,
	noDomainInPath,
	...props
}: useUpdateProductProps) => {
	const {mutation} = useBaseMutation<Product, unknown, any>({
		url:
			id && noDomainInPath
				? `/domain/products/${id}`
				: id
				? `/domain/products/${id}`
				: "/domain/products",
		method: id ? "put" : "post",
		isApiKeyRequired: false,
		...props,
	});

	const saveProduct = (data: Partial<Product>) => {
		mutation.mutate(data);
	};

	return {saveProduct, isSavingProduct: mutation?.isLoading};
};
