import styled from "styled-components";

export const DropDownContainer = styled.div`
	position: relative;
	width: 100%;
	height: 54px;
`;

export const DropDownWrapper = styled.div`
	position: absolute;
	top: 62px;
	left: 0;
	width: 100%;
	box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
	z-index: 4;
`;

export const DropDownBackDrop = styled.div`
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
`;

export const ListWrapper = styled.div`
	max-height: 204px;
	position: relative;
	background: white;
	padding: 16px 0;
	width: 100%;
	border-radius: 0px 0px 8px 8px;
	overflow: scroll;
	scrollbar-width: thin;
	scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		position: relative;
		background: #94a0b130;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		margin: 16px 0;
	}
`;

export const StyledInput = styled.input`
	background: white;
	border: none;
	border-bottom: 1px solid #f4f4f8;
	border-radius: 8px 8px 0px 0px !important;
	display: block;
	font-size: 15px;
	height: 54px;
	outline: none;
	position: relative;
	width: 100%;
	padding: 16px 20px;
	color: #212121;
	font-family: "Averta CY";
	font-weight: 400;
	font-size: 15px;
`;

export const DropDownListItem = styled.div`
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 6px 16px;
	font-weight: 400;
	font-size: 15px;
	cursor: pointer;
	&.selected {
		color: #94a0b1;
		cursor: default;
	}
	&:hover {
		background: #f4f4f8;
	}
`;

export const LoaderWrapper = styled.div`
	@keyframes loaderAnimation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	width: 24px;
	height: 24px;
	position: absolute;
	top: calc(50% - 12px);
	right: 20px;
	animation: loaderAnimation 1s linear infinite;
	background: url("images/icons/ui/status/blue/loader.svg") no-repeat;
	background-size: contain;
`;

export const StyledClearButton = styled.button`
	background: #f4f4f8;
	width: 24px;
	height: 24px;
	right: 40px;
	top: calc(50% - 12px);
	cursor: pointer;
	display: none;
	&.active {
		display: block;
	}
	position: absolute;
	border: none;
	outline: none;
	background: transparent;
`;

export const SelectedBlock = styled.div`
	&.select-block.multiselect {
		padding: 16px 50px 16px 20px;
		text-overflow: ellipsis;
		position: absolute !important;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		&::after {
			background: url("images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
			content: "";
			display: block;
			height: 24px;
			pointer-events: none;
			position: absolute;
			right: 16px;
			top: 16px;
			width: 24px;
		}
	}
`;
