import cn from "classnames";
import React, {FC, InputHTMLAttributes} from "react";

export type InputActions = {
	register?: any;
	control?: any;
	remove?: any;
	append?: any;
};
export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	inputClass?: string;
	validate?: (value: any) => void;
	pattern?: any;
	error?: {message?: string; type?: string};

	inputActions: InputActions;
};

export const Input: FC<InputProps> = ({
	inputClass,
	inputActions,
	validate = () => true,
	pattern = false,
	error,
	...rest
}) => {
	return (
		<input 
			maxLength={rest?.maxLength > 0 ? 65 : 100000}
			type={rest?.type || "text"}
			{...(inputActions
				? inputActions?.register(rest?.name, {
						...rest,
				  })
				: rest)}
			onKeyPress={(event) => {
				if (rest?.type === "number" && !/[0-9]/.test(event.key)) {
					event.preventDefault();
				}
			}}
			autoComplete='off'
			className={cn("input", inputClass, {
				// "input--error": error,
			})}
		/>
	);
};

// onChange: (e: any) => e.target.value.replace(/\D/g, ""),

// 				valueAsNumber: true,
// 				pattern: {
// 					value: /^(0|[1-9]\d*)(\.\d+)?$/,
// 				},
