import {useGetUploadImg} from "./useGetUploadImg";
import {useBaseMutation, useBaseMutationProps} from "../../Actions/shared";
import {Product} from "../types";
import {useState} from "react";

type ApiResponse = {
	title: string;
	price: string;
	img: string;
	link: string;
};

type ApiParams = {
	url: string;
};

type useParserProps = {
	onSuccess: (product: Partial<Product>) => any;
};
export const useParser = ({onSuccess}: useParserProps) => {
	const [parserResponse, setParserResponse] = useState<any>(null);

	const {isGettingImg} = useGetUploadImg({
		url: parserResponse?.img,
		onSuccess: (res: any) => {
			let product = {
				title: parserResponse?.title,
				price: parserResponse?.price,
				URL_HD_IMAGE: res?.data?.url || parserResponse?.img,
				URL_SOURCE: parserResponse?.link,
			};
			onSuccess(product);
			setParserResponse(null);
		},
	});

	const {mutation, error, ...updateMutation} = useBaseMutation<
		ApiResponse,
		unknown,
		ApiParams
	>({
		baseURLKey: "parse",
		url: "/parse",
		retry: 4,
		onSuccess: (res: any, ...rest) => {
			const data = res?.data;
			setParserResponse(data);
		},
	});

	const parseUrl = ({url, URL_SOURCE}: Partial<Product & ApiParams>) => {
		const parseUrl = url || URL_SOURCE;
		parseUrl && mutation.mutate({url: parseUrl});
	};

	return {
		...updateMutation,
		parseUrl,
		isParsing: mutation.isLoading || isGettingImg,
		parseError: error,
	};
};
