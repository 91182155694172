import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import ModalBrightboxInfo from "../ModalBrightboxInfo/ModalBrightboxInfo.jsx";
import { formatStatisticDate } from "./formatDate.js";

const StatisticContent = ({
	activeColumnState,
	paramsState,
	setParamsState,
	setStatisticOrderType,
	actions = [],
}) => {
	let { handleModal } = useContext(ModalContext);
	return actions?.length > 0 ? (
		<div className="list-table stat-list">
			{actions.map((item, index) => {
				const date = item?.create_date && new Date(item?.create_date);
				const moscowDate = date && new Date(date);
				const localeStringDate =
					moscowDate &&
					new Date(moscowDate).toLocaleTimeString([], {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					});

				return (
					<div
						className="list-table-item action-button"
						onClick={() => {
							handleModal(
								<ModalBrightboxInfo
									itemState={item || {}}
									paramsState={paramsState}
									setParamsState={setParamsState}
									setStatisticOrderType={setStatisticOrderType}
								/>
							);
						}}
						key={`${index}.${item.id}`}
					>
						<div
							className={`list-table-item-element ${
								!activeColumnState.tips_id?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.tips_id.width }}
						>
							<span>{item.tips_id || "Нет значений"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.price?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.price.width }}
						>
							<span>{item.price ? item.price : "0"}</span>
						</div>



						<div
							className={`list-table-item-element ${
								!activeColumnState.product?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.product.width }}
						>
							<span>{item.product}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.category?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.category.width }}
						>
							<span>{item.category || "Нет значения"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.video?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.video.width }}
						>
							<span>{item.video}</span>
						</div>

						<div
							className={`list-table-item-element ${
								!activeColumnState.genre?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.genre.width }}
						>
							<span>
								{Array.isArray(item.genre) && item.genre?.length > 0
									? item.genre.map((genre, index) =>
											index + 1 === item.genre.length ? `${genre}` : `${genre}, `
									  )
									: "Нет значений"}
							</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.sex?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.sex.width }}
						>
							<span>{item?.sex || "Нет значения"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.age?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.age.width }}
						>
							<span>{item?.age || "Нет значения"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.country?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.country.width }}
						>
							<span>{item?.country || "Нет значения"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.city?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.city.width }}
						>
							<span>{item?.city || "Нет значения"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.click?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.click.width }}
						>
							<span>{item.click == 1 ? "Клик" : "Показ"}</span>
						</div>
						<div
							className={`list-table-item-element ${
								!activeColumnState.create_date?.active ? "hidden" : ""
							}`}
							style={{ width: activeColumnState.create_date.width }}
						>
							{/* <span>{formatedDate(item.created_at)}</span> */}
							<span>{String(formatStatisticDate(new Date(item.create_date).getTime(), 3))}</span>
						</div>
					</div>
				);
			})}
		</div>
	) : null;
};

// const mapDispatchToProps = {
// 	getStatistic,
// };

export default StatisticContent;
