import { useBaseQuery } from "../../Actions/shared";
import { isArr } from "../../utils/helpers";
import { Frame, Attribute } from "../types";

export const useGetFramesAttrs = ({ frameId }): Attribute[] => {
	const framesQuery = useBaseQuery<{ data: Frame }>({
		url: `/frame/${frameId}`,
		queryKey: `frame ${frameId}`,
	});
	const frameResponse = framesQuery?.data?.data?.attributes;

	return frameResponse;
	;
};

type useGetAttributes = any

export const useGetAttributes = (): useGetAttributes => {
	const framesQuery = useBaseQuery<{ data: Frame[] }>({
		url: `/domain/frames/attributeslist?frame_ids=1&frame_ids=2&frame_ids=15&frame_ids=16&frame_ids=17&frame_ids=18&frame_ids=19&frame_ids=20&frame_ids=21&frame_ids=22&frame_ids=23&frame_ids=24&frame_ids=25&frame_ids=26&frame_ids=27&frame_ids=28&frame_ids=29&frame_ids=30&frame_ids=31&frame_ids=32&frame_ids=33&frame_ids=34&frame_ids=35`,
		queryKey: "attriytes",
	});
	const framesResponse = framesQuery?.data?.data;

	return framesResponse;
};

export const useGetAttribute = ({ id }: { id: number }): useGetAttributes => {
	const framesQuery = useBaseQuery<{ data: Frame[] }>({
		url: `/attribute/${id}`,
		queryKey: ["attribute", id],
	});
	const framesResponse = framesQuery?.data?.data;

	return framesResponse;
};

export const useGetCategoryAttributes = ({ categoryId, enabled }): any => {
	const framesQuery = useBaseQuery<{ data: any }>({
		url: `/domain/categories/attributes/${categoryId}`,
		queryKey: "cat-attr",
		enabled,
		params: {
			categoryId
		}
	});
	const array = framesQuery?.data?.data?.category?.predefined_attributes;
	const initialValue = {};
	if (array && isArr(array)) {
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item["id"]]: item,
			};
		}, initialValue);
	}
	return {}


};
