import React, {FC, useContext, useCallback, useEffect} from "react";
import {useForm, useFormState, useController} from "react-hook-form";
import {Field} from "../../../ui-kit/forms/Field";
// import {Field} from "../../../ui-kit/forms/";
import {ModalContext} from "../../../components/modal/ModalContext/ModalContext";
import {Textarea} from "../../../ui-kit/forms/Textarea";

import {useDropzone} from "react-dropzone";

import {ModalContentWrapper} from "../Modals/ModalContentWrapper";
import "./styles/FeedbackPopup.scss";
import {useToggle} from "../../../utils/hooks/useToggle";
import {useSendFeedback} from "../../../api/shared/useSendFeedback";

type FeedbackPopupProps = {};

export const FeedbackPopup: FC<FeedbackPopupProps> = (props) => {
	//@ts-ignore
	const {handleModal} = useContext(ModalContext);
	const {register, handleSubmit, control, watch, setValue} = useForm();
	const {isValid} = useFormState({
		control,
	});
	const fileFieldValue = watch("file");

	const [isComplaintSent, toggleIsComplaintSent] = useToggle();

	const {sendFeedback, isSendingFeedback} = useSendFeedback({
		onSuccess: (res) => {
			handleModal(<SuccessPopup />);

			const timeout = setTimeout(() => {
				toggleIsComplaintSent();
				handleModal();
			}, 1500);
			return () => clearTimeout(timeout);
		},
	});

	const onDrop = useCallback((acceptedFiles) => {
		setValue("file", acceptedFiles[0]);
	}, []);
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

	// const checkFile = () => {
	// 	const isCorrectType = fileFieldValue?.type?.includes("image");
	// 	const totalBytes = fileFieldValue?.size;
	// 	let size;
	// 	if (totalBytes < 1000000) {
	// 		size = Math.floor(totalBytes / 1000);
	// 	} else {
	// 		size = Math.floor(totalBytes / 1000000);
	// 	}
	// 	const isCorrectSize = size <= 5;
	// };

	// useEffect(()=>{
	// 	checkFile()
	// },[fileFieldValue])
	return (
		<ModalContentWrapper
			className='feedback-popup'
			style={{
				width: "504px",
			}}
			withCancelBtn
			primaryButton={{
				text: `Отправить`,
				onClick: handleSubmit(sendFeedback),
				type: "submit",
				disabled: !isValid || isSendingFeedback,
			}}
			title='Написать нам'>
			<form onSubmit={handleSubmit(sendFeedback)}>
				<Field
					required
					inputActions={{register, control}}
					setValue={setValue}
					label='Сообщение'
					name='text'
					component={Textarea}
					placeholder='Напишите нам, если обнаружили ошибку или если у вас есть идея как нам улучшить сервис.'
				/>
				<label
					htmlFor='feedback-file'
					className='feedback-popup__file-field w100'
					{...getRootProps()}>
					<p className='mb-6 T14 T-grey'>Прикрепить файл (не обязательно)</p>
					<input
						type='file'
						id='feedback-file'
						// @ts-ignore
						onChange={(e) => setValue("file", e?.target?.files[0])}
						style={{display: "none"}}
						name='file'
						{...getInputProps()}
					/>
					<label className='feedback-popup__file-field_drop-zone ph-20 pv-16'>
						{fileFieldValue && (
							<p className='T15-list'>{fileFieldValue?.name}</p>
						)}
						{!fileFieldValue && (
							<div className='row between top'>
								<p className='T15-list mb-26'>
									Выберите файл <br /> или перетащите его сюда
								</p>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M15.7929 9.20711L16.5 9.91421L17.9142 8.5L17.2071 7.79289L12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289L6.79289 7.79289L6.08579 8.5L7.5 9.91421L8.20711 9.20711L11 6.41421V15V16H13V15V6.41421L15.7929 9.20711ZM4 19H3V21H4H20H21V19H20H4Z'
										fill='#212121'
									/>
								</svg>
							</div>
						)}

						{!fileFieldValue && (
							<span className='T13'> Файлы .jpg,.png. Макс. размер 5 Мб</span>
						)}
					</label>
				</label>
			</form>
		</ModalContentWrapper>
	);
};

const SuccessPopup = () => {
	return (
		<div className='bg-blue radius-16 pv-24 ph-32'>
			<div className='row between cGap-36 T-white'>
				Сообщение отправлено
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M21.4142 7.00015L20.7071 7.70726L10.7071 17.7073C10.5196 17.8948 10.2652 18.0002 10 18.0002C9.7348 18.0002 9.48045 17.8948 9.29291 17.7073L3.29292 11.7073L2.58582 11.0001L4.00003 9.58594L4.70714 10.293L10 15.586L19.2929 6.29304L20 5.58594L21.4142 7.00015Z'
						fill='white'
					/>
				</svg>
			</div>
		</div>
	);
};
