import React, { useState, useEffect, useContext } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { postLogin } from "../../Actions/AuthorizationActions";
import "./styles/Authorization.scss";
import { Button } from "../../ui-kit/buttons/Button";
import { Field } from "../../ui-kit/forms/Field";
import { useLogin } from "../../api/auth/index";
import { useTinkoffAuthUrlQuery } from "../../api/auth/useTinkoffAuthMutations";
import { useForgotPassword, useResetPassword } from "../../api/auth/useResetPassword";
import { useTinkoffAuthCallback } from "../../api/auth/useTinkoffAuthMutations";
import { Error } from "../../ui-kit/forms/Error";
import { useToggle } from "../../utils/hooks/useToggle";
import SuccessIcon from "../../../public/images/icons/ui/success-icon";
import { EMAIL, PASS, PASS_REPEAT, PASS_NEW } from "../../utils/constants/fields";
import { useVerifyEmail } from "../../api/auth/index";
import { ForgotPasswordModal } from "./ForgotPasswordModal.jsx";
import { ModalContext } from "../modal/ModalContext/ModalContext";

const Authorization = () => {
	let navigate = useNavigate();
	let location = useLocation();
	const pathname = location.pathname;
	const token = location.search.split("=")[1];
	const { handleModal } = useContext(ModalContext);
	const tikoffParams = location.search?.slice(1)?.split("&")?.join("=")?.split("=");
	const [isTinkoffAuth, toggleIsTinkQueryEnabled] = useToggle();
	const tinkoffQuery = useTinkoffAuthUrlQuery({
		enabled: isTinkoffAuth,
	});
	const [isResetSuccess, toggleIsResetPass] = useToggle(false);
	const [submitError, setSubmitError] = useState(false);

	const { register, handleSubmit, control } = useForm();
	const { isValid } = useFormState({
		control,
	});

	const { login, error } = useLogin({
		onSuccess: (res) => {
			const resData = res?.data;
			localStorage.setItem("token", resData?.["access_token"]);
			localStorage.setItem("refresh_token", resData?.["refresh_token"]);
			localStorage.setItem("userid", resData?.["user_id"]);

			navigate("/");
		},
	});
	const { sendEmailForResetPassword, error: forgotError } = useForgotPassword({
		onSuccess: () => {
			handleModal(<ForgotPasswordModal />);
			navigate("/sign-in");
		},
	});
	const { resetPassword, error: resetError } = useResetPassword({
		onSuccess: () => {
			toggleIsResetPass();
		},
	});
	const authData = {
		formTitle: "Вход в сервис",
		fields: [{ label: "Email", name: "username", required: true }, PASS],
		btnText: "Войти",
		onSubmit: login,
		bottomHint: "Еще нет профиля в Atips? ",
		bottomLinkText: " Зарегистрируйтесь",
		bottomLink: "/sign_up",
	};
	const {
		formTitle,
		formHint,
		fields,
		btnText,
		onSubmit,

		bottomHint,
		bottomLinkText,
		bottomLink,
		btnLink,
	} = {
		"/sign-in": authData,
		"/auth/complete": authData,
		"/forgot-password": {
			formTitle: "Восстановить пароль",
			formHint: "Введите email, который вы указывали при регистрации.",
			fields: [EMAIL],
			btnText: "Восстановить пароль",
			onSubmit: sendEmailForResetPassword,
			bottomHint: "Вспомнили пароль? ",
			bottomLinkText: " Войдите",
			bottomLink: "/sign-in",
		},
		"/reset-password": {
			formTitle: isResetSuccess ? "Пароль успешно изменен!" : "Придумайте новый пароль",
			fields: [PASS_NEW, PASS_REPEAT],

			btnText: isResetSuccess ? "Войти" : "Подтвердить",
			onSubmit: ({ password, password_repeat }) => {
				if (password !== password_repeat) {
					setSubmitError({
						msg: "Неверно введен повтор пароля",
					});
				} else {
					setSubmitError(false);
					resetPassword({ password, token });
				}
			},
		},
	}[pathname];

	const { verifyEmail } = useVerifyEmail();
	useEffect(() => {
		pathname === "/sign-in" && token && verifyEmail({ token });
	}, [token]);

	useEffect(() => {
		if (pathname !== "/reset-password" && isResetSuccess) {
			handleModal(<ForgotPasswordModal />);
			toggleIsResetPass();
		}
	}, [pathname]);

	const [queryParams, setQueryParams] = useState(null);
	const state = tikoffParams && tikoffParams[1];
	const code = tikoffParams && tikoffParams[3];
	const tinkoffAuthCallback = useTinkoffAuthCallback({
		code: code,
		state: state,
		onSuccess: () => navigate("/"),
	});

	useEffect(() => {}, [tikoffParams]);
	return (
		<section
			id="auth"
			className={cn("auth", {
				"auth-reset": pathname === "/reset-password",

				"auth-reset-success": isResetSuccess,
			})}
		>
			<div style={{ display: "none" }} id="commit-hash">
				{`COMMIT HASH: ${process.env.COMMIT_HASH}`}
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				id="auth-form"
				autoComplete="off"
				autoFocus
				className="auth-form"
			>
				<img src="images/logo.svg" alt="" id="logo" />
				{isResetSuccess && <SuccessIcon className="auth-reset-success__svg" />}
				<h4 className="title">{formTitle}</h4>
				{formHint && <p className="auth-form__hint">{formHint}</p>}

				{!isResetSuccess &&
					fields.map((field, i) => (
						<Field
							{...field}
							autoFocus={i === 0}
							focus
							inputActions={{ register, control }}
							key={i}
						/>
					))}
				{error?.response?.status === 403 && (error || submitError || forgotError || resetError) ? (
					<p className={cn("error field-error")}>{error?.response?.data?.detail}</p>
				) : (
					<Error
						error={error || submitError || forgotError || resetError}
						errorType={error?.response?.data?.detail}
					/>
				)}

				<Button
					disabled={!isValid}
					text={btnText}
					type={isResetSuccess ? "button" : "submit"}
					size="l"
					variant="purple"
					link={isResetSuccess && "/sign-in"}
				/>
				{/* {pathname === "/sign-in" && (
					<>
						<div className='auth-form__or-dividor cross-line'>
							<span>или</span>
						</div>
						<Button
							text='Войти c'
							size='l'
							variant='tinkoff'
							onClick={toggleIsTinkQueryEnabled}
						/>
					</>
				)} */}
				{bottomHint && (
					<p className="auth-form__reg-hint">
						{bottomHint}
						Тогда
						<Link to={bottomLink}>{bottomLinkText}</Link>
					</p>
				)}
			</form>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		login: state.login,
	};
};

const mapDispatchToProps = {
	postLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
