import React from "react";

export const Edit2SmallIcon = (props) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.6203 10.2495C24.4328 10.0619 24.1784 9.95655 23.9132 9.95654C23.648 9.95654 23.3936 10.0619 23.2061 10.2494L13.5858 19.8696C13.2107 20.2446 13 20.7534 13 21.2838L13 25C13 25.2652 13.1054 25.5196 13.2929 25.7071C13.4804 25.8947 13.7348 26 14 26H17.7162C18.2467 26 18.7554 25.7893 19.1305 25.4142L28.7503 15.7941C29.1408 15.4036 29.1409 14.7704 28.7504 14.3799L24.6203 10.2495ZM15 21.2838L23.9131 12.3708L26.6291 15.087L17.7162 24H15L15 21.2838ZM11 28.0002H10V30.0002H11H29H30V28.0002H29H11Z'
				fill='#94A0B1'></path>
		</svg>
	);
};

export const RefreshIcon = (props) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20 12C17.0475 12 14.5151 13.6009 13.1339 16H14.5H15.5V18H14.5H11C10.4477 18 10 17.5523 10 17V13V12H12V13V14.0803C13.8022 11.6113 16.6856 10 20 10C23.9359 10 27.2641 12.2722 28.8955 15.555L29.3405 16.4505L27.5495 17.3405L27.1045 16.445C25.7905 13.8009 23.1315 12 20 12ZM12.4505 22.6595L12.8955 23.555C14.2095 26.1991 16.8685 28 20 28C22.5202 28 24.7343 26.8336 26.1876 25H25H24V23H25H27.9798C27.9937 22.9997 28.0077 22.9997 28.0217 23H28.5C29.0523 23 29.5 23.4477 29.5 24V27.5V28.5H27.5V27.5V26.5505C25.6875 28.6596 23.023 30 20 30C16.0641 30 12.7359 27.7278 11.1045 24.445L10.6595 23.5495L12.4505 22.6595Z'
				fill='#94A0B1'></path>
		</svg>
	);
};

export const DeleteIcon = (props) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 13C15 11.3431 16.3431 10 18 10H22C23.6569 10 25 11.3431 25 13V14H26L28 14H29V16H28H26.9311L26.1301 27.2137C26.018 28.7837 24.7117 30 23.1378 30H16.8622C15.2883 30 13.982 28.7837 13.8699 27.2137L13.0689 16H12H11V14H12H14H15V13ZM17 14L23 14V13C23 12.4477 22.5523 12 22 12L18 12C17.4477 12 17 12.4477 17 13V14ZM15.074 16L15.8648 27.0712C15.9022 27.5946 16.3376 28 16.8622 28H23.1378C23.6624 28 24.0978 27.5946 24.1352 27.0712L24.926 16H24L16.0003 16H16H15.9997H15.074Z'
				fill='#94A0B1'></path>
		</svg>
	);
};

export const PlusIcon = (props) => {
	return (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 4L9 3L7 3L7 4L7 7L4 7H3V9L4 9L7 9L7 12L7 13H9V12L9 9L12 9H13V7H12L9 7L9 4Z'
				fill='white'
			/>
		</svg>
	);
};

export const PlusBigIcon = ({fill, ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 6V5L11 5V6L11 11H6H5L5 13H6H11L11 18V19H13V18L13 13H18L19 13V11L18 11H13L13 6Z'
				fill={fill || "#ffffff"}
			/>
		</svg>
	);
};

export const CloseIcon = ({fill, ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z'
				fill={fill || "#212121"}></path>
		</svg>
	);
};

export const CloseSmallIcon = ({fill, ...props}) => {
	return (
		<svg
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.70701 1.29304L1.99991 0.585938L0.585693 2.00015L1.2928 2.70726L4.58569 6.00015L1.2928 9.29305L0.585693 10.0002L1.99991 11.4144L2.70701 10.7073L5.99991 7.41436L9.2928 10.7073L9.99991 11.4144L11.4141 10.0002L10.707 9.29304L7.41412 6.00015L10.707 2.70726L11.4141 2.00015L9.99991 0.585938L9.2928 1.29305L5.99991 4.58594L2.70701 1.29304Z'
				fill={fill || "#212121"}
			/>
		</svg>
	);
};

export const CloseMiddleIcon = (props) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.70701 5.29304L5.99991 4.58594L4.58569 6.00015L5.2928 6.70726L10.5857 12.0002L5.2928 17.293L4.58569 18.0002L5.99991 19.4144L6.70701 18.7073L11.9999 13.4144L17.2928 18.7073L17.9999 19.4144L19.4141 18.0002L18.707 17.293L13.4141 12.0002L18.707 6.70726L19.4141 6.00015L17.9999 4.58594L17.2928 5.29304L11.9999 10.5859L6.70701 5.29304Z'
				fill='#212121'
			/>
		</svg>
	);
};

export const PersonIcon = (props) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			{...props}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8ZM12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2ZM8 16C4.68629 16 2 18.6863 2 22H4C4 19.7909 5.79086 18 8 18H16C18.2091 18 20 19.7909 20 22H22C22 18.6863 19.3137 16 16 16H8Z'
				fill='#212121'
			/>
		</svg>
	);
};

export const PersonBigIcon = (props) => {
	return (
		<svg
			width='65'
			height='64'
			viewBox='0 0 65 64'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.1667 21.3333C20.1667 14.5218 25.6885 9 32.5 9C39.3115 9 44.8333 14.5218 44.8333 21.3333C44.8333 28.1448 39.3115 33.6667 32.5 33.6667C25.6885 33.6667 20.1667 28.1448 20.1667 21.3333ZM32.5 7C24.5839 7 18.1667 13.4173 18.1667 21.3333C18.1667 29.2494 24.5839 35.6667 32.5 35.6667C40.4161 35.6667 46.8333 29.2494 46.8333 21.3333C46.8333 13.4173 40.4161 7 32.5 7ZM21.8333 44.3333C13.9173 44.3333 7.5 50.7506 7.5 58.6667H9.5C9.5 51.8552 15.0218 46.3333 21.8333 46.3333H43.1667C49.9782 46.3333 55.5 51.8552 55.5 58.6667H57.5C57.5 50.7506 51.0827 44.3333 43.1667 44.3333H21.8333Z'
				fill='#94A0B1'
			/>
		</svg>
	);
};

export const CardIcon = (props) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}>
			<g clipPath='url(#clip0_6820_21126)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 6C0 3.79086 1.79086 2 4 2L20 2C22.2091 2 24 3.79086 24 6V8V10V18C24 20.2091 22.2091 22 20 22H4C1.79086 22 0 20.2091 0 18L0 10L0 8L0 6ZM22 6V8L2 8V6C2 4.89543 2.89543 4 4 4L20 4C21.1046 4 22 4.89543 22 6ZM2 18L2 10L22 10V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18ZM6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H9C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15H6Z'
					fill='#212121'
				/>
			</g>
			<defs>
				<clipPath id='clip0_6820_21126'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export const MirIcon = (props) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.2707 16.0566L19.744 20.909H19.4867V15.0018H15.8967V25.0003H18.944C19.3286 25.0046 19.7067 24.908 20.0358 24.7213C20.3648 24.5347 20.6316 24.2655 20.806 23.9442L23.3333 19.0912H23.5893V25.0003H27.18V15.0018H24.1333C23.7487 14.9974 23.3704 15.094 23.0412 15.2806C22.712 15.4673 22.4451 15.7365 22.2707 16.0579V16.0566ZM8.79999 16.3141L7.30733 20.9096H7.05133L5.556 16.3141C5.41968 15.9269 5.15485 15.5906 4.80019 15.3545C4.44552 15.1184 4.01961 14.9948 3.58466 15.0018H0V25.0003H3.58866V19.0905H3.846L5.89733 25.0003H8.46133L10.5127 19.0912H10.7687V25.0003H14.3587V15.0018H10.7733C10.338 14.9944 9.91168 15.1179 9.55661 15.354C9.20154 15.5901 8.93641 15.9265 8.79999 16.3141ZM28.7153 19.5448V25.0003H32.298V21.8182H36.1513C36.9299 21.8287 37.6934 21.6169 38.3404 21.211C38.9875 20.805 39.4875 20.2241 39.774 19.5455H28.718L28.7153 19.5448Z'
				fill='#3BA634'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M36.1544 15.0013H28.2051C28.4823 16.179 29.1864 17.2302 30.1974 17.976C31.2085 18.7219 32.4641 19.1162 33.7504 19.0919H39.9237C39.9747 18.8674 40.0004 18.6385 40.0004 18.4089C39.9715 17.4793 39.5503 16.5984 38.8292 15.9595C38.1082 15.3207 37.1462 14.9761 36.1544 15.0013Z'
				fill='#009BDB'
			/>
		</svg>
	);
};

export const MastercardIcon = (props) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.0739 28.4271H14.9268V10.2788H25.0739V28.4271Z'
				fill='#F36523'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M38.7499 19.2979C38.7599 21.461 38.1549 23.583 37.0046 25.42C35.8542 27.257 34.2052 28.7346 32.2469 29.6829C30.2886 30.6312 28.1005 31.0118 25.9341 30.7809C23.7677 30.55 21.7109 29.717 20 28.3776C21.3799 27.2937 22.4948 25.9138 23.2613 24.3414C24.0278 22.769 24.4259 21.0448 24.4259 19.2979C24.4259 17.5511 24.0278 15.8269 23.2613 14.2544C22.4948 12.682 21.3799 11.3022 20 10.2183C21.711 8.87915 23.7678 8.04638 25.9341 7.81562C28.1004 7.58486 30.2883 7.96548 32.2465 8.91373C34.2047 9.86199 35.8537 11.3394 37.0041 13.1763C38.1545 15.0132 38.7596 17.135 38.7499 19.2979Z'
				fill='#FA9F1B'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.5737 19.2987C15.5713 17.5514 15.9684 15.8263 16.7352 14.2535C17.5019 12.6807 18.6182 11.3012 20 10.219C18.2891 8.8796 16.2323 8.0466 14.0659 7.81569C11.8995 7.58479 9.71147 7.96536 7.75318 8.91366C5.79488 9.86197 4.14581 11.3395 2.99546 13.1766C1.84511 15.0136 1.24016 17.1356 1.25012 19.2987C1.24016 21.4617 1.84511 23.5837 2.99546 25.4208C4.14581 27.2578 5.79488 28.7354 7.75318 29.6837C9.71147 30.632 11.8995 31.0125 14.0659 30.7816C16.2323 30.5507 18.2891 29.7177 20 28.3783C18.6182 27.2961 17.5019 25.9166 16.7352 24.3438C15.9684 22.771 15.5713 21.046 15.5737 19.2987Z'
				fill='#E71B26'
			/>
		</svg>
	);
};

export const CheckRoundFilledIcon = ({fill = "#212121", ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.7071 10.7071L17.4142 10L16 8.58579L15.2929 9.29289L11 13.5858L8.70711 11.2929L8 10.5858L6.58579 12L7.29289 12.7071L10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16C11.2652 16 11.5196 15.8946 11.7071 15.7071L16.7071 10.7071Z'
				fill={fill}
			/>
		</svg>
	);
};

export const FilterIcon = ({fill = "#212121", ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z'
				fill='#212121'></path>
		</svg>
	);
};

// NAVIGATION
export const DropNextIcon = ({fill = "#212121", ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			{...props}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.58594 7.99966L10.293 8.70677L13.5859 11.9997L10.293 15.2926L9.58594 15.9997L11.0002 17.4139L11.7073 16.7068L15.7073 12.7068C16.0978 12.3162 16.0978 11.6831 15.7073 11.2926L11.7073 7.29256L11.0002 6.58545L9.58594 7.99966Z'
				fill={fill || "#94A0B1"}
			/>
		</svg>
	);
};

export const CommentDotsIcon = ({fill = "#212121", ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.5371 2.55176C10.17 2.18847 13.83 2.18847 17.4629 2.55176L18.7752 2.68299C20.1391 2.81938 21.2273 3.88106 21.3973 5.24118L21.4216 5.43581C21.8006 8.46713 21.8006 11.5338 21.4216 14.5652C21.2477 15.9564 20.0651 17.0005 18.663 17.0005H13H12.638L12.3598 17.2323L8.00001 20.8654L8.00001 18.0005V17.0005H7.00001H5.33707C3.93497 17.0005 2.75229 15.9564 2.57838 14.5652C2.19946 11.5338 2.19946 8.46713 2.57838 5.43581L2.6027 5.24118C2.77272 3.88106 3.86093 2.81938 5.22484 2.68299L6.5371 2.55176ZM0.59382 5.18774C0.194313 8.3838 0.194313 11.6172 0.59382 14.8132C0.892836 17.2054 2.92632 19.0005 5.33707 19.0005H6.00001V20.8654C6.00001 22.5611 7.97772 23.4874 9.28038 22.4019L13.3621 19.0005H18.663C21.0737 19.0005 23.1072 17.2054 23.4062 14.8132C23.8057 11.6172 23.8057 8.3838 23.4062 5.18774L23.3819 4.99311C23.0961 2.70681 21.2668 0.922181 18.9742 0.692914L17.6619 0.561689C13.8967 0.185166 10.1033 0.185166 6.33809 0.561689L5.02584 0.692914C2.73317 0.922181 0.903937 2.70681 0.618148 4.99311L0.59382 5.18774ZM8.00001 11.0005C8.55229 11.0005 9.00001 10.5528 9.00001 10.0005C9.00001 9.4482 8.55229 9.00048 8.00001 9.00048C7.44772 9.00048 7.00001 9.4482 7.00001 10.0005C7.00001 10.5528 7.44772 11.0005 8.00001 11.0005ZM12 11.0005C12.5523 11.0005 13 10.5528 13 10.0005C13 9.4482 12.5523 9.00048 12 9.00048C11.4477 9.00048 11 9.4482 11 10.0005C11 10.5528 11.4477 11.0005 12 11.0005ZM17 10.0005C17 10.5528 16.5523 11.0005 16 11.0005C15.4477 11.0005 15 10.5528 15 10.0005C15 9.4482 15.4477 9.00048 16 9.00048C16.5523 9.00048 17 9.4482 17 10.0005Z'
				fill='#94A0B1'
			/>
		</svg>
	);
};

export const SendIcon = ({fill, ...props}) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			{...props}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.7484 10.2246C24.1899 10.9702 24.1899 13.0318 22.7484 13.7774L6.13663 22.3697C4.54484 23.193 2.75693 21.6684 3.31852 19.9665L5.94701 12.001L3.31852 4.03545C2.75694 2.33358 4.54484 0.808944 6.13663 1.63229L22.7484 10.2246ZM7.7231 13.001L5.21779 20.5933L21.8295 12.001L5.21779 3.40872L7.7231 11.001H13C13.5523 11.001 14 11.4487 14 12.001C14 12.5533 13.5523 13.001 13 13.001H7.7231Z'
				fill={fill || "white"}
			/>
		</svg>
	);
};

export const AttachIcon = ({fill, ...props}: any) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.0688 4.57163L10.9192 7.72119L10.9175 7.71945L5.9497 12.6872C3.99707 14.6398 3.99707 17.8057 5.9497 19.7583C7.90232 21.7109 11.0681 21.7109 13.0208 19.7583L21.7928 10.9862L22.4999 10.2791L23.9142 11.6933L23.2071 12.4004L14.435 21.1725C11.7013 23.9062 7.26915 23.9062 4.53548 21.1725C1.80181 18.4388 1.80181 14.0067 4.53548 11.273L11.2279 4.5806C11.2447 4.56378 11.262 4.54769 11.2796 4.53231L12.6545 3.15741C14.6275 1.18451 17.8262 1.18451 19.7991 3.15742C21.772 5.13032 21.772 8.32903 19.7991 10.3019L17.7292 12.3718L17.7292 12.3718L12.214 17.887C11.0258 19.0752 9.09943 19.0752 7.91128 17.887C6.72313 16.6989 6.72313 14.7725 7.91128 13.5843L14.4783 7.01733L15.1854 6.31023L16.5996 7.72444L15.8925 8.43155L9.32549 14.9985C8.91839 15.4056 8.91839 16.0657 9.32549 16.4728C9.7326 16.8799 10.3926 16.8799 10.7997 16.4728L18.1296 9.14292C18.1316 9.14093 18.1336 9.13895 18.1356 9.13698L18.3849 8.88773C19.5767 7.69587 19.5767 5.76349 18.3849 4.57163C17.193 3.37977 15.2606 3.37977 14.0688 4.57163Z'
				fill={fill || "#94A0B1"}
			/>
		</svg>
	);
};

// FILES
export const DocTextIcon = ({ fill = "#212121", ...props }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_14578_29875)">
				<path fillRule="evenodd" clipRule="evenodd" d="M6 2C5.44771 2 5 2.44772 5 3L5 21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V9L13 9C12.4477 9 12 8.55228 12 8V2L6 2ZM14 2.41421L18.5858 7H14V2.41421ZM3 3C3 1.34314 4.34315 0 6 0L14 0C14.2652 0 14.5196 0.105357 14.7071 0.292893L20.7071 6.29289C20.8946 6.48043 21 6.73478 21 7V21C21 22.6569 19.6569 24 18 24H6C4.34315 24 3 22.6569 3 21L3 3ZM7 13H8L16 13H17V15H16L8 15H7V13ZM7 17H8H13H14V19H13H8H7V17Z" fill="#212121" />
			</g>
			<defs>
				<clipPath id="clip0_14578_29875">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>

	);
};
