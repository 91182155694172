import cn from "classnames";
import "./button.scss";
import {Link} from "react-router-dom";

import React, {FC, ButtonHTMLAttributes, createElement} from "react";
import {IconsMap, createIcon} from "../Icons/IconsMap";
//@ts-ignore
import TinkoffLogo from "../../../public/images/tinkoff-btn-logo.png";
import {Loader} from "./ButtonLoader";
type BtnVariant =
	| "primary"
	| "secondary"
	| "tinkoff"
	| "purple"
	| "success"
	| "none";
type BtnSize = "xss" | "s" | "m" | "l";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	className?: string;
	text?: string;
	size?: BtnSize;
	variant?: BtnVariant;
	iconAfter?: keyof typeof IconsMap;
	iconBefore?: keyof typeof IconsMap;
	svgProps?: any;
	link?: string | boolean | number;
	isLoading?: boolean;
	fullWidth?: boolean;
};

export const Button: FC<ButtonProps> = (props) => {
	const {
		text,
		size,
		variant = "primary",
		className,
		iconBefore,
		iconAfter,
		svgProps,
		link,
		fullWidth,
		isLoading,
		...rest
	} = props;

	const varClassMap: {[key in BtnVariant]: string} = {
		primary: "btn-primary",
		secondary: "btn-secondary border-button",
		tinkoff: "btn-tinkoff",
		purple: "btn-purple",
		success: "btn-success",
		none: "",
	};

	const sizeClassMap: {[key in BtnSize]: string} = {
		xss: "btn-xss",
		s: "small-button btn-s",
		m: "btn-m",
		l: "big-button btn-l",
	};

	return createElement(
		link ? Link : "button",
		{
			type: "button",
			...Object.assign(rest),
			className: cn("btn button", "action-button", className, {
				[`${sizeClassMap[size]}`]: size,
				"with-icon": iconBefore || iconAfter,
				[`${varClassMap[variant]}`]: variant,
				w100: fullWidth,
			}),
			to: link,
		},
		<>
			{iconBefore && createIcon({icon: iconBefore, svgProps})}
			{isLoading && <Loader />}
			{text}
			{variant === "tinkoff" && <img src={TinkoffLogo} />}
			{iconAfter && createIcon({icon: iconAfter, svgProps})}
		</>,
	);
	//  (
	// 	<button
	// className={cn("btn button", "action-button", className, {
	// 	[`${sizeClassMap[size]}`]: sizeClassMap,
	// 	"with-icon": iconBefore || iconAfter,
	// 	[`${varClassMap[variant]}`]: variant,
	// })}
	// 		{...rest}>
	// <>
	// 	{iconBefore && createIcon({icon: iconBefore, svgProps})}
	// 	{text}
	// 	{variant === "tinkoff" && <img src={TinkoffLogo} />}
	// 	{iconAfter && createIcon({icon: iconAfter, svgProps})}
	// </>
	// 	</button>
	// );
};
