import React from "react";

export const AtipsMarketFullLogo = () => {
	return (
		<svg
			width='156'
			height='36'
			viewBox='0 0 156 36'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.4339 11.0484C13.065 9.33336 11.1696 8.49336 8.71259 8.49336C6.36089 8.49336 4.36019 9.40336 2.67539 11.1884C1.02569 12.9734 0.183289 15.1434 0.183289 17.7334C0.183289 20.3234 1.02569 22.5284 2.67539 24.3134C4.36019 26.0984 6.36089 26.9734 8.71259 26.9734C11.1696 26.9734 13.065 26.1334 14.4339 24.4184V26.4834H18.9618V8.98336H14.4339V11.0484ZM6.08009 21.3034C5.16749 20.3584 4.71119 19.1684 4.71119 17.7334C4.71119 16.2984 5.16749 15.1084 6.08009 14.1984C6.99269 13.2534 8.15099 12.7984 9.55499 12.7984C10.959 12.7984 12.1173 13.2534 13.0299 14.1984C13.9776 15.1084 14.4339 16.2984 14.4339 17.7334C14.4339 19.1684 13.9776 20.3584 13.0299 21.3034C12.1173 22.2134 10.959 22.6684 9.55499 22.6684C8.15099 22.6684 6.99269 22.2134 6.08009 21.3034Z'
				fill='black'
			/>
			<path
				d='M33.1063 8.98336H29.14V4.12225H24.6121V8.98336H21.5584V13.3234H24.6121V20.6034C24.6121 25.3984 26.8936 27.1834 33.1063 26.4834V22.3884C30.5089 22.5284 29.14 22.4934 29.14 20.6034V13.3234H33.1063V8.98336Z'
				fill='black'
			/>
			<path
				d='M38.5451 6.88336C40.0895 6.88336 41.3531 5.62336 41.3531 4.11836C41.3531 2.61336 40.0895 1.31836 38.5451 1.31836C37.0358 1.31836 35.7722 2.61336 35.7722 4.11836C35.7722 5.62336 37.0358 6.88336 38.5451 6.88336ZM40.8266 26.4834V8.98336H36.2987V26.4834H40.8266Z'
				fill='black'
			/>
			<path
				d='M55.185 8.49336C52.728 8.49336 50.8326 9.33336 49.4988 11.0484V8.98336H44.9709V33.4834H49.4988V24.4184C50.8326 26.1334 52.728 26.9734 55.185 26.9734C57.5367 26.9734 59.5725 26.0984 61.2222 24.3134C62.907 22.5284 63.7494 20.3234 63.7494 17.7334C63.7494 15.1434 62.907 12.9734 61.2222 11.1884C59.5725 9.40336 57.5367 8.49336 55.185 8.49336ZM50.8677 21.3034C49.9551 20.3584 49.4988 19.1684 49.4988 17.7334C49.4988 16.2984 49.9551 15.1084 50.8677 14.1984C51.7803 13.2534 52.9386 12.7984 54.3426 12.7984C55.7466 12.7984 56.9049 13.2534 57.8175 14.1984C58.7652 15.1084 59.2215 16.2984 59.2215 17.7334C59.2215 19.1684 58.7652 20.3584 57.8175 21.3034C56.9049 22.2134 55.7466 22.6684 54.3426 22.6684C52.9386 22.6684 51.7803 22.2134 50.8677 21.3034Z'
				fill='black'
			/>
			<path
				d='M70.7425 13.9534C70.7425 13.0784 71.62 12.5184 72.7783 12.5184C74.077 12.5184 75.0247 13.1134 75.5863 14.3034L79.4473 12.2034C78.0784 9.82336 75.6214 8.49336 72.7783 8.49336C70.8829 8.49336 69.3034 8.98336 68.0047 9.99836C66.7411 10.9784 66.1093 12.3434 66.1093 14.0584C66.1093 17.6984 69.3736 18.7834 71.971 19.4484C73.7611 19.9034 75.1651 20.4284 75.1651 21.3734C75.1651 22.3884 74.3578 22.8784 72.7432 22.8784C70.9531 22.8784 69.8299 22.1084 69.3034 20.6034L65.3722 22.8084C66.6709 25.5734 69.1279 26.9734 72.7432 26.9734C74.7439 26.9734 76.4287 26.4834 77.7625 25.5384C79.1314 24.5584 79.7983 23.1584 79.7983 21.3734C79.8334 17.6284 76.4989 16.4034 73.9366 15.8784C72.1465 15.4234 70.7425 14.8984 70.7425 13.9534Z'
				fill='black'
			/>
			<path
				d='M88.725 9.46918C88.725 5.17364 92.2172 1.69141 96.525 1.69141H143.812C148.12 1.69141 151.612 5.17364 151.612 9.46918V19.6775C151.612 23.9731 148.12 27.4553 143.812 27.4553H96.525C92.2172 27.4553 88.725 23.9731 88.725 19.6775V9.46918Z'
				fill='#3586FF'
			/>
			<path
				d='M92.625 13.3579C92.625 9.06231 96.1172 5.58008 100.425 5.58008H147.712C152.02 5.58008 155.512 9.06231 155.512 13.3579V23.5662C155.512 27.8617 152.02 31.344 147.712 31.344H100.425C96.1172 31.344 92.625 27.8617 92.625 23.5662V13.3579Z'
				fill='#FD4AA7'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M150.923 6.26745C151.366 7.24384 151.612 8.3277 151.612 9.46897V19.6773C151.612 23.9728 148.12 27.4551 143.812 27.4551H96.525C95.3805 27.4551 94.2935 27.2093 93.3143 26.7677C92.8715 25.7913 92.625 24.7075 92.625 23.5662V13.3579C92.625 9.06231 96.1172 5.58008 100.425 5.58008H147.712C148.857 5.58008 149.944 5.82589 150.923 6.26745Z'
				fill='#7027C2'
			/>
			<path
				d='M106.063 13.155C104.987 13.155 104.191 13.5594 103.677 14.3839C103.193 13.5594 102.46 13.155 101.461 13.155C100.479 13.155 99.7299 13.5283 99.2307 14.2905V13.3572H97.5459V21.135H99.2307V16.7794C99.2307 15.4261 99.9483 14.7261 100.962 14.7261C101.898 14.7261 102.429 15.3483 102.429 16.4217V21.135H104.113V16.7794C104.113 15.4105 104.769 14.7261 105.814 14.7261C106.75 14.7261 107.327 15.3483 107.327 16.4217V21.135H109.012V16.3128C109.012 14.3839 107.826 13.155 106.063 13.155Z'
				fill='white'
			/>
			<path
				d='M116.993 14.4616C116.353 13.5905 115.448 13.155 114.263 13.155C113.186 13.155 112.281 13.5439 111.517 14.3372C110.753 15.1305 110.378 16.095 110.378 17.2461C110.378 18.3816 110.753 19.3616 111.517 20.155C112.281 20.9483 113.186 21.3372 114.263 21.3372C115.448 21.3372 116.353 20.9016 116.993 20.015V21.135H118.677V13.3572H116.993V14.4616ZM112.765 19.035C112.297 18.5528 112.063 17.9616 112.063 17.2461C112.063 16.5305 112.297 15.9394 112.765 15.4728C113.233 14.9905 113.826 14.7572 114.528 14.7572C115.23 14.7572 115.823 14.9905 116.291 15.4728C116.759 15.9394 116.993 16.5305 116.993 17.2461C116.993 17.9616 116.759 18.5528 116.291 19.035C115.823 19.5016 115.23 19.735 114.528 19.735C113.826 19.735 113.233 19.5016 112.765 19.035Z'
				fill='white'
			/>
			<path
				d='M122.326 13.3572H120.641V21.135H122.326V17.215C122.326 16.4217 122.56 15.8461 123.044 15.5039C123.527 15.1461 124.089 14.9905 124.728 15.0372V13.2172C123.558 13.2172 122.747 13.6994 122.326 14.6639V13.3572Z'
				fill='white'
			/>
			<path
				d='M129.312 17.1683L132.744 13.3572H130.684L127.658 16.795V10.2461H125.973V21.135H127.658V17.5416L130.856 21.135H132.868L129.312 17.1683Z'
				fill='white'
			/>
			<path
				d='M140.912 17.9616C140.959 17.6661 140.974 17.4328 140.974 17.2616C140.974 16.1261 140.6 15.1461 139.867 14.3528C139.134 13.5594 138.198 13.155 137.059 13.155C135.858 13.155 134.859 13.5439 134.095 14.3372C133.33 15.115 132.94 16.0794 132.94 17.2461C132.94 18.4283 133.33 19.4083 134.11 20.1861C134.89 20.9483 135.92 21.3372 137.184 21.3372C138.697 21.3372 139.836 20.7772 140.6 19.6416L139.212 18.8328C138.759 19.4705 138.088 19.7972 137.199 19.7972C135.904 19.7972 134.953 19.1439 134.703 17.9616H140.912ZM134.688 16.5928C134.922 15.4105 135.795 14.6794 137.043 14.6794C138.151 14.6794 139.04 15.3328 139.274 16.5928H134.688Z'
				fill='white'
			/>
			<path
				d='M146.565 13.3572H144.631V11.1794L142.946 11.6772V13.3572H141.511V14.975H142.946V18.7083C142.946 20.7461 143.991 21.4461 146.565 21.135V19.6261C145.286 19.6883 144.631 19.7039 144.631 18.7083V14.975H146.565V13.3572Z'
				fill='white'
			/>
		</svg>
	);
};
