import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import cn from "classnames";
import { AsideToggleContext } from "../../sellerArea/context/AsideToggleContext";
import { TestModeNotification } from "./TestModeNotification";

import { useGetUser } from "../../../api/user/index";

import "./styles/Navbar.scss";
import { BloggerChatButton } from "../../../features/bloggers/components/BloggerChatButton";
import useWebSocket, { ReadyState } from "react-use-websocket";
import {
	SOCKET_BASE_URL,
	getAccessToken,
	SOCKET_CONNECTION_STATUSES,
} from "../../../api/constants";
import { useMounted } from "../../../utils/hooks/useMounted";

const PersonalAreaNavbar = ({ userState }) => {
	const { toggleFunction } = useContext(AsideToggleContext);
	let navigate = useNavigate();
	let location = useLocation();
	const [mounted] = useMounted();
	const { user, balance, isLoadingUser } = useGetUser({ navigate });

	const [unreadChatsCount, setUnreadChatsCount] = useState(0);

	const { lastJsonMessage: notificationEvent, readyState: notificationsWsStatus } = useWebSocket(
		`${SOCKET_BASE_URL}/notifications?token=${getAccessToken()}`,
		{
			onMessage: (e) => {
				const data = JSON.parse(e?.data);
				const unread_chats_count = data?.unread_chats_count;

				if (unreadChatsCount !== unread_chats_count) {
					setUnreadChatsCount(unread_chats_count);
				}
			},
		}
	);

	if (mounted) {
		const unread_chats_count = notificationEvent?.unread_chats_count;
		if (unreadChatsCount !== unread_chats_count) {
			setUnreadChatsCount(unread_chats_count);
		}
	}

	return (
		<div className="personal-area-header">
			<TestModeNotification />{" "}
			<div
				className={cn("ph-40", {
					"bg-grey":
						location.pathname === "/chat" ||
						location.pathname === "/blogger" ||
						location.pathname === "/product" ||
						location.pathname === "/service" ||
						location.pathname.includes("profile") ||
						location.pathname === "/",
				})}
			>
				<div className="personal-area-header-wrapper">
					<button className="button_aside-toggler" onClick={toggleFunction}>
						<img src="images/icons/ui/actions/menu-list.svg" alt="burger" />
					</button>

					{!isLoadingUser && (
						<Link to="/payments-history">
							<span className="personal-area-header__balance">{balance} ₽</span>
						</Link>
					)}
					<BloggerChatButton className="mr-16" count={unreadChatsCount} />
					{!!userState.image && userState.image?.length > 0 ? (
						<Link
							to="/profile"
							className="user-profile-icon"
							style={{
								backgroundImage: `url(${
									user?.avatar_url || `/images/icons/ui/actions/black/person-small.svg`
								})`,
								backgroundSize: user?.avatar_url ? "auto 100%" : "unset",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "50% 50%",
							}}
						></Link>
					) : (
						<Link
							to="/profile"
							className="user-profile-icon"
							style={{
								backgroundColor: "#005bff",
							}}
						>
							<span>{`${userState?.surname?.length > 0 ? userState?.surname?.split("")[0] : ""}${
								userState?.name?.length > 0 ? userState.name?.split("")[0] : ""
							}`}</span>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default connect(null, null)(PersonalAreaNavbar);
