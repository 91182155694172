import React, {FC} from "react";
import cn from "classnames";
import {useNavigate} from "react-router-dom";
import {IconButton, IconBtnProps} from "../../../ui-kit/buttons/IconButton";

type BloggerChatButtonProps = Partial<IconBtnProps> & {
	blogger_id?: number | string;
	count?: number;
};

export const BloggerChatButton: FC<BloggerChatButtonProps> = ({
	blogger_id,
	className,
	count,
	...props
}) => {
	const navigate = useNavigate();
	const goToChat = (e) => {
		e?.preventDefault();
		e?.stopPropagation();
		navigate(blogger_id ? `/chat?id=${blogger_id}` : "/chat");
	};
	return (
		<div className={cn("chat-btn blogger-chat-btn", className)}>
			<IconButton onClick={goToChat} icon='comment_dots' {...props} />
			{count > 0 && <span className='chat-btn-counter'>{count}</span>}
		</div>
	);
};
