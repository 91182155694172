// import {ConnectedRouter} from "connected-react-router";
import { QueryClient, QueryClientProvider } from "react-query";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App/App.jsx";
import { appStore, history } from "./Store/Store";
import { setupInterceptors } from "./services/setupInterceptors.js";
import { CookiesNotifications } from "./components/universal/CookiesNotification/CookiesNotification";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { STAT_API_URL } from "./api/constants";

const queryClient = new QueryClient();

export const client = new ApolloClient({
	uri: `${STAT_API_URL}/graphql`,
	cache: new InMemoryCache(),
});

ReactDOM.render(
	// <ApolloProvider client={apolloClient}>
	<QueryClientProvider client={queryClient}>
		<ApolloProvider client={client}>
			<Provider store={appStore}>
				{/* <ConnectedRouter history={history}> */}
				<App />
				<CookiesNotifications />
				<div id="block-on-mobile">
					<img src="images/logo.svg" alt="" id="mobile-logo" />
				</div>

				{/* </ConnectedRouter> */}
			</Provider>
		</ApolloProvider>
	</QueryClientProvider>,
	// {/* </ApolloProvider> */}
	document.getElementById("root")
);

setupInterceptors();
