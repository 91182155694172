import React from "react";
import {Navigate, Outlet} from "react-router-dom";

const PrivateRoute = ({login}) => {
	return (!!login?.token && login.token.length > 0) ||
		(localStorage.getItem("token")?.length > 0 &&
			!!localStorage.getItem("userid")) ? (
		<Outlet />
	) : (
		<Navigate to='/sign-in' />
	);
};

export {PrivateRoute};
