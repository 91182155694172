// @ts-nocheck
import React, { FC, useContext } from "react";
import {useLogout} from "../../../api/auth/index";
import { ModalContentWrapper } from "../../universal/Modals/ModalContentWrapper";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import { useLocation, useNavigate } from "react-router-dom";

export const LogoutProfileModal: FC = () => {
	const { handleModal } = useContext(ModalContext) as any;
	const navigate = useNavigate();

	const {logout} = useLogout({
		onSettled: () => {
			navigate("/sign-in");
			handleModal()
		},
	});

	return (
		<ModalContentWrapper
			className='delete-profile-popup scrollbar'
			style={{
				maxHeight: "640px",
				overflowY: "auto",
				width: "380px",
			}}
			title='Вы уверены?'
			primaryButton={{ text: "Выйти", onClick: logout }}
			withCancelBtn
		/>
	);
};
