import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {handleToggleOpenClick} from "../../../scripts/PersonalAreaScripts";

const initialGenderStatusState = {
	50: false,
	51: false,
};

const SideGenderFilters = ({sideFilters, setSideFilters}) => {
	const [mapGenderState, setMapGenderState] = useState(
		initialGenderStatusState,
	);

	const handleSelectParamClick = (id) => {
		if (!mapGenderState.hasOwnProperty(id)) return;

		setMapGenderState({...mapGenderState, [id]: !mapGenderState[id]});
	};

	useEffect(() => {
		// обнуление
		if (sideFilters.gender === null) {
			setMapGenderState(initialGenderStatusState);
		}
	}, [sideFilters, initialGenderStatusState]);

	useEffect(() => {
		const filteredObject = Object.keys(mapGenderState).filter(
			(key) => mapGenderState[key] === true,
		);
		if (Object.keys(filteredObject).length > 0)
			setSideFilters({...sideFilters, gender: filteredObject});
	}, [mapGenderState]);

	return (
		<div className='statistics-filter-items-block'>
			<div
				className='statistics-filter-items-block-title'
				onClick={(e) => handleToggleOpenClick(e)}>
				Пол
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z'
						fill='#212121'></path>
				</svg>
			</div>
			<div className='statistics-filter-items-block-subitems'>
				<div className='flex-block flex-start'>
					<div className='flex-block-column'>
						<div
							className='form-field'
							onClick={() => handleSelectParamClick("50")}>
							<div
								className={`check-button-item ${
									mapGenderState["50"] ? "selected" : ""
								}`}
								id='show-stat'>
								<span>Мужской</span>
							</div>
						</div>
						<div
							className='form-field'
							onClick={() => handleSelectParamClick("51")}>
							<div
								className={`check-button-item ${
									mapGenderState["51"] ? "selected" : ""
								}`}
								id='click-stat'>
								<span>Женский</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, null)(SideGenderFilters);
