import axios from "axios";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import {
	useQuery,
	useMutation,
	MutationOptions,
	QueryObserverBaseResult,
	QueryObserverOptions,
	UseMutationResult,
} from "react-query";
import React, { useState } from "react";

import { isArr } from "../utils/helpers";
import {
	ATIPS_CREATORS_API_URL,
	ADVERTISING_API_URL,
	SELLER_API_KEY,
	getAccessToken,
	ATIPS_SELLER_URL,
	ATIPS_ROOT_URL,
	PARSE_URL,
	ATIPS_IO,
	CATEGORIES_PATH,
} from "../api/constants";
import { sellerAxiosInstance } from "../api/useAxios";

export type getAxiosQueryProps = AxiosRequestConfig & {
	data?: any;
	baseURLKey?: "seller" | "root" | "parse" | "atips_io" | "creators" | "chat";
	url: string;
	isApiKeyRequired?: boolean;
	headers?: any;
	noApiKey?: boolean;
};

export const useAxios = ({
	data,
	baseURLKey,
	url,
	method = "get",
	headers = {},
	isApiKeyRequired = true,
}: getAxiosQueryProps) => {
	const BASE_URL = {
		seller: ATIPS_SELLER_URL,
		root: ATIPS_ROOT_URL,
		parse: PARSE_URL,
		atips_io: ATIPS_IO,
		creators: ATIPS_CREATORS_API_URL,
		chat: ADVERTISING_API_URL,
	}[baseURLKey || "root"];
	const isCreatorsApi = baseURLKey === "creators";

	const queryUrl = data?.id ? `${BASE_URL}${url}` : `${BASE_URL}${url}`;
	const options = {
		headers: isCreatorsApi
			? {
				Authorization: `Bearer ${getAccessToken()}`,
				...headers,
			}
			: isApiKeyRequired
				? {
					"x-advertisers-api-key": SELLER_API_KEY,
					...headers,
				}
				: headers,
	};

	return data
		? sellerAxiosInstance[method](queryUrl, data, options)
		: sellerAxiosInstance[method](queryUrl, options);
};

// export type UseTypedMutationOptions<T> = {
// 	options: UseMutationOptions & {
// 	  mutationFn: MutationFunction;
// 	};
// 	dependentQueryKeys: T["dependentQueryKeys"];
// 	queryClient: QueryClient;
//   };

//   export type UseTypedMutationResult<T> = UseMutationResult<T["returnType"],
// 	unknown,
// 	T["paramsType"],
// 	unknown
//   >;

export type useBaseMutationProps = MutationOptions & getAxiosQueryProps;

export const useBaseMutation = <
	Response = unknown,
	Error = unknown,
	Params = unknown,
>({
	baseURLKey,
	url,
	method = "post",
	onSuccess,
	onError,
	headers = {},
	isApiKeyRequired,
	...rest
}: useBaseMutationProps & { [prop: string]: any }) => {
	const [error, setError] = useState();

	const [responseData, setResponseData] = useState(null);

	const mutation = useMutation(
		(data: any) =>
			useAxios({ data, baseURLKey, url, method, headers, isApiKeyRequired }),
		{
			...rest,
			onSuccess: (res: any, y, z) => {
				onSuccess && onSuccess(res, y, z);
				setResponseData(res?.data);
				setError(null);
			},
			onError: (res: any, y, z) => {
				console.error("ON ERROR", url, { ...res }, y, z);
				onError && onError(res, y, z);
				setError(res);
			},
		},
	) as UseMutationResult<Response, Error, Params, unknown>;
	return { mutation, error, responseData };
};

export type useBaseQueryProps = QueryObserverOptions &
	getAxiosQueryProps & { options?: QueryObserverOptions };

export const useBaseQuery = <TData = unknown, TError = unknown>({
	baseURLKey,
	url,
	method,
	queryKey = "",
	options = {},
	isApiKeyRequired,
	...rest
}: useBaseQueryProps) => {
	const query = useQuery(
		queryKey,
		(data) => useAxios({ data, baseURLKey, url, method, isApiKeyRequired }),
		{
			notifyOnChangeProps: "tracked",
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			...options,
			...rest,
		},
	);
	return query as QueryObserverBaseResult<TData, TError>;
};

export const getFrames = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		url: "/frame",
		queryKey: "frames",
		...props,
	});

export const getGenres = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult => {
	const { data, ...rest }: any = useBaseQuery({
		baseURLKey: "root",
		url: "/genre",
		queryKey: "genres",
		...props,
	});

	const genres = data?.data?.map((genre = {}) => ({
		...genre,
		//@ts-ignore

		title: genre?.title?.toLowerCase(),
	}));
	return { genres, ...rest };
};

export const getRegions = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		baseURLKey: "root",
		url: "/frame/11",
		queryKey: "regions",
		...props,
	});
export const getGenders = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		baseURLKey: "root",
		url: "/frame/4",
		queryKey: "genders",
		...props,
	});

export const getSizes = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		baseURLKey: "root",
		url: "/frame/2",
		queryKey: "sizes",
		...props,
	});

export const getAges = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		baseURLKey: "root",
		url: "/frame/3",
		queryKey: "ages",
		...props,
	});

export const getColors = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		baseURLKey: "root",
		url: "/frame/1",
		queryKey: "colors",
		...props,
	});

type getCategoriesResult = QueryObserverBaseResult & {
	productCategories: any[];
	serviceCategories: any[];
};

{
	/* TODO: https://api.dev.testatips.ru/domain/categories/extra */
}
export const getCategories = (
	props?: QueryObserverOptions,
): getCategoriesResult => {
	const { data, ...rest }: any = useBaseQuery({
		baseURLKey: "atips_io",
		url: `/pregenerate/getextradev/`,
		headers: null,
		isApiKeyRequired: false,
		// baseURLKey: "root",
		// url: "/domain/categories/extra",

		queryKey: "categories",
		...props,
	});

	// const sortedCategories = data?.data && sortCategories(data?.data);

	return {
		// productCategories: sortedCategories?.productCategories || [],
		// serviceCategories: sortedCategories?.serviceCategories || [],
		productCategories: data?.data?.[0],

		serviceCategories: data?.data?.[1],
		...rest,
	};
};

const entityArray = (arr: any[], key: string) => {
	let obj;
	arr.forEach((item, index) => {
		obj = { ...obj, [item[key]]: item };
	});
	return obj;
};

const entityToArray = (obj) => {
	let arr = [];
	Object.keys(obj).forEach((key) => arr.push(obj[key]));
	return arr;
};

const compare = (a, b) => {
	if (a.service < b.serivce) {
		return -1;
	}
	if (a.service > b.service) {
		return 1;
	}
	return 0;
};

export const sortCategories = (array) => {
	let firstLevel = array.filter(
		(item) =>
			item?.category?.parent_id === 1 || item?.category?.parent_id === 2,
	);

	firstLevel = firstLevel.map((elem) => {
		return { ...elem, orderIndex: 1, isDisabled: true };
	});
	let transformedFirstLevel = entityArray(firstLevel, "id");
	let fullListCategories = firstLevel;
	array = array?.filter(
		(item) =>
			item?.category?.parent_id !== null &&
			item?.category?.parent_id !== 1 &&
			item?.category?.parent_id !== 2,
	);

	isArr(array) &&
		array?.forEach((item, index) => {
			const parentId = item?.category?.parent_id;

			if (transformedFirstLevel && transformedFirstLevel[parentId]) {
				if (transformedFirstLevel[parentId].orderIndex === 2) {
					if (item.tips_counter === 0) return; // выходим, если у элемента нет типсов
					if (transformedFirstLevel[parentId].child) {
						transformedFirstLevel[parentId].child.push({
							...item,
							orderIndex: 3,
						});
					} else {
						transformedFirstLevel = {
							...transformedFirstLevel,
							[parentId]: {
								...transformedFirstLevel[parentId],
								child: [{ ...item, orderIndex: 3 }],
							},
						};
					}
				} else {
					transformedFirstLevel = {
						...transformedFirstLevel,
						[item.id]: {
							...item,
							orderIndex: transformedFirstLevel[parentId]["orderIndex"] + 1,
						},
					};
				}
			}
			// TODO: общий отсортированный список
			if (transformedFirstLevel && transformedFirstLevel[parentId]) {
				if (transformedFirstLevel[parentId].orderIndex === 1) {
					const fullListIndex = fullListCategories.findIndex(
						(elem) => elem.id === item?.category?.parent_id,
					);
					fullListCategories.splice(fullListIndex + 1, 0, {
						...item,
						orderIndex: fullListCategories[fullListIndex]["orderIndex"] + 1,
						isDisabled: true,
					});
				} else if (transformedFirstLevel[parentId].orderIndex === 2) {
					const fullListIndex = fullListCategories.findIndex(
						(elem) => elem.id === item?.category?.parent_id,
					);
					fullListCategories.splice(fullListIndex + 1, 0, {
						...item,
						orderIndex: fullListCategories[fullListIndex]["orderIndex"] + 1,
					});
				}
			}
		});
	array = transformedFirstLevel && Object.keys(transformedFirstLevel);
	isArr(array) &&
		transformedFirstLevel &&
		array.forEach((key, index) => {
			const resolvedValue = transformedFirstLevel[key];
			if (
				transformedFirstLevel[key]?.orderIndex === 2 &&
				(!("child" in transformedFirstLevel[key]) ||
					transformedFirstLevel[key]?.child?.length === 0)
			) {
				delete transformedFirstLevel[key];
				return;
			}
			if (resolvedValue && resolvedValue?.orderIndex === 2) {
				const parentId = resolvedValue?.category?.parent_id;
				if (
					transformedFirstLevel &&
					transformedFirstLevel[parentId] &&
					transformedFirstLevel[parentId]?.orderIndex === 1
				) {
					if (transformedFirstLevel[parentId].child) {
						transformedFirstLevel[parentId].child.push(resolvedValue);
					} else {
						transformedFirstLevel = {
							...transformedFirstLevel,
							[parentId]: {
								...transformedFirstLevel[parentId],
								child: [resolvedValue],
							},
						};
					}
				} else {
					transformedFirstLevel = {
						...transformedFirstLevel,
						[parentId]: {
							...resolvedValue,
							orderIndex: transformedFirstLevel[parentId]["orderIndex"] + 1,
						},
					};
				}
			}
		});
	const resultObject =
		transformedFirstLevel && entityToArray(transformedFirstLevel);
	firstLevel =
		resultObject &&
		resultObject.filter(
			(item) => item.orderIndex !== 2 && item.child?.length > 0,
		);
	const categories =
		firstLevel && firstLevel.filter((elem) => elem.category.parent_id === 1);
	const service =
		firstLevel && firstLevel.filter((elem) => elem.category.parent_id === 2);

	return {
		productCategories: categories?.map((productCategory = { category: {} }) => ({
			...productCategory,
			...productCategory?.category,
		})),
		serviceCategories: service?.map((serviceCategory = { category: {} }) => ({
			...serviceCategory,
			...serviceCategory?.category,
		})),
		categoriesFullList: fullListCategories,
	};
};
