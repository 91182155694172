import React from "react";
import "./styles/PolicyPage.scss";
import {AtipsMarketFullLogo} from "../../../public/images/logo";
import {LEGAL_POLICY} from "../../utils/constants/policies";

const LegalPage = () => {
	return (
		<div className='policy-page'>
			<div className='policy-page__header'>
				<AtipsMarketFullLogo />
			</div>

			<div className='policy-page__content'>
				<div className='policy-page__title'>
					Пользовательское соглашение — оферта
				</div>
				<div
					style={{
						fontSize: "14px",
						lineHeight: "22px",
					}}
					dangerouslySetInnerHTML={{
						__html: LEGAL_POLICY,
					}}
				/>
			</div>
		</div>
	);
};

export default LegalPage;
