import React, { FC } from "react";
import cn from "classnames";

export type ErrorProps = {
	error?: { msg?: string; type?: string } | boolean;
	errorType?: "required" | "get" | any;
	errorClass?: string;
};

export const Error: FC<ErrorProps> = ({ error, errorType, errorClass }) => {
	const errorMsg = {
		default: "Произошла ошибка",
		required: "Обязательно для заполнения",
		save: "Ошибка при сохранении данных",
		LOGIN_BAD_CREDENTIALS: "Неверный логин или пароль",
		LOGIN_USER_NOT_VERIFIED: "Почта не подтверждена",
		"Аккаунт не активирован":
			"Ваш аккаунт еще на рассмотрении. <br /> Свяжитесь с администратором.",
		"You've been banned and are not allowed to login":
			"Ваш аккаунт заблокирован. <br /> Свяжитесь с администратором.",
	}[errorType || "default"];
	if (!error) return null;
	return (
		<p
			className={cn("error field-error", errorClass)}
			dangerouslySetInnerHTML={{
				__html: error["msg"] || errorMsg,
			}}
		/>
	);
};
