import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import cn from "classnames";
import { connect } from "react-redux";
import PersonalAreaNavbar from "../universal/Navbar/PersonalAreaNavbar.jsx";
import { Footer } from "../universal/Footer/Footer";
import { AsideToggleContext } from "./context/AsideToggleContext.js";
import DropDownSaveInfo from "../universal/DropDownSaveInfo/DropDownSaveInfo.jsx";
import Sidebar from "../universal/Sidebar/Sidebar.jsx";
import { lazy } from "react";
import { PrivatePersonalRoute } from "./PrivatePersonalRoute.js";
import "./styles/PersonalArea.scss";
// import "./styles/allStyles.scss";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";

const HomePage = lazy(() => import("./HomePage/HomePage.jsx"));
import StatisticPage from "./StatisticPage/StatisticPage.jsx";
const NotFoundPage = lazy(() => import("../Index/NotFoundPage.tsx"));

const ProductsPage = lazy(() => import("./ProductsPage/ProductsPage.jsx"));
const Product = lazy(() => import("../../features/products/components/EditProduct.tsx"));
const ServicesPage = lazy(() => import("./ServicesPage/ServicesPage.jsx"));
const BloggersPage = lazy(() => import("./BloggersPage/BloggersPage.jsx"));
const BloggerProfilePage = lazy(() => import("./BloggersPage/BloggerProfilePage"));

const PaymentsHistoryPage = lazy(() => import("./PaymentsHistoryPage/PaymentsHistoryPage.tsx"));
const ProfilePage = lazy(() => import("./ProfilePage/ProfilePage.jsx"));
const ChatPage = lazy(() => import("./ChatPage/ChatPage.tsx"));
import { useGetSeller } from "../../api/seller/index";

const PersonalArea = () => {
	const { toggle } = React.useContext(AsideToggleContext);
	const location = useLocation();
	const { seller } = useGetSeller();

	const [userState, setUserState] = useState({
		activity: false,
		areas: [4, 3],
		brightboxes: 2,
		categories: 2,
		id: 23,
		image: "/uploads/files/1667138767.jpg",
		login: "deniskapitonov",
		marketplace: "avito",
		name: "admin",
		surname: "admin",
		playlists: 2,
		politics: 2,
		prices: 2,
		sellers: 2,
		statistics: 2,
		users: 2,
		videos: 2,
		viewers: 2,
	});

	return (
		<>
			<div style={{ display: "none" }} id="commit-hash">
				{`COMMIT HASH: ${process.env.COMMIT_HASH}`}
			</div>
			{/* <div id='block-on-mobile'>
				<img src='images/logo.svg' alt='' id='mobile-logo' />
			</div> */}
			<DropDownSaveInfo />
			<Sidebar userState={userState} />

			<div
				className={cn(`personal-area-section animte-menu ${toggle ? "closed" : ""}`, {
					chat: location?.pathname?.includes("/chat"),
				})}
				style={{ overflowY: "auto" }}
			>
				<PersonalAreaNavbar userState={userState} />
				<div style={location?.pathname?.includes("/chat") ? {} : { minHeight: "100vh" }}>
					<Suspense
						fallback={
							<></>
							// <div className={`main-loader-wrapper active`}>
							// 	<Lottie
							// 		loop
							// 		animationData={newPreloaderJson}
							// 		play
							// 		style={{width: 50, height: 50}}
							// 	/>
							// </div>
						}
					>
						<Routes>
							<Route exact path="/" element={<HomePage title="Atips Market" />} />
							<Route exact path="*" element={<NotFoundPage title="Atips Market" />} />

							<Route
								// exact
								path="/products"
								element={<ProductsPage title="Atips Market" />}
							/>
							<Route
								// exact
								path="/products/:id"
								element={<Product title="Atips Market" />}
							/>
							<Route exact path="/product" element={<Product title="Atips Market" />} />

							<Route exact path="/service" element={<Product title="Atips Market" />} />

							<Route exact path="/services" element={<ServicesPage title="Atips Market" />} />
							<Route
								// exact
								path="/bloggers"
								element={<BloggersPage title="Atips Market" />}
							/>
							<Route
								// exact
								path="/blogger"
								element={<BloggerProfilePage title="Atips Market" />}
							/>
							<Route
								exact
								path="/payments-history"
								element={<PaymentsHistoryPage title="Atips Market" />}
							/>

							<Route
								exact
								path="/statistic"
								element={<PrivatePersonalRoute state={userState} keyVal="statistics" />}
							>
								<Route
									exact
									path="/statistic"
									element={
										<StatisticPage title="Atips Market" accessStatus={userState.statistics} />
									}
								/>
							</Route>

							<Route exact path="/profile" element={<ProfilePage title="Atips Market" />} />

							<Route
								// exact
								path="/chat"
								element={<ChatPage title="Atips Market" />}
							/>
						</Routes>
					</Suspense>
				</div>

				<Footer />
				{/* mainLoader > 0 */}
				{/* <div
					className={`main-loader-wrapper ${
						mainLoader > 0 ? "active" : "passive"
					}`}>
					<Lottie
						loop
						animationData={newPreloaderJson}
						play
						style={{width: 50, height: 50}}
					/>
				</div> */}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authUser,
		mainLoader: state.mainLoader,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalArea);
