import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {handleToggleOpenClick} from "../../../scripts/PersonalAreaScripts";

const SideCatregoryFilters = ({categoriesList}) => {
	const [categoriesState, setCategoriesState] = useState([]);

	useEffect(() => {
		setCategoriesState(categoriesList?.categories);
	}, [categoriesList]);

	return (
		<div className='statistics-filter-items-block'>
			<div
				className='statistics-filter-items-block-title'
				onClick={(e) => handleToggleOpenClick(e)}>
				Категория товаров
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z'
						fill='#212121'></path>
				</svg>
			</div>
			<div className='statistics-filter-items-block-subitems'>
				{categoriesState?.length > 0
					? categoriesState.map((categ) => (
							<div className='constructor-filter-items-block' key={categ.id}>
								<div
									className='constructor-filter-item-title'
									onClick={(e) => handleToggleOpenClick(e)}>
									<span>{categ.title}</span>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z'
											fill='#212121'></path>
									</svg>
								</div>
								{categ && !!categ.child && categ.child?.length > 0 ? (
									<div className='constructor-filter-subitems'>
										{categ.child.map((subCateg) => (
											<div
												className='constructor-filter-subitem'
												key={subCateg.id}>
												<div
													className='constructor-filter-subitem-title'
													onClick={(e) => handleToggleOpenClick(e)}>
													<span>{subCateg.title}</span>
													<svg
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z'
															fill='#212121'></path>
													</svg>
												</div>
												{subCateg &&
												!!subCateg.child &&
												subCateg.child?.length > 0 ? (
													<div className='constructor-filter-subitem-items'>
														<div className='flex-block flex-start'>
															<div className='flex-block-column'>
																{subCateg.child.map((subSubCateg) => (
																	<div
																		className='form-field'
																		key={subSubCateg.id}>
																		<div className='check-button-item'>
																			<span>{subSubCateg.title}</span>
																		</div>
																	</div>
																))}
															</div>
														</div>
													</div>
												) : null}
											</div>
										))}
									</div>
								) : null}
							</div>
					  ))
					: null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state?.loadingStatus,
		categoriesList: state?.categoriesList,
		mainLoader: state?.mainLoader,
	};
};

// const mapDispatchToProps = {
// 	getCategories,
// };

export default connect(mapStateToProps, null)(SideCatregoryFilters);
