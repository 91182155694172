import axios, { AxiosRequestConfig } from "axios";
import {
	ATIPS_SELLER_URL,
	ATIPS_ROOT_URL,
	ATIPS_IO,
	PARSE_URL,
	SELLER_API_KEY,
	getRefreshToken,
	getAccessToken,
	ATIPS_CREATORS_API_URL,
	CREATORS_API_KEY,
	ADVERTISING_API_URL,
} from "./constants";

export type useAxiosProps = AxiosRequestConfig & {
	data?: any;
	baseURLKey?: "seller" | "root" | "parse" | "atips_io" | "creators" | "chat"; // DEFAULT - "root"
	url: string;
};

export const sellerAxiosInstance = axios.create();

sellerAxiosInstance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config;
		const errMsg = err?.response?.data?.detail || "";

		if (err.response) {
			// Access Token was expired or Signature has expired
			if (
				errMsg?.includes("Unauthorized") ||
				errMsg?.includes("Signature has expired") ||
				errMsg?.includes("Token decode error") ||
				errMsg?.includes("authenticated")
			) {
				originalConfig._retry = true;

				try {
					const rs = await axios.post(`${ATIPS_SELLER_URL}/auth/jwt/refresh`, {
						refresh_token: getRefreshToken(),
					});
					const access_token = rs?.data?.["access_token"];
					localStorage?.setItem("token", access_token);

					return sellerAxiosInstance(originalConfig);
				} catch (_error) {
					localStorage?.clear();
					window["location"]["href"] = "/";
					// const path = window?.location?.href;
					// if (window && window?.location && path?.includes("profile")) {
					// 	// const win = window.open(product?.URL_SOURCE, "_blank");
					// 	window["location"]["href"] = window?.location?.origin;
					// }
					return Promise.reject(_error);
				}
			}
		}
		return Promise.reject(err);
	},
);

sellerAxiosInstance.interceptors.request.use(
	(config) => {
		const token: string = getAccessToken();
		const url = config?.url;
		// if (
		// !url.includes(ATIPS_IO)
		// && !url.includes(process.env.REACT_APP_ATIPS_DASHBOARD_URL)
		// ) {
		// if (token) {
		// 	config.headers!["Authorization"] = `Bearer ${getAccessToken()}`;
		// }
		config.headers!["Authorization"] = `Bearer ${getAccessToken()}`;

		if (url.includes(ATIPS_CREATORS_API_URL)) {
			config.headers!["x-content-creators-api-key"] = CREATORS_API_KEY;
		}
		if (url.includes(ATIPS_SELLER_URL)) {
			config.headers!["x-advertisers-api-key"] = SELLER_API_KEY;
		}
		// }

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export const useAxios = ({
	data = {},
	baseURLKey,
	url,
	method = "get",
}: useAxiosProps) => {
	const BASE_URL = {
		seller: ATIPS_SELLER_URL,
		creators: ATIPS_CREATORS_API_URL,
		root: ATIPS_ROOT_URL,
		parse: PARSE_URL,
		atips_io: ATIPS_IO,
		chat: ADVERTISING_API_URL,
	}[baseURLKey || "root"];

	return sellerAxiosInstance[method](
		`${BASE_URL}${url}`,
		method === "get" ? {} : data,
	);
};
