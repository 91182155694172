import React, { FC } from "react";
import InputMask from "react-input-mask";
import { InputProps } from "./Input";
import { Controller } from "react-hook-form";

export const InputWithMask: FC<InputProps> = (props: any) => {
	return (
		<Controller
			control={props.inputActions.control}
			name={props?.name}
			render={({ field: { value = "" } }) => {
				return (
					<InputMask
						maskChar={""}
						{...props.inputActions?.register(props?.name, {
							...props,
						})}
						value={value === null ? "" : value}
						mask={props?.mask}
						placeholder={props.placeholder}
						maskplaceholder={props.maskPlaceholder}
					/>
				);
			}}
		/>
	);
};
