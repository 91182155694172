const initialState = {
	loading: 0,
};
const LoadingReducer = (state = initialState, action) => {
	switch (action.type) {
		case "HIDE_LOADER":
			return { ...state, loading: 0 };
		case "SHOW_LOADER":
			return { ...state, loading: 1 };
		case "HIDE_ALL":
			return { ...state, loading: 2 };
		default:
			return state;
	}
};


const MainLoadingReducer = (state = 0, action) => {
	switch (action.type) {
		case "SHOW_MAIN_LOADER":
			return state++;
		case "HIDE_MAIN_LOADER":
			return state--;
		default:
			return state;
	}
};

// const MainLoadingReducer = (state = false, action) => {
// 	switch (action.type) {
// 		case "SHOW_MAIN_LOADER":
// 			return true;
// 		case "HIDE_MAIN_LOADER":
// 			return false;
// 		default:
// 			return state;
// 	}
// };

const StatisticLoaderReducer = (state = false, action) => {
	switch (action.type) {
		case "SHOW_STATISTIC_LOADER":
			return true;
		case "HIDE_STATISTIC_LOADER":
			return false;
		default:
			return state;
	}
};

const SaveReducer = (state = false, action) => {
	switch (action.type) {
		case "SHOW_SAVE_INFO":
			return true;
		case "HIDE_SAVE_INFO":
			return false;
		default:
			return state;
	}
};

export { LoadingReducer, SaveReducer, MainLoadingReducer, StatisticLoaderReducer };
