import {useBaseMutation} from "../../Actions/shared";
import {getSellerId} from "../constants";
import {User} from "../types";

export const useUpdateSeller = (props: any) => {
	const {mutation} = useBaseMutation<User, unknown, any>({
		baseURLKey: "root",
		url: `/domain/sellers/${getSellerId()}`,
		method: "put",
		...props,
	});

	const updateSeller = (data: User) => {
		mutation.mutate(data);
	};

	return {updateSeller};
};
