export const PRIVACY_POLICY = `
<p>
Дата публикации: «__» октября 2023  г.
</p>
<p>
Настоящая политика  конфиденциальности (далее – «Политика») определяет
порядок Обработки  персональных данных и меры по обеспечению их
безопасности, предпринимаемые  Оператором.
</p>
<p>
Соглашаясь с настоящей  Политикой, Рекламодатель подтверждает, что:
</p>
<p>
−          является дееспособным лицом;
</p>
<p>
−          ознакомился с Политикой и выражает свое  безусловное согласие с
ней и принимает на себя указанные в ней права и  обязанности;
</p>
<p>
−          предоставляет достоверные и достаточные  Персональные данные,
поддерживает такие данные в актуальном состоянии;
</p>
<p>
−          обладает всеми необходимыми правами для  регистрации и
использования Сайта.
</p>
<p align="center">
<strong>1.</strong>
<strong>
	Термины и определения,  использующиеся в Политике.
</strong>
</p>
<p>
<strong>Персональные данные</strong>- любая информация, относящаяся к  прямо
или косвенно определенному или определяемому субъекту персональных данных  —
Рекламодателю.
</p>
<p>
<strong>Оператор</strong>- общество с ограниченной ответственностью
«ЭйТипс», ОГРН  1227700295943, адрес: 109382,  г. Москва, ул Нижние Поля, д.
31, стр. 1, фактический адрес: 109382, г. Москва, ул. Нижние  Поля, д. 31,
стр. 1, самостоятельно или  совместно с другими лицами организующее и/или
осуществляющее  Обработку персональных данных, а также определяющее цели
Обработки  персональных данных, состав Персональных данных, подлежащих
обработке, действия  (операции), совершаемые с Персональными данными.
</p>
<p>
<strong>Обработка персональных данных</strong> – это любое действие
(операция) или  совокупность действий (операций) с Персональными данными,
совершаемых с  использованием средств автоматизации или без использования
таких средств с  Персональными данными, включая сбор, запись,
систематизацию, накопление,  хранение, уточнение (обновление, изменение),
извлечение, использование,  передачу (распространение, предоставление,
доступ), обезличивание,  блокирование, удаление, уничтожение Персональных
данных.
</p>
<p>
<strong>Автоматизированная Обработка персональных данных</strong> –
Обработка персональных данных с  помощью средств вычислительной техники.
</p>
<p>
<strong>Блокирование персональных данных</strong> – временное прекращение
Обработки  персональных данных, за исключением случаев, если обработка
необходима для  уточнения Персональных данных.
</p>
<p>
<strong>Обезличивание персональных данных</strong> – действия, в результате
которых  становится невозможным без использования дополнительной информации
определить  принадлежность Персональных данных Рекламодателю.
</p>
<p>
<strong>Предоставление персональных данных</strong> – действия, направленные
на  раскрытие персональных данных определенному лицу или определенному кругу
лиц.
</p>
<p>
<strong>Распространение персональных данных</strong> – действия,
направленные на  раскрытие персональных данных неопределенному кругу лиц.
</p>
<p>
<strong>Рекламодатель</strong> — физическое лицо, зарегистрированное в
качестве индивидуального  предпринимателя либо применяющее специальный
налоговый режим «Налог на  профессиональный доход», или юридическое лицо,
которые прошли регистрацию и  использует Сайт в целях продвижения своих
товаров и/или услуг.
</p>
<p>
<strong>Сайт</strong> — <a href="https://market.atips.io" target="_blank">https://market.atips.io</a>.
</p>
<p>
<strong>Cookies</strong>— файл с настройками и  другой информацией, который
сохраняется на компьютерах Рекламодателей.
</p>
<p>
<strong>Сетевой адрес (IP-адрес)</strong> - идентификатор в сети  передачи
данных, определяющий при оказании телекоммуникационных услуг связи
абонентский терминал или иные средства связи, входящие в информационную
систему.
</p>
<p>
<strong>Уничтожение персональных данных</strong> – действия, в  результате
которых становится невозможным восстановить содержание Персональных  данных
в ИСПДн и (или) в результате которых уничтожаются материальные носители
Персональных данных.
</p>
<p>
<strong>ИСПДн</strong> – Информационная система персональных данных.
Совокупность содержащихся в базах данных Персональных данных и
обеспечивающих  их обработку информационных технологий и технических
средств.
</p>
<p>
<strong>Трансграничная передача Персональных данных</strong> — передача
Персональных данных на территорию иностранного государства органу власти
иностранного государства, иностранному физическому или иностранному
юридическому лицу.
</p>
<p>
<strong>Закон</strong> - Федеральный закон от  27.07.2006 г. № 152-ФЗ «О
персональных данных»
</p>
<p align="center">
<strong>2.</strong> <strong>Область действия  Политики</strong>
</p>
<p>
Политика действует в  отношении Персональных данных, которые Оператор может
получить от Рекламодателя  в процессе использования Сайта и исполнения
соглашений или договоров с  Рекламодателем.
</p>
<p>
Политика применяется в отношении  Персональных данных:
</p>
<p>
- сотрудников Рекламодателя;
</p>
<p>
- непосредственного Рекламодателя –  физического лица.
</p>
<p>
<strong>Перечень Персональных данных, обрабатываемых  Оператором</strong>
</p>
<p>
Для доступа к Сайту и  заключения соглашения или договора, Рекламодатель
предоставляет следующие  Персональные данные:
</p>
<p>
<a name="bookmark=id.gjdgxs"></a>- ФИО или ФИО представителя;
</p>
<p>
- контактный номер телефона;
</p>
<p>
- адрес электронной почты;
</p>
<p>
- ИНН;
</p>
<p>
- ОГРН ИП;
</p>
<p>
- адрес регистрации (для  индивидуальных предпринимателей);
</p>
<p>
- банковские реквизиты.
</p>
<p>
Рекламодатель  предоставляет Оператору конкретное, информированное,
сознательное и однозначное  согласие на Обработку своих Персональных данных
в следующем порядке:
</p>
<p>
−          для Персональных данных, указанных  Рекламодателем при
регистрации на Сайте, – путем заполнения формы регистрации,  проставлении
галочки в поле «Соглашаюсь на обработку моих персональных данных в
соответствии с политикой конфиденциальности и принимаю условия соглашения»
(или  ином аналогичном поле) и нажатии кнопки «Зарегистрироваться»;
</p>
<p>
−          для Персональных данных, которые были  отредактированы
Рекламодателем в личном кабинете Сайта, - путем внесения  обновленных данных
в профиле Рекламодателя и нажатия кнопки «Сохранить  изменения» (или иной
аналогичной кнопки).
</p>
<p>
Предоставляя Оператору согласие на  Обработку персональных данных одним из
способов, указанных  выше, Рекламодатель подтверждает, что он действует
свободно, своей волей и в  своих интересах.
</p>
<p>
<a name="bookmark=id.2et92p0"></a>В случаях, если при регистрации на
Сайте/редактировании информации в личном кабинете Сайта были указаны
Персональные данные сотрудников Рекламодателя, последний подтверждает и
гарантирует, что получил от таких сотрудников все необходимые согласия на
Обработку их персональных данных, в том числе, в указанных целях.
</p>
<p>
Оператор не обязан  запрашивать согласие непосредственно у сотрудника
Рекламодателя, но вправе  запросить такое согласие у Блогера.
</p>
<p>
Рекламодатель  обязуется предоставить согласие на Обработку персональных
данных своих  сотрудников в сроки, обозначенные Оператором.
</p>
<p>
Оператор полагается на достоверность  предоставленных Рекламодателем
Персональных данных. <a name="bookmark=id.30j0zll"></a>
</p>
<p>
<a name="bookmark=id.1fob9te"></a>При использовании Рекламодателем Сайта
Оператор анализирует следующие  данные:
</p>
<p>
−          HTTP-заголовок,  Сетевой адрес, файлы cookies, информацию об
аппаратном и программном  обеспечении устройства Рекламодателя;
</p>
<p>
−          информацию об активности Рекламодателя во время  использования
Сайта (данные о посещенных страницах).
</p>
<p>
Оператор не хранит в  базах данных, связанных с персональными данными
Рекламодателей, анализируемую  информацию.
</p>
<p>
Оператор не собирает  специальные категории Персональных данных (расовое
происхождение, политические взгляды, информация о  здоровье и биометрические
данные) и настоятельно просит Рекламодателей не  предоставлять подобные
данные на Сайте либо сотрудникам Оператора.
</p>
<p>
При предоставлении  подобных данных Оператор не несет ответственности за их
обработку, хранение и  передачу третьим лицам.
<a name="bookmark=id.3znysh7">
</a>
</p>
<p>
Трансграничная передача  персональных данных Оператором не осуществляется.
</p>
<p align="center">
<a name="_heading=h.2et92p0"></a><strong>3.</strong>
<strong>
	Цели Обработки  персональных данных
</strong>
</p>
<p>
Оператор Обрабатывает  персональные данные Рекламодателя в целях:
</p>
<p>
−          регистрации Рекламодателя на Сайте;
</p>
<p>
−          идентификации Рекламодателя на Сайте;
</p>
<p>
−          авторизации Рекламодателя на Сайте;
</p>
<p>
−          заключения с Рекламодателя пользовательского соглашения  или
договора;
</p>
<p>
−          исполнения пользовательского соглашения или  договора с
Рекламодателя;
</p>
<p>
−          предоставления информации Рекламодателя о Сайте  и услугах
Оператора;
</p>
<p>
−          для защиты прав Рекламодателя и прав Оператора;
</p>
<p>
−          для организации финансовых расчетов с Рекламодателем;
</p>
<p>
−          для совершенствования и улучшения Сайта;
</p>
<p>
−          для выявления угроз безопасности Сайта.
</p>
<p align="center">
<strong>4.</strong>
<strong>
	Правовые основания обработки персональных данных
</strong>
</p>
<p>
Персональные данные  Рекламодателей, его сотрудников, обрабатываются
Оператором на основании:
</p>
<p>
−          Конституции Российской Федерации;
</p>
<p>
−          Гражданского кодекса Российской Федерации;
</p>
<p>
−          Устава ООО «ЭйТипс»;
</p>
<p>
−          Пользовательских соглашений или договоров с Рекламодателем;
</p>
<p>
−          Согласия на обработку персональных данных (пп.1  ч.1 ст. 6
Закона).
</p>
<p align="center">
<strong>5.</strong>
<strong>
	Порядок, условия и способы Обработки персональных данных
</strong>
</p>
<p>
Отказ Рекламодателя от  предоставления согласия на Обработку его
персональных данных влечет за собой  невозможность достижения целей
Обработки персональных данных (п.3 Политики) за  исключением случаев,
установленных действующим законодательством Российской  Федерации.
</p>
<p>
Оператор осуществляет  Обработку персональных данных Рекламодателей
автоматизированным и  неавтоматизированным способом.
</p>
<p>
Персональные данные  Рекламодателей обрабатываются и хранятся на виртуальных
вычислительных  серверах, арендуемых у ООО «Яндекс.Облако».
</p>
<p>
Оператор может  передавать Персональные данные органам дознания и следствия,
иным  уполномоченным органам по основаниям, предусмотренным действующим
законодательством Российской Федерации.
</p>
<p>
Обработка Персональных  данных производится на основе соблюдения принципов:
</p>
<p>
−          законности целей и способов Обработки  персональных данных;
</p>
<p>
−          соответствия целей Обработки персональных  данных целям, заранее
определенным и заявленным при сборе Персональных данных;
</p>
<p>
−          соответствия объема и характера обрабатываемых  Персональных
данных, способов Обработки персональных данных заявленным целям  Обработки
персональных данных;
</p>
<p>
−          достоверности Персональных данных, их  достаточности для целей
обработки, недопустимости Обработки персональных  данных, избыточных по
отношению к целям, заявленным при сборе Персональных  данных;
</p>
<p>
−          недопустимости объединения созданных для  несовместимых между
собой целей баз данных, содержащих Персональные данные;
</p>
<p>
−          хранения Персональных данных в форме,  позволяющей определить
Рекламодателя, не дольше, чем этого требуют цели их  обработки;
</p>
<p>
−          уничтожения по достижении целей Обработки  персональных данных
или в случае утраты необходимости в их достижении.
</p>
<p align="center">
<strong>6.</strong>
<strong>
	Сроки Обработки персональных данных и их уничтожение
</strong>
</p>
<p>
Обработка персональных  данных осуществляется Оператором:
</p>
<p>
−          До момента достижения целей (п.3 Политики);
</p>
<p>
−          До момента удаления Рекламодателем учетной  записи на Сайте;
</p>
<p>
−          До момента отзыва Рекламодателем (его  сотрудником) согласия на
Обработку персональных данных;
</p>
<p>
−          До момента окончания срока действия согласия на  Обработку
персональных данных.
</p>
<p>
По достижению сроков  Обработки персональных данных такие данные подлежат
уничтожению, если иное не  предусмотрено Законом.
</p>
<p align="center">
<strong>7.</strong> <strong>Права и обязанности</strong>
</p>
<p>
<strong><em>Оператор персональных  данных вправе:</em></strong>
</p>
<p>
−          получать от Рекламодателя достоверные  Персональные данные,
необходимые для достижения целей Обработки персональных  данных;
</p>
<p>
−          предоставлять Персональные данные Рекламодателя  третьим лицам,
если это предусмотрено действующим законодательством Российской  Федерации и
Политикой;
</p>
<p>
−          отказывать в предоставлении Персональных данных  в
предусмотренных законодательством Российской Федерации случаях;
</p>
<p>
−          осуществлять Обработку персональных данных без  согласия
Рекламодателя при наличии оснований, указанных в Законе;
</p>
<p>
−          самостоятельно определять состав  и перечень мер, необходимых и
достаточных для обеспечения выполнения  обязанностей, предусмотренных
действующим законодательство Российской  Федерации и Политикой.
</p>
<p>
<strong><em>Оператор персональных  данных обязуется:</em></strong>
</p>
<p>
−          предоставлять Рекламодателю или его законному  представителю по
их просьбе информацию, касающуюся Обработки персональных данных
Рекламодателя, а также предоставить  возможность ознакомления с такими
Персональными данными;
</p>
<p>
−          отвечать на обращения и запросы Рекламодателя  и его законных
представителей в соответствии с требованиями  Закона;
</p>
<p>
−          прекратить Обработку персональных данных в  случаях, указанных в
Политике и Законе;
</p>
<p>
−          внести необходимые изменения, уничтожить или  блокировать
Персональные данные в случае предоставления Рекламодателя неполных,
устаревших, недостоверных или незаконно полученных Персональных данных, а
также  уведомить о своих действиях Рекламодателя;
</p>
<p>
−          выполнять требования законодательства Российской  Федерации.
</p>
<p>
<strong><em>Рекламодатель имеет  право:</em></strong>
</p>
<p>
−          получать информацию, касающуюся обработки его  Персональных
данных, за исключением случаев, предусмотренных федеральными  законами, в
том числе:
</p>


<ul>
<li>о подтверждении факта Обработки персональных  данных Оператором;</li>
<li>о правовых основания и целях Обработки  персональных данных;</li>
<li>о целях  и применяемых Оператором способах Обработки персональных
данных;</li>
<li>о наименование и месте нахождения Оператора,  сведениях о лицах
(за исключением работников оператора), которые имеют доступ к  Персональным
данным или которым могут быть раскрыты Персональные данные на  основании
договора с Оператором или на основании Закона;</li>
<li>об обрабатываемых Персональных данных,  относящиеся к
Рекламодателю, источнике их получения, если иной порядок  представления
таких данных не предусмотрен Законом;</li>
<li>о сроках Обработки персональных данных, в том  числе сроках их
хранения;</li>
<li>о порядке осуществления Рекламодателем прав, предусмотренных
Законом;</li>
<li>информацию об осуществленной или о  предполагаемой Трансграничной
передаче данных;</li>
<li>о наименовании или фамилии, имени, отчестве и  адресе лица,
осуществляющего Обработку персональных данных по поручению  Оператора, если
обработка поручена или будет поручена такому лицу;</li>
<li>информацию о способах исполнения Оператором  обязанностей,
установленных статьей 18.1 Закона;</li>
<li>иных сведениях, предусмотренных Законом или  другими федеральными
законами;</li>
</ul>






<p>
−          требовать от Оператора уточнения его  Персональных данных, их
блокирования или уничтожения в случае, если Персональные  данные являются
неполными, устаревшими, неточными, незаконно полученными или  не являются
необходимыми для заявленной цели обработки, а также  принимать
предусмотренные законом меры по защите своих прав;
</p>
<p>
−          обжаловать в уполномоченный орган по защите прав  субъектов
персональных данных или в судебном порядке неправомерные действия или
бездействия при обработке его Персональных данных;
</p>
<p>
−          на осуществление иных прав,  предусмотренных законодательством
Российской Федерации.
</p>
<p>
<strong><em>Рекламодатель обязан:</em></strong>
</p>
<p>
−          передавать достоверные Персональные данные;
</p>
<p>
−          выполнять требования законодательства Российской  Федерации.
</p>
<p align="center">
<strong>8.</strong>
<strong>
	Актуализация и исправление Персональных данных, ответы на  запросы
	Рекламодателя на доступ к Персональным данным
</strong>
</p>
<p>
Рекламодатель имеет право на  получение сведений, указанных в
<a
	href="https://www.consultant.ru/document/cons_doc_LAW_61801/a15bab6050028753706f22c32fe60627a7be79f9/" target="_blank"
>
	части 7</a> ст.14 Закона, за исключением случаев,  предусмотренных
<a
	href="https://www.consultant.ru/document/cons_doc_LAW_61801/cd0d49f816bca2fb01994515ed3223a96dd38629/" target="_blank"
>частью 8</a> ст. 14 Закона (далее – «Сведения»).
</p>
<p>
Сведения  предоставляются Рекламодателю в доступной форме и в них не
включаются персональные данные, относящиеся к  другим субъектам персональных
данных за исключением случаев, когда имеются  законные основания для
раскрытия таких персональных данных.
</p>
<p>
Сведения предоставляются Рекламодателю или его представителю в течение 10
рабочих дней с момента обращения либо  получения запроса от Рекламодателя
или его представителя.
</p>
<p>
Данный срок может быть  продлен Оператором, но не более чем на пять рабочих
дней.
</p>
<p>
В случае продления  срока, Оператор направляет Рекламодателю мотивированное
уведомление с указанием причин продления срока предоставления Сведений.
</p>
<p>
Запрос должен содержать:
</p>
<p>
−          номер основного документа, удостоверяющего  личность субъекта
персональных данных или его представителя;
</p>
<p>
−          сведения о дате выдачи указанного документа и  выдавшем его
органе;
</p>
<p>
−          сведения, подтверждающие участие субъекта  персональных данных в
отношениях с Оператором (номер договора, дата заключения  договора, условное
словесное обозначение и (или) иные сведения), либо сведения,  иным образом
подтверждающие факт обработки персональных данных Оператором;
</p>
<p>
−          подпись субъекта персональных данных или его  представителя.
</p>
<p>
Если Рекламодателю были предоставлены  полностью запрошенные Сведения по его
первоначальному запросу, Рекламодатель вправе обратиться к  Оператору с
повторным запросом не ранее чем через 30 (тридцать) дней.
</p>
<p>
Если Сведения были  предоставлены Рекламодателю в не полном объеме по
первоначальному  запросу, Рекламодатель вправе подать повторный запрос с
обоснованием  его повторного направления.
</p>
<p>
Оператор вправе  отказать Рекламодателю по предоставлению Сведений по
повторному  запросу, если такой запрос не соответствует вышеописанному.
</p>
<p>
Оператор вправе  отказать Рекламодателю в предоставлении Сведений. Отказ
должен быть  мотивированный. Отказ в предоставлении Сведений направляется
Рекламодателю в срок не превышающий  10 (десяти) дней с момента получения
запроса Рекламодателю.
</p>
<p>
Вышеуказанный срок  может быть продлен Оператором на срок не более 5 (пяти)
дней с обоснованием  причин.
</p>
<p>
Оператор рассматривает  все запросы Рекламодателя в порядке, указанном выше.
</p>
<p>
Оператор вносит изменения в  Персональные данные Рекламодателя в течение 7
(семи) дней с момента обращения Рекламодателя  с обращением о неполных,
неточных или неактуальных Персональных данных.
</p>
<p>
Оператор уничтожает незаконно  полученные Персональные данные или данные,
которые не является необходимыми для  целей обработки, в течение 7 (семи)
дней с момента обращения Рекламодателя с соответствующим обращением.
</p>
<p>
Оператор блокирует Персональные  данные, обработка которых является
неправомерной, либо неточные Персональные  данные на период проверки.
</p>
<p>
Блокировка Персональных данных  производится на основании запроса
Рекламодателя  либо уполномоченного органа по защите прав субъектов
персональных данных.
</p>
<p>
Оператор прекращает неправомерную  Обработку персональных данных в течение 3
(трех) рабочих дней с момента факта  ее выявления.
</p>
<p>
Если обеспечить правомерность  Обработки персональных данных невозможно,
Оператор в срок, не превышающий 10  (десяти) рабочих дней с момента
выявления неправомерной Обработки персональных  данных, уничтожает такие
Персональные данные или обеспечивает их уничтожение.
</p>
<p>
Оператор уточняет Персональные  данные на основании обращения Рекламодателя
в  течение 7 (семи) рабочих дней с момента подтверждения факта неточных
Персональных данных, затем снимает блокировку с таких Персональных данных.
</p>
<p>
Если Закон содержит иные сроки и порядок  рассмотрения обращений и запросов
Рекламодателя,  чем указанные в Политики, применяются порядок и сроки,
указанные в Законе.
</p>
<p>
Обращения, запросы могут  направляться Рекламодателем в адрес Оператора  по
адресу: 109382, г.  Москва, ул Нижние Поля, д. 31, стр. 1, или в форме
электронного  документа, по адресу электронной почты:
<a href="mailto:support@atips.ru">support@atips.ru</a>, с указанием в теме письма: «обращение/запрос по  персональным данным».
</p>
<p align="center">
<strong>9.</strong> <strong>Защита Персональных  данных</strong>
</p>
<p>
Оператор организует  Обработку персональных данных с соблюдением условий
конфиденциальности и  безопасности.
</p>
<p>
Обеспечение безопасности  Обработки персональных данных достигается в том
числе путем  разработки и своевременной актуализации
организационно-распорядительной  документации, технических мер защиты,
соответствующих условиям Обработки  персональных данных.
</p>
<p>
Оператором разработаны и  внедрены необходимые организационные и технические
меры для обеспечения  безопасности Персональных данных от
несанкционированного доступа, уничтожения,  изменения и других
несанкционированных действий.
</p>
<p>
К таким мерам относятся:
</p>
<p>
−          назначение лица, ответственного за организацию  Обработки
персональных данных;
</p>
<p>
−          утверждение и своевременная актуализация  документов,
определяющих политику в отношении Обработки персональных данных,
организационных и технических мер, направленных на предотвращение и
выявление  нарушений законодательства;
</p>
<p>
−          разработка модель угроз безопасности  Персональных данных для
ИСПДн;
</p>
<p>
−          подписание сотрудниками Оператора, допущенные к  Обработке
персональных данных, соглашений о неразглашении;
</p>
<p>
−          разработка и внедрение настройки безопасности  для системного и
прикладного программного обеспечения ИСПДн;
</p>
<p>
−          выбор, внедрение и настройка средств защиты  информации ИСПДн;
</p>
<p>
−          проведение внутреннего аудита на соответствие  Обработки
персональных данных законодательству Российской Федерации;
</p>
<p>
−          проведение периодической оценки эффективности  принимаемых мер по
обеспечению безопасности Персональных данных;
</p>
<p>
−          проведение периодической оценки вреда, который  может быть
причинен Рекламодателю.
</p>
<p align="center">
<strong>10.</strong>
<strong>
	Передача Персональных  данных третьим лицам
</strong>
</p>
<p>
Оператор может  передавать Персональные данные третьим лицам, к которым
относятся:
</p>
<p>
−          провайдер облачных услуг, участвующий в процессе  хранения,
передачи и Обработки персональных данных;
</p>
<p>
−          государственные органы, правоохранительные  органы и судебные
органы Российской Федерации.
</p>
<p>
Регистрируясь на Сайте,  принимая условия пользовательского соглашения и
Политики, Рекламодатель дает  Оператору согласие на предоставление
Персональных данных вышеуказанным третьим  лицам.
</p>
<p>
Оператор информирует  Рекламодателя, что в случае отзыва согласия на
предоставление Персональных  данных вышеуказанным третьим лицам, Оператор не
сможет оказывать услуги  Рекламодателю и доступ к Сайту может быть ограничен
Оператором.
</p>
<p align="center">
<strong>11.</strong> <strong>Использование</strong>
<strong>
	файлов Cookies
</strong>
</p>
<p>
Оператор использует  следующие типы файлов Cookies:
</p>
<p>
−          технические файлы Cookies: необходимы для работы  Сайта, они
позволяют идентифицировать аппаратное и программное обеспечение
Рекламодателя, в том числе тип и версию используемого браузера;
</p>
<p>
−          статистические/аналитические файлы Cookie:  позволяют
распознавать Рекламодателей, подсчитывать их количество и собирать
информацию о посещенных веб-страницах;
</p>
<p>
−          функциональные файлы Cookie: позволяют  предоставлять
определенные функции, чтобы облегчить использование сайта для
Рекламодателя, например, сохраняют языковые и региональные предпочтения;
</p>
<p>
−          сторонние Cookies: собирают информацию о  Рекламодателях,
источниках трафика, посещенных страницах и рекламе.
</p>
<p>
Файлы Cookies используются для  запоминания предпочтений Рекламодателя на
Сайте.
</p>
<p>
В случае отказа Рекламодателя от использования файлов Cookies, часть
функций Сайта может быть недоступна.
</p>
<p>
Оператор не требует обязательного  согласия на установку файлов Cookies на
устройство, Рекламодатель  вправе отключить эту опцию в настройках браузера.
</p>
<p>
Оператор вправе передавать  информацию из файлов Cookies третьим лицам для
целей, указанных в Политике.
</p>
<p align="center">
<strong>12.</strong> <strong>Изменение Политики</strong>
</p>
<p>
Оператор оставляет за собой право в  одностороннем порядке вносить изменения
в Политику, в том числе, по причине  изменения законодательства Российской
Федерации и при необходимости приведения  Политики в соответствие с такими
изменениями.
</p>
<p>
Актуальная версия политики  размещается на
<a href="https://atips.media/" target="_blank">Сайте</a>.
</p>
<p>
Продолжая использовать Сайт после  внесения изменений в <a href="https://market.atips.io/privacy" target="_blank">Политику</a>,
<a href="https://market.atips.io/legal" target="_blank">Рекламодатель соглашается с условиями</a> обновленной Политики в полном объеме.
</p>`;

export const PERSONAL_POLICY = `
<ol>
	<li><h1 class="western" align="left"><b>1. Общие</b><b>
	</b><b>положения</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		1.1. Настоящая Политика
		в области обработки и обеспечения
		безопасности персональных данных
		(далее – «Политика») является основой
		для организации работы по обработке
		и обеспечению безопасности персональных
		данных в ООО «ЭйТипс» (далее –
		«Компания»). 
		</p>
	</ol>
</ol>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
Положения настоящей
Политики распространяются на всех
работников Компании, имеющих доступ к
персональным данным.</p>
<ol>
	<ol start="2">
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		1.2. Настоящая Политика,
		разработанная в соответствии с
		Федеральным <a href="https://www.consultant.ru/document/cons_doc_LAW_61801/" target="_blank">законом</a>
		«О персональных данных», устанавливает
		цели, основные принципы и правила
		обработки персональных данных и
		определяет основные меры по обеспечению
		их безопасности 
		</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		1.3. Настоящая Политика
		является общедоступной и подлежит
		размещению на сайте Компании. 
		</p>
	</ol>
</ol>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
Компания оставляет
за собой право в любое время обновлять
и изменять Политику без какого-либо
согласования с какими-либо третьими
лицами.</p>
<ol>
	<ol start="4">
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		1.4. С целью поддержания
		деловой репутации Компания считает
		важнейшей задачей обеспечение
		легитимности обработки и безопасности
		персональных данных субъектов в
		бизнес-процессах Компании.</p>
	</ol>
</ol>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
Для решения данной
задачи в Компании введена, функционирует
и проходит периодический пересмотр
(контроль) системы защиты персональных
данных.</p>
<ol>
	<ol start="5">
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		1.5. Компания обеспечивает
		принятие мер, необходимых и достаточных
		для обеспечения выполнения обязанностей,
		предусмотренных Федеральным законом
		от 27.07.2006 № 152-ФЗ «О персональных данных»
		и принятыми в соответствии с ним
		нормативными правовыми актами.</p>
	</ol>
	<li style="margin-top: 0.41cm;"><h1 class="western" align="left"><b>2. Основные
	понятия, используемые в политике</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.1. обработка
		персональных данных&nbsp;-
		любое действие (операция) или совокупность
		действий (операций) с персональными
		данными, совершаемых с использованием
		средств автоматизации или без
		использования таких средств с
		персональными данными, включая сбор,
		запись, систематизацию, накопление,
		хранение, уточнение (обновление,
		изменение), извлечение, использование,
		передачу (распространение, предоставление,
		доступ), обезличивание, блокирование,
		удаление, уничтожение персональных
		данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.2. оператор&nbsp;-
		государственный орган, муниципальный
		орган, юридическое или физическое
		лицо, самостоятельно или совместно с
		другими лицами организующие и (или)
		осуществляющие обработку персональных
		данных, а также определяющие цели
		обработки персональных данных, состав
		персональных данных, подлежащих
		обработке, действия (операции),
		совершаемые с персональными данными.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.3. персональные
		данные - любая
		информация, относящаяся к прямо или
		косвенно определенному или определяемому
		физическому лицу (субъекту персональных
		данных); 
		</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.4. информационная
		система -
		совокупность содержащейся в базах
		данных информации (персональных данных)
		и обеспечивающих ее обработку
		информационных технологий и технических
		средств 
		</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.5. защита персональных
		данных -
		деятельность, направленная на
		предотвращение утечки защищаемых
		персональных данных, несанкционированных
		и непреднамеренных воздействий на
		защищаемые персональные данные 
		</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.6. безопасность
		персональных данных -
		состояние защищенности персональных
		данных, характеризуемое способностью
		пользователей, технических средств и
		информационных технологий обеспечить
		конфиденциальность, целостность и
		доступность персональных данных при
		их обработке в информационных системах;
		
		</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.7. субъект персональных
		данных&nbsp;–
		определенное или определяемое физическое
		лицо, к которому прямо или косвенно
		относятся персональные данные, в том
		числе, пользователи услуг Компании,
		работники Компании и их близкие
		родственники, кандидаты для приема на
		работу (соискатели), контрагенты –
		физические лица и индивидуальные
		предприниматели, представители
		контрагентов, а также иные лица, с
		которыми Компания входит в правоотношения
		и получает возможность обработки
		персональных данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.8. трансграничная
		передача персональных данных -&nbsp;передача
		персональных данных на территорию
		иностранного государства органу власти
		иностранного государства, иностранному
		физическому лицу или иностранному
		юридическому лицу.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		2.9. конфиденциальность
		персональных данных&nbsp;-
		обязательное для выполнения лицом,
		получившим доступ к персональным
		данным, требование не раскрывать
		третьим лицам и не распространять
		персональные данные без согласия
		субъекта персональных данных, если
		иное не предусмотрено федеральным
		законом.</p>
	</ol>
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>3. Принципы
	обработки персональных данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		3.1. Обработка персональных
		данных в Компании осуществляется в
		соответствии с требованиями действующего
		законодательства и следующими основными
		принципами:</p>
		<ol>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.1. законности целей
			и способов обработки персональных
			данных и добросовестности;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.2. соответствия целей
			обработки персональных данных целям,
			заранее определенным и заявленным
			при сборе персональных данных, а также
			полномочиям Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.3. соответствия
			объема и характера обрабатываемых
			персональных данных, способов обработки
			персональных данных целям обработки
			персональных данных;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.4. достоверности,
			точности персональных данных, их
			актуальности и достаточности для
			целей обработки, недопустимости
			обработки избыточных по отношению к
			целям сбора персональных данных;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.5. легитимности
			организационных и технических мер по
			обеспечению безопасности персональных
			данных;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.6. непрерывности
			повышения уровня знаний работников
			Компании в сфере обеспечения безопасности
			персональных данных при их обработке;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.7. стремления к
			постоянному совершенствованию системы
			защиты персональных данных;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			3.1.8. уничтожения либо
			обезличивания персональных данных
			по достижении целей обработки или в
			случае утраты необходимости в достижении
			этих целей, если иное не предусмотрено
			законодательством Российской Федерации.</p>
		</ol>
	</ol>
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>4. Цели
	обработки персональных данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		4.1. В соответствии с
		принципами обработки персональных
		данных в Компании определены цели их
		обработки:</p>
		<ol>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.1. Осуществление
			трудовых отношений с работниками в
			соответствии с действующим трудовым
			законодательством и заключенными
			трудовыми договорами и обеспечение
			работников комфортными условиями
			труда;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.2. Исполнение
			обязательств, предусмотренных
			действующим законодательством РФ,
			включая подзаконные акты; &nbsp;&nbsp;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.3. Заключение,
			исполнение, сопровождение, изменение,
			расторжение договоров с контрагентами
			с учетом требований действующего
			законодательства;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.4. Информационное
			обеспечение лиц, использующих внутренние
			информационные сервисы Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.5. Оформление гостевых
			пропусков для однократного прохода
			на территорию Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.6. Обработка обращений
			(жалоб, претензии, заявления и т.д.) по
			заключенным договорам (в т.ч. от
			пользователей) или в соответствии с
			действующим законодательством РФ;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			4.1.7. Продвижения
			товаров, работ, услуг на рынке путем
			осуществления прямых контактов с
			потенциальным потребителем с помощью
			средств связи.</p>
		</ol>
	</ol>
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>5. Правовые
	основания обработки п</b><b>ерсональных&nbsp;данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		5.1. Компания осуществляет
		обработку персональных данных на
		следующих правовых основаниях:</p>
		<ol>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.1. Устав ООО «ЭйТипс»;
			&nbsp;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.2. Трудовое
			законодательство, включая Трудовой
			кодекс РФ от 30.12.2001 г. № 197-ФЗ;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.3. Гражданский кодекс
			РФ от 30.11.1994 г. № 51-ФЗ (часть первая), от
			26.01.1996 г. №14-ФЗ (часть вторая), от
			26.11.2001 г. № 146-ФЗ (часть третья), от
			18.12.2006&nbsp;г. № 230-ФЗ (часть четвертая);</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.4. Налоговый кодекс
			РФ от 31.07.1998 г. № 146-ФЗ (часть первая),
			от 05.08.2000 г. №117-ФЗ (часть вторая);</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.5. Федеральный закон
			от 27.07.2006 № 149-ФЗ «Об информации,
			информационных технологиях и о защите
			информации»;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.6. Федеральный закон
			от 15.12.2001 г. № 167-ФЗ «Об обязательном
			пенсионном страховании»;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.7. Федеральный закон
			от 01.04.1996 г. № 27-ФЗ «Об индивидуальном
			(персонифицированном) учете в системе
			обязательного пенсионного страхования»;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.8. Федеральный закон
			от 06.12.2011 г. № 402-ФЗ «О бухгалтерском
			учете»;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.9. Постановления
			уполномоченных органов;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.10. Договоров, соглашений
			и иных документы с контрагентами;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.11. Пользовательских
			(лицензионных) соглашений интернет-сервиса;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.12. Согласий на
			обработку персональных данных;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.13. Согласий на
			обработку персональных данных,
			разрешенных субъектом персональных
			данных для распространения;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.14. Согласий на
			обработку персональных данных,
			разрешенных субъектом персональных
			данных для передачи третьим лицам;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			5.1.15. Локальных актов,
			принятые Компанией в соответствии с
			законодательством о персональных
			данных, включая настоящую Политику и
			действующую редакцию Положения об
			обработке персональных данных в ООО
			«ЭйТипс».</p>
		</ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		5.2. Для интернет-сервисов
		Компании предусмотрены соответствующие
		политики конфиденциальности, не
		противоречащие настоящей Политике.</p>
	</ol>
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>6. Категории
	субъектов персональных данных и объем
	обрабатываемых персональных данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		6.1. В Компании
		осуществляется или допускается
		обработка персональных данных следующих
		категорий субъектов персональных
		данных:</p>
		<ol>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.1. работников Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.2. родственников
			работников Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.3. бывших работников
			Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.4. исполнителей по
			договорам, соглашениям и иным документам
			с физическими лицами;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.5. пользователей
			интернет-сервисов Компании (в т.ч.
			бывшие);</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.6. соискателей;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.7. гостей, посетителей
			Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.8. участников
			проводимых Компанией мероприятий;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.9. представителей
			лиц, имеющих договорные отношения с
			Компанией;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.10. пользователей
			внутренних информационных сервисов
			Компании;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.11. субъектов
			персональных данных, которые направляют
			заявления, претензии и иные обращения
			в адрес Компании и их представителей;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.12. потенциальных
			потребителей товаров, работ, услуг;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			6.1.13. и иных физических
			лиц, чьи персональные данные могут
			быть обработаны в связи с возникающими
			взаимоотношениями.</p>
		</ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		6.2. Обработка Компанией
		специальных категорий персональных
		данных допускается только в случаях,
		предусмотренных федеральным
		законодательством РФ.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		6.3. Обработка Компанией
		биометрических персональных данных
		не осуществляется, если иное явным
		образом не указано в политике
		конфиденциальности интернет-сервисов
		Компании или в отдельном локальном
		нормативном акте.</p>
	</ol>
	<li  style="margin-top: 0.41cm"><h1 class="western" align="left"><b>7. Основные
	права и обязанности субъектов персональных
	данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		7.1. Субъекты персональных
		данных вправе:</p>
		<ol>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			7.1.1. получать информацию,
			касающуюся своих персональных данных
			и ее обработки, в порядке и в объеме,
			предусмотренном Федеральным законом
			от 27.07.2006 № 152-ФЗ «О персональных
			данных»;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			7.1.2. требовать от
			Компании уточнения персональных
			данных, блокирования или уничтожения
			в случае, если персональные данные
			являются неполными, устаревшими,
			неточными, незаконно полученными или
			не являются необходимыми для заявленной
			цели обработки;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			7.1.3. принимать
			предусмотренные законодательством
			РФ меры по защите своих прав;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			7.1.4. отзывать согласие
			на обработку персональных данных в
			порядке, предусмотренном настоящей
			Политикой;</p>
			<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
			7.1.5. реализовывать
			иные права, предусмотренные
			законодательством Российской Федерации.</p>
		</ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		7.2. Субъекты персональных
		данных обязаны осуществлять свои права
		в соответствии с Федеральным законом
		от 27.07.2006 № 152-ФЗ «О персональных данных,
		а также с иными нормативными правовыми
		актами.&nbsp;</p>
	</ol>
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>8. Основные</b><b>
	правила обработки персональных данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.1. Компания осуществляет
		обработку персональных данных, а
		именно: сбор, запись, систематизацию,
		накопление, хранение, уточнение
		(обновление, изменение), извлечение,
		использование, передачу (распространение,
		предоставление, доступ), блокирование,
		удаление и уничтожение персональных
		данных только при наличии оснований
		и в течение сроков, предусмотренных
		действующим законодательством.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.2. Обработка персональных
		данных Компанией осуществляется как
		с использованием, так и без использования
		средств автоматизации (в т.ч. смешанная
		обработка).</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.3. При обработке
		персональных данных Компания соблюдает
		права субъектов персональных данных
		и выполняет обязанности оператора,
		предусмотренные законодательством
		РФ о персональных данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.4. Компания, осуществляя
		обработку персональных данных субъекта
		персональных данных, вправе поручить
		обработку персональных данных другому
		лицу с согласия субъекта персональных
		данных и на основании заключаемого с
		этим лицом договора.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.5. При сборе персональных
		данных Компания обеспечивает запись,
		систематизацию, накопление, хранение,
		уточнение (обновление, изменение),
		извлечение персональных данных
		субъектов персональных данных с
		использованием баз данных, находящихся
		на территории РФ.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.6. Компания в ходе
		своей деятельности может осуществлять
		передачу персональных данных третьим
		лицам в случаях, предусмотренных
		законодательством РФ или с согласия
		субъекта персональных данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.7. Компания в ходе
		своей деятельности может осуществлять
		трансграничную передачу персональных
		данных с соблюдением условий,
		предусмотренных Федеральным законом
		от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.8. При обработке
		персональных данных Компания обеспечивает
		их конфиденциальность.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.9. Хранение персональных
		данных осуществляется в форме,
		позволяющей определить субъекта
		персональных данных, не дольше, чем
		того требуют цели обработки персональных
		данных, если срок хранения персональных
		данных не установлен федеральным
		законом, договором, стороной которого,
		выгодоприобретателем или поручителем,
		по которому является субъект персональных
		данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		8.10. По достижении целей
		обработки или в случае утраты
		необходимости в достижении этих целей,
		если иное не предусмотрено законодательством
		Российской Федерации - обрабатываемые
		персональные данные уничтожаются либо
		обезличиваются.</p>
	</ol>
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>9. Актуализация,
	исправление, удаление и уничтожение
	персональных данных, ответы на запросы
	субъектов на доступ к персональным
	данным</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.1. В случае подтверждения
		факта неточности персональных данных
		или неправомерности их обработки такие
		персональные
		данные подлежат актуализации Компанией.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.2. При достижении
		целей обработки персональных данных,
		а также в случае отзыва субъектом
		персональных данных согласия на их
		обработку, прекращения обработки
		персональных данных по обращению
		субъекта персональных данных Компания
		прекращает обработку персональных
		данных способом, предусмотренным
		ч. 7 ст. 5 Федерального закона от 27.07.2006
		г. № 152-ФЗ «О персональных данных».</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.3. Компания вправе
		продолжить обработку персональных
		данных в случае достижения цели
		обработки при наличии законных
		оснований.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.4. Компания вправе
		продолжить обработку персональных
		данных после отзыва
		согласия субъектом персональных данных
		на обработку персональных данных,
		обращения с требованием о прекращении
		обработки персональных данных по
		обращению субъекта персональных
		данных, при наличии оснований,
		предусмотренных в пунктах 2 - 11 части
		1 статьи 6, части 2 статьи 10 и части 2
		статьи 11 Федерального закона РФ от
		27.07.2006 г. № 152-ФЗ «О персональных данных».</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.5. В случае выявления
		неправомерной обработки персональных
		данных они подлежат уничтожению
		Компанией в порядке, предусмотренном
		законодательством РФ о персональных
		данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.6. Компания осуществляет
		реагирование на запросы/обращения
		субъектов персональных данных и их
		представителей, а также уполномоченных
		органов в соответствии с действующим
		законодательством РФ.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.7. Запросы/обращения
		указанных субъектов по поводу неточности
		персональных данных, неправомерности
		их обработки, доступа субъекта
		персональных данных к своим персональным
		данным направляются по адресу места
		нахождения Компании в письменной форме
		или в ином порядке, предусмотренном
		действующим законодательством РФ. 
		</p>
	</ol>
</ol>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
Отзыв согласия на
обработку персональных данных, обращение
субъекта персональных данных с требованием
о прекращении обработки персональных
данных направляются субъектом персональных
данных в письменной форме по адресу
места нахождения Компании.</p>
<ol>
	<ol start="8">
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.8. Компания вправе
		ограничить доступ субъекта персональных
		данных к его персональным данным в
		случаях, предусмотренных законодательством
		РФ, в том числе в случае, если доступ
		нарушает права и законные интересы
		третьих лиц.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.9. При направлении
		запроса/обращения в Компанию с целью
		реализации прав субъекта персональных
		данных необходимо указывать:</p>
	</ol>
</ol>
<ul>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	фамилию, имя, отчество
	субъекта персональных данных или его
	представителя;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	номер основного
	документа, удостоверяющего личность
	субъекта персональных данных или его
	представителя;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	сведения о дате
	выдачи указанного документа и выдавшем
	его органе;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	сведения, подтверждающие
	наличие у субъекта персональных данных
	отношений с Компанией (например, номер
	и дата договора, название и дата
	проведения конкурса, идентификатор
	пользователя в сервисах Компании:
	логин, id, ссылка на профиль, номер
	телефона, адрес электронной почты или
	иное), либо сведения, которые могут
	свидетельствовать об обработке Компанией
	персональных данных соответствующего
	субъекта;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	подпись субъекта
	персональных данных (или его представителя).</p>
</ul>
<ol>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		9.10. При направлении
		отзыва согласия на обработку персональных
		данных, обращения с требованием о
		прекращении обработки персональных
		данных в Компанию с целью реализации
		прав субъекта персональных данных
		необходимо указывать, в том числе:</p>
	</ol>
</ol>
<ul>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	фамилию, имя, отчество
	субъекта персональных данных или его
	представителя, адрес субъекта персональных
	данных;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	сведения, подтверждающие
	наличие у субъекта персональных данных
	отношений с Компанией (например, номер
	и дата договора, название и дата
	проведения конкурса, идентификатор
	пользователя в сервисах Компании:
	логин, id, ссылка на профиль, номер
	телефона, адрес электронной почты или
	иное), либо сведения, которые могут
	свидетельствовать об обработке Компанией
	персональных данных соответствующего
	субъекта;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	подпись субъекта
	персональных данных (или его представителя).</p>
</ul>
<ol start="10">
	<li style="margin-top: 0.41cm"><h1 class="western" align="left"><b>10. Выполняемые
	требования по обеспечению безопасности
	персональных данных</b></h1>
	<ol>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		10.1. С целью обеспечения
		безопасности персональных данных при
		их обработке в Компании реализуются
		требования применимых нормативных
		документов в области обработки и
		обеспечения безопасности персональных
		данных, включая:</p>
	</ol>
</ol>
<ul>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	Федеральный закон
	от 27.07.2006 г. № 152-ФЗ «О персональных
	данных»;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	Постановление
	Правительства РФ от 01.11.2012 г. № 1119 «Об
	утверждении требований к защите
	персональных данных при их обработке
	в информационных системах персональных
	данных»;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	Постановление
	Правительства РФ от 15.09.2008г. № 687 «Об
	утверждении Положения об особенностях
	обработки персональных данных,
	осуществляемой без использования
	средств автоматизации»;</p>
	<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
	Приказ ФСТЭК России
	от 18.02.2013 г. № 21 «Об утверждении Состава
	и содержания организационных и
	технических мер по обеспечению
	безопасности персональных данных при
	их обработке в информационных системах
	персональных данных».</p>
</ul>
<ol>
	<ol start="2">
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		10.2. Компания проводит
		оценку вреда, который может быть
		причинен субъектам персональных
		данных, и определяет угрозы безопасности
		персональных данных. 
		</p>
	</ol>
</ol>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
В соответствии с
выявленными актуальными угрозами
Компания применяет необходимые и
достаточные организационные и технические
меры, включающие в себя использование
средств защиты информации, обнаружение
фактов несанкционированного доступа,
восстановление персональных данных,
установление правил доступа к персональным
данным, а также контроль и оценку
эффективности применяемых мер.</p>
<ol>
	<ol start="3">
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		10.3. В Компании назначены
		лица, ответственные за организацию
		обработки и обеспечение безопасности
		персональных данных.</p>
		<li><p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm; ">
		10.4. Руководство Компании
		осознает необходимость и заинтересовано
		в обеспечении должного как с точки
		зрения требований нормативных документов
		РФ, так и обоснованного с точки зрения
		оценки рисков для бизнеса уровня
		безопасности персональных данных,
		обрабатываемых в рамках выполнения
		основной деятельности Компании.</p>
	</ol>
</ol>
<p align="justify" style="margin-top: 0.53cm; margin-bottom: 0.35cm; ">
Редакция № 1 от
26.04.2023 г.</p>
<p align="justify" style="margin-bottom: 0.35cm"><br/>
<br/>

</p>
`;
export const LEGAL_POLICY = `
<p align="left">
Российская
Федерация, г. Москва</p>
<p align="left" style="margin-bottom:0.35cm;  orphans: 0; widows: 0">
Редакция
от «26» апреля 2023 года
</p>
<p style="margin-bottom: 0.35cm">Настоящее Пользовательское соглашение является публичной офертой (далее –
«Соглашение») согласно ст.437 Гражданского кодекса Российской Федерации (далее также – «ГК РФ»), представляет
собой официальное предложение <i>общества с ограниченной ответственностью «ЭйТипс» (далее –
	«Администратор»),</i> адресованное физическим лицам или юридическим лицам, осуществляющим
предпринимательскую деятельность либо деятельность, приносящую доход (далее также – «Рекламодатель»), заключить
соглашение на использование Сервиса <span lang="en-US">ATIPS</span> (ЭЙТИПС) на указанных в нем условиях.</p>

<p style="margin-bottom: 0.35cm">Для акцепта настоящего Соглашения Рекламодателю достаточно принять его условия и
зарегистрироваться в Личном кабинете.
</p>
<p style="margin-bottom: 0.35cm">Принятие условия Соглашения осуществляется Рекламодателем путем проставления «V» в
графе «Я принимаю условия пользовательского соглашения», что подтверждает полное и безоговорочное принятие
условий Соглашения.
</p>
<p style="margin-bottom: 0.35cm"><i>Администратор информирует Рекламодателя о том, что если он не согласен с
	какими-либо условиями настоящего Соглашения, либо ему не понятны какие-то условия настоящего Соглашения,
	ему не следует осуществлять действия для акцепта Соглашения и входить в правоотношения с Администратором,
	возникающие на основании Соглашения, т.е. не стоит Регистрироваться в Сервисе и пользоваться услугами
	Администратора.</i></p>
<p style="margin-bottom: 0.35cm">Датой заключения Соглашения между Сторонами является дата акцепта Рекламодателем
Соглаше-ния и Регистрации в Личном кабинете.</p>
<h1 class="western" align="left"><b><i>
		Определения и толкования
	</i></b></h1>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Администратор
</b>общество с ограниченной ответственностью «ЭйТипс», ОГРН 1227700295943, <span>109382,
	109382, г. Москва, ул Нижние Поля, д. 31, стр. 1</span>,
фактический адрес: <span>1109382, г. Москва, ул Нижние Поля, д. 31, стр. 1.</span></p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Рекламодатель</b>
– лицо, заключившее с Администратором Соглашение и прошедшее процедуру Регистрации в Сервисе.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Стороны
	– </b>Администратор
и Рекламодатель.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Соглашение
	– </b>настоящее пользовательское соглашение, заключаемое между Сторонами, регулирующее условия
предоставления доступа к Сервису с целью размещения рекламных материалов, условия размещения рекламных
материалов, права и обязанности Сторон, финансовые условия, отражающее последствия нарушения обязательств и
содержащее иные условия взаимодействия Сторон.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Соглашение
размещено по адресу: <span style="letter-spacing: 0.2pt"><span><a href='https://market.atips.io/'
			target='_blank'>market.atips.io</a></span></span>
в соответствующем разделе сайта.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Факт того, что Рекламодатель при Регистрации не
ознакомился с условиями Соглашения, не освобождает его от соблюдения условий Соглашения.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Сервис
	(Интернет-Сервис)</b>
– платформа
«<span lang="en-US">ATIPS</span>»,
представляющая собой совокупность разработанных Администратором программных средств, по-средством которых
Рекламодатель получает возможность создавать Рекламную кампанию и размещать Рекламу.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Сервис
располагается по адресу: <span style="letter-spacing: 0.2pt"><span><a href='https://atips.media/'
			target='_blank'>atips.media</a>.</span></span></p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Контент</b>
– аудиовизуальные произведения (художественные, документальные, научно-популярные, учебные фильмы, сериалы,
мультипликационные фильмы, записи телевизионных программ и телевизионных передач, видеоролики, создаваемые
пользователями Сервиса, и иные аудиовизуальные произведения), размещаемые и доступные на Сервисе, их отдельные
фрагменты, элементы и т.д., в которых будет размещаться Реклама (Типсы).</p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm;  orphans: 0; widows: 0">
<b>Личный
	кабинет</b> –
раздел Сервиса, доступ к которому появляется у Рекламодателя после принятия Соглашения и Регистрации в Сервисе.
</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Личный кабинет предоставляет возможность
создания/изменения/удаления Рекламной кампании, ознакомления с Данными статистики, а также иные возможности,
исходя из функциональных возможностей Сервиса. </p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Личный кабинет располагается по адресу: <span
	style="letter-spacing: 0.2pt"><span><a href='https://market.atips.io/' target='_blank'>market.atips.io.</a></span></span></p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Регистрация
	- </b>процедура, включающая создание Рекламодателем Учетной записи, через которую осуществляется вход в
Личный кабинет для доступа к возможностям Сервиса.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><a name="_Hlk133429276"></a><b>Учетная
	запись</b> - под Учетной записью (профилем) Рекламодателя в рамках Соглашения понимается профиль
Рекламодателя в Сервисе, который включает в себя сведения, необходимые для идентификации Рекламодателя, и
информацию для авторизации в Сервисе.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><a name="_Hlk133434954"></a>Учетная запись
создается после Регистрации.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Данные статистики </b> – сводная информация,
содержащая данные о Рекламной кампании, стоимости Услуг по размещению Рекламной кампании в Отчетном периоде,
количестве переходов пользователей Сервиса по Типсам и иные сведения, устанавливаемые Администратором в
одностороннем порядке, формируемые по результатам автоматической обработки, с которыми Рекламодатель может
ознакомиться в Личном кабинете.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Лицевой счет Рекламодателя </b> – используется
Рекламодателем для оплаты Услуг Администратора.</p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Реклама
	(Типсы)</b> – рекламные объявления, всплывающие окна, которые добавляются в Контент после оформления
Рекламодателем Рекламной кампании в Личном кабинете.</p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Клик</b> – каждый случай перехода пользователя
из Сервиса на информационный ресурс (сайт, интернет-магазин) Рекламодателя, который осуществляется посредством
нажатия на Типс.</p>


<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Рекламная кампания </b> – действия
Рекламодателя, направленные на создание Рекламы, которые включают в себя в том числе наполнение рекламных
объявлений рекламируемыми товарами и услугами и иные действия, отображаемые в Личном кабинете при оформлении
Рекламной кампании. </p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Условия размещения Рекламы, в том числе место,
время, стоимость, определяются автоматизированными средствами Сервиса в автоматическом режиме после заполнения
Рекламодателем всех данных в Личном кабинете по конкретной Рекламной кампании. </p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>Отчетный период </b> – 1 (один) календарный
месяц. </p>

<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Первый Отчетный период равен периоду времени с
момента заключения Соглашения до истечения первого полного календарного месяца. </p>
<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае прекращения действия Соглашения по любым
основаниям последний Отчетный период равен периоду с момента окончания последнего полного календарного месяца до
момента прекращения действия Соглашения.</p>

<li>
<h1 class="western" align="left"><b><i>1. Предмет
		Соглашения</i></b></h1>
<ol>
	<li>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
			1.1. Администратор оказывает Рекламодателю услуги по размещению Рекламы (Типсов) путем
			предоставления Рекламодателю возможности самостоятельного создания Рекламной кампании в Личном
			кабинете (далее также – «Услуги», «Услуги размещения») при условии соблюдения Рекламодателем
			требований Администратора, Соглашения и законодательства Российской Федерации.
		</p>
	</li>
	<li>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
			1.2. В соответствии с ч.16 ст.18.1 Федерального закона «О рекламе», Реклама подлежит обязательному
			сопровождению пометкой «реклама» и информацией о Рекламодателе. </p>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель обязан до создания
			Рекламной кампании обеспечить Администратора до-стоверной и полной информацией о Рекламодателе.</p>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор вправе не размещать
			Рекламу (Типсы) или прекратить ее размещение в случае нарушения Рекламодателем данного условия. </p>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае неисполнения или
			ненадлежащего исполнения Рекламодателем указанного в настоящем пункте требования, если это повлекло
			причинение Администратору вреда, Рекламодатель обязуется по требованию Администратора возместить
			последнему такой вред, а также возникшие у Администратора расходы в полном объеме.</p>
	</li>
	<li>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
			1.3. Обязательства, связанные с созданием Рекламной кампании/размещением Рекламы (Типсов) в
			Сервисе:</p>
		<ol>
			<li>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					1.3.1. Принимая во внимание, что Администратор оказывает Рекламодателю Услуги, а также с
					учетом положений действующего законодательства Российской Федерации:</p>

				<ol>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.1. Рекламодатель поручает, а Администратор обязуется:</p>

						<ul>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
									Своевременно до начала размещения Рекламы получать от оператора рекламных
									данных («ОРД») маркер рекламы — идентификатор Рекламы (токен);</p>
							</li>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> Указывать
									идентификатор Рекламы (токен) во всей Рекламе (Типсах) до начала
									распространения Рекламы;</p>
							</li>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> Добавлять
									пометку «реклама», а также указание на Рекламодателя или сайт с информацией
									о нем во всей Рекламе до их распространения, если указанная информация не
									была добавлена ранее;</p>
							</li>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> Передавать
									за Рекламодателя всю необходимую информацию о распространенной Рекламе в
									Единый реестр интернет-рекламы («ЕРИР») через ОРД в порядке и сроки,
									установленные законодательством РФ.</p>
							</li>
						</ul>
					</li>

					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.2. Администратор передает информацию в ЕРИР самостоятельно или обеспечивает
							наличие идентичной обязанности о передаче информации в ЕРИР за Рекламодателя в
							договоре с лицом, обязанным передавать такую информацию.</p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.3. В течение 3 (Трех) рабочих дней с момента получения запроса от
							Рекламодателя, Администратор обязуется: </p>

						<ul>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
									предоставлять информацию об идентификаторе Рекламы (токене);</p>
							</li>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
									предоставлять информацию о заключении договора с обязанностью о передаче
									информации в ЕРИР за Рекламодателя с лицом, обязанным передавать такую
									информацию;</p>
							</li>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
									предоставлять информацию о выборе ОРД;</p>
							</li>
							<li>
								<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> вносить
									исправления в информацию, передаваемую в ОРД и ЕРИР, в случае получения от
									Рекламодателя измененной информации и/или получения запроса от ОРД по
									предварительному согласованию с Рекламодателем.</p>
							</li>
						</ul>
					</li>

					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.4. Администратор имеет право самостоятельно выбирать ОРД без дополнительного
							согласования с Рекламодателем.</p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.5. Рекламодатель предоставляет Администратору всю необходимую для передачи в
							ЕРИР и запрашиваемую ОРД информацию в течение 3 (Трех) рабочих дней с момента
							получения запроса от Администратора. </p>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							В случае изменения информации, передаваемой в ЕРИР, Рекламодатель в течение 3 (Трех)
							рабочих дней уведомляет Администратора о таких изменениях.</p>

					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.6. Расходы на оплату услуг ОРД входят в стоимость Услуг и оплачиваются
							Администратором самостоятельно.
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							1.3.1.7. Передача информации ОРД и/или в ЕРИР не является разглашением
							конфиденциальной информации либо персональных данных Рекламодателя.
					</li>

				</ol>
			</li>
		</ol>
	</li>

</li>

</ol>
</ol>
<ol start="2" style="margin-top: 0.41cm;">
<li>
	<h1 class="western" align="left"><b><i>2. Использование Сервиса</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
				2.1. Доступ к Сервису осуществляется при помощи сети Интернет через веб-браузер и
				пользовательские устройства - персональных компьютеров, ноутбуков, позволяющих по своим
				техническим характеристикам осуществлять использование Сервиса.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Работа Сервиса зависит от скорости
				сети Интернет и других критериев, в т. ч. используемых Рекламодателями устройств.</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор оставляет за собой
				право изменять перечень устройств, доступ с которых возможен к Сервису. </p>
		</li>
	</ol>
</ol>
<ol>
<ol start="2">
	<li>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
			2.2. Использование Сервиса для размещения Рекламы (Типсов) возможно на территории Российской
			Федерации.</p>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
			Соглашаясь с условиями использования Сервиса, Рекламодатель понимает, что Реклама (Типсы) может быть
			не доступна пользователям Сервиса за пределами Российской Федерации, в связи с чем Администратор не
			несет за это ответственности. </p>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор оставляет за собой право
			в одностороннем порядке изменять территорию использования Сервиса.</p>
	</li>
	<li>
		<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><i>2.3. Процесс Регистрации на
				Сервисе:</i>
		</p>
		<ol>
			<li>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					2.3.1. Регистрация в Сервисе осуществляется путем прохождения специальной процедуры и
					заполнения всей необходимой информации для Регистрации, либо с использованием сервиса
					партнеров, таких как Tinkoff ID, Сбер ID, МТС ID, ВК ID и т.п.</p>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					Регистрация в Сервисе посредством сервиса партнера, означает согласие Рекламодателя с
					настоящим Соглашением. </p>
			</li>
			<li>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					2.3.2. После заполнения регистрационной формы, данные о Регистрации поступают
					Администратору для осуществления их проверки.</p>


				<ol>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.2.1. Если все обязательные данные были указаны Рекламодателем корректно и у
							Администратора не возникло дополнительных вопросов, Администратор подтверждает
							Учетную запись.</p>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							С момента подтверждения Учетной записи Рекламодателю становится доступен Личный
							кабинет со всеми функциональными возможностями для создания Рекламной кампании. </p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.2.2. Если по каким-либо причинам введенные Рекламодателем данные не прошли
							проверку со стороны Администратора, последний вправе запросить дополнительные данные
							и информацию у Рекламодателя и в случае их предоставления и достаточности -
							подтвердить создаваемую Учетную запись.</p>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							В случае непредоставления дополнительно запрошенных данных и информации, либо если
							Администратор усомнится в благонадежности Рекламодателя, Учетная запись последнему
							не создается. </p>

						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							Администратор информирует Рекламодателя о том, что он оставляет за собой право без
							объяснения каких-либо причин отказать Рекламодателю в Регистрации.</p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.2.3. Администратор уведомляет Рекламодателя о принятом решение (п.2.3.3.1,
							2.3.3.2 Соглашения) по электронной почте, либо иным доступным способом.</p>
					</li>






				</ol>
			</li>

			<li>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					2.3.3. Вход в Личный кабинет осуществляется с использованием логина и созданного пароля.</p>

				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					Вход в Личный кабинет также возможен посредством сервиса партнера, такого как
					Tinkoff ID, Сбер ID, МТС ID, ВК ID и т.п.</p>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					Сервис партнер определяется Администратором самостоятельно, без какого-либо
					дополнительного согласования с Рекламодателем, и в течение существования Сервиса
					такой партнер может меняться.</p>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					Администратор оставляет за собой право вовсе отказаться от использования сервиса
					партнера, в связи с чем доступ к Личному кабинету будет осуществляться с
					использованием логина и пароля.</p>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					В случае наличия технических проблем, препятствующих входу Рекламодателю в Учетную
					запись, утери пароля, Рекламодатель имеет возможность восстановить пароль согласно
					процедуре, действующей в Сервисе, либо путем обращения к Администратору.</p>
			</li>
			<li>
				<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
					2.3.4. Администратор оставляет за собой право запросить у Рекламодателя следующие
					документы: </p>

				<ol>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.4.1. Копия паспорта; </p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.4.2. Копия устава; </p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.4.3. Копия свидетельства о регистрации юридического лица либо физического лица в
							качестве индивидуального предпринимателя; </p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.4.4. Копия свидетельства о присвоении ИНН; </p>
					</li>
					<li>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							2.3.4.5. Иные документы, запрошенные в Личном кабинете </p>

						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							Документы предоставляются Администратору в указанном им порядке.</p>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							Предоставляя Администратору вышеуказанные документы, Рекламодатель подтверждает и
							гарантирует их достоверность и соответствие оригиналам таких документов, в связи с
							чем Администратор полагается на их достоверность, как если бы они были представлены
							на бумажном носителе в оригинале. </p>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							Перечень предоставляемых Рекламодателем документов зависит от статуса Рекламодателя
							(индивидуальный предприниматель, юридическое лицо, физическое лицо,
							зарегистриро-ванное в качестве плательщика налога на профессиональный доход).</p>
						<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">
							В случае непредоставления Рекламодателем запрошенных документов, Администратор
							оставляет за собой право приостановить оказание Услуг до момента предоставления всех
							запрошенных документов. </p>
					</li>
				</ol>
			</li>
		</ol>
	</li>

</ol>
</li>

</ol>

<ol start="3" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>3. Создание Рекламной кампании</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.1. В Личном кабинете
				Рекламодатель имеет возможность создать Рекламную кампанию, определить перечень товаров/услуг,
				которые будут рекламироваться, ознакомиться со стоимостью Рекламной кампании, стоимостью Клика и
				осуществить иные действия, доступные в Личном кабинете.
			</p>
		</li>

		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.2. После создания и
				подтверждения Рекламной кампании в Контенте начинает появляться Реклама (Типсы).
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.3. Типсы содержат в себе ссылку
				для перехода на сайт Рекламодателя к указанному им товару/услуге, которая открывается
				посредством Клика.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">При размещении Рекламы (Типсов)
				Рекламодатель гарантирует пользователям наличие рекламируемых товаров/услуги и возможность их
				приобретения на момент перехода пользователя по Типсу посредством Клика.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.4. Всплывающие Типсы предлагают
				пользователям, осуществляющим просмотр Контента, приобрести товары, услуги, схожие с
				присутствующими в Контенте, например, аналог увиденного садового инвентаря, техники, элементов
				одежды и пр.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель гарантирует, что
				описание рекламируемых товаров/услуг будет соответствовать рекламируемым товарам/услугам и
				такая Реклама не будет вводить в заблуждение пользователей Сервиса.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Всю ответственность за
				недостоверную Рекламу, в том числе, если вместо оригинальных товаров предлагаются их копии
				(реплики), несет непосредственно Рекламодатель.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Если из-за действий Рекламодателя
				к Администратору будут предъявлены какие-либо претензии и/или иски, либо возникнут убытки (в том
				числе в виде штрафов), Рекламодатель обязуется разрешить такие претензии/иски самостоятельно и
				за свой счет, возместить Администратору убытки.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.5. При создании Рекламной
				кампании имеется возможность проверить охват аудитории и спрогнозировать бюджет Рекламной
				кампании и, в случае необходимости, изменить те или иные условия Рекламной кампании.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.6. После создания Рекламной
				кампании Типсы размещаются при помощи автоматизированного программного обеспечения, входящего в
				состав Сервиса, в Контенте в автоматическом режиме.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">3.7. В случае внесения изменений в
				условия Рекламной кампании после ее создания, а равно приостановки (прекращении) Рекламной
				кампании, Рекламодатель соглашается с тем, что новые условия/момент приостановки (прекращения)
				Рекламной кампании вступают в силу/наступает спустя некоторое количество времени, а не сразу.
			</p>

			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор информирует
				Рекламодателя, а последний соглашается с тем, что в случае, если пользователь осуществляет
				просмотр Контента в период внесения им изменений в Рекламную кампанию, приостановки
				(прекращения) Рекламной кампании, такие изменения не доступны пользователю до момента окончания
				просмотра, в связи с чем пользователь может осуществить переход по Типсам, и в этом случае
				стоимость Услуг будет списана с Лицевого счета.
			</p>
		</li>
	</ol>

</li>
</ol>

<ol start="4" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>4. Требования к Рекламе (Типсам)</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.1. Реклама должна быть
				добросовестной и достоверной. Недобросовестная реклама и недостоверная реклама не допускаются
			</p>

			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Признаки недобросовестной и
				недостоверной рекламы приведены в ст.5 Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе».
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.2. При создании Рекламной
				кампании, Рекламодатель обязуется исходить из следующих запрещений и ограничений:
			</p>

			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.2.1. Категорически
						запрещена Реклама товаров и (или) услуг, производство и (или) реализация которых
						запрещены законодательством Российской Федерации, в том числе:
					</p>

					<ul>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> сайтов,
								осуществляющих сбор персональных данных и/или контактной информации (в т.ч.
								номера телефонов, адреса электронной почты), без пояснения цели и/или согласия
								на обработку персональных данных, либо указывать ссылки на такие сайты в Типсах;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> товаров и услуг
								для взрослых: порнографические материалы, интим-товары, эскорт-услуги, сервисы,
								целью которых является поиск партнеров, предоставляющих услуги интимного
								характера, а также интимные тренинги и тренинги по соблазнению (в т.ч. пикап)
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> наркотических
								средств, психотропных веществ и средств их употребления;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> алкогольной,
								спиртосодержащей продукции, в том числе средств по изготовлению алкоголя;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> табака, табачной
								продукции, табачных изделий и курительных принадлежностей, в том числе кальянов,
								электронных сигарет, испарителей, вейпов и комплектующих к ним, сигаретной
								бумаги;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> лекарств,
								отпускаемых строго по рецепту, контрацептивов, отпускаемых по рецепту или
								применение которых требует наличия специальных знаний (спирали и т.п.);
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> медицинских
								услуг по искусственному прерыванию беременности;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> анаболических
								стероидных, иных препаратов, специализированных пищевых продуктов для
								применения, влияющих на рост мышц, действие которых аналогично действию
								стероидов;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> продукции
								военного назначения и оружия (боевое ручное стрелковое и холодное, служебное,
								гражданское, в т.ч. оружие самообороны, спортивное с дульной энергией свыше 3
								Дж, охотничье и сигнальное);
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> казино,
								тотализаторов и других игр, основанных на риске и пари, за исключением покера
								как интеллектуально-коммерческой игры;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> взрывчатых
								веществ и материалов, за исключением пиротехнических изделий;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> финансовых
								пирамид, касс взаимопомощи, сервисов для быстрого заработка;
							</p>
						</li>

						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> органов и (или)
								тканей человека в качестве объектов купли-продажи;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> подделок (копии,
								реплики) товаров;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> услуг по
								подготовке и написанию выпускных квалификационных работ, научных докладов об
								основных результатах, подготовленных научно-квалификационных работ (диссертаций)
								и иных работ, предусмотренных государственной системой научной аттестации или
								необходимых для прохождения обучающимися промежуточной или итоговой аттестации;
							</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"> а также иных
								товаров и/или услуг, на которые действуют ограничения в рамках действующего
								законодательства Российской Федерации.
							</p>
						</li>
					</ul>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Перечень запрещений и
						ограничений не является исчерпывающим.
					</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">С точным и актуальным на
						момент создания Рекламной кампании перечнем запрещений и ограничений Рекламодатель
						обязуется ознакомиться в Федеральном законе от 13.03.2006 № 38-ФЗ «О рекламе» и иных
						нормативных актах.
					</p>
				</li>
			</ol>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.3. Рекламодатель понимает и
				соглашается с тем, что Администратор не занимается модерацией Рекламы (Типсов), в том числе не
				проверяет ее на соответствие требованиям действующего законодательства Российской Федерации.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель самостоятельно несет
				ответственность за соответствие Рекламы (Типсов) требованиями Соглашения и действующего
				законодательства Российской Федерации.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор оставляет за собой
				право осуществлять выборочную проверку Рекламы (Типсов) на свое усмотрение на соответствие ее
				требованиям действующего законодательства Российской Федерации, условиям Соглашения, а также в
				случае жалоб, поступающих от пользователей Сервиса и третьих лиц.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.4. Если для рекламирования
				каких-либо товаров/услуг требуется наличие специального разрешения (лицензии) на реализацию
				таких товаров/услуг пользователям, Рекламодатель обязуется предоставить такие документы
				Администратору самостоятельно либо по первому требованию Администратора.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.5. Если Реклама (Типсы) не
				соответствует требованиям настоящего Соглашения либо действующего законодательства Российской
				Федерации, либо если Рекламодателем не были представлены необходимые документы, Администратор
				оставляет за собой право заблокировать распространение Рекламы (Типсов) до выяснения всех
				обстоятельств.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.6. Если из-за действий
				Рекламодателя, в том числе из-за несоответствия Рекламы (Типсов) требованиям Соглашения,
				действующего законодательства Российской Федерации, к Администратору будут предъявлены
				какие-либо претензии, требования и/или иски со стороны третьих лиц, Рекламодатель обязуется
				разрешить такие претензии, требования, иски самостоятельно и за свой счет, без привлечения
				Администратора, а в случае если у Администратора возникнут какие-либо убытки, в том числе в
				результате уплаты штрафов, либо иные расходы в связи с такими претензиями, требованиями, исками
				третьих лиц – Рекламодатель обязуется возместить такие убытки/расходы в полном объеме.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.7. Администратор вправе удалить
				размещенную Рекламодателем Рекламу (Типсы) в следующих случаях:
			</p>
			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.7.1. Если Реклама
						нарушает условия Соглашения и/или требования действующего законодательства;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.7.2. Если Реклама может
						нанести вред Сервису, Администратору, пользователям или третьим лицам;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.7.3. Если Реклама
						нарушает авторские права третьих лиц и от таких лиц поступили жалобы на Рекламу.
					</p>
				</li>
			</ol>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">4.8. Рекламодатель соглашается с
				тем, что Администратор вправе предоставлять информацию о Рекламе, о показах Рекламы, Кликах и
				иную статистическую информацию правообладателям Контента, в том числе путем отображения таких
				данных в личном кабинете правообладателей.</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Предоставление указанной
				информации не является разглашением конфиденциальной, коммерческой или иной информации, в связи
				с чем Администратор не может быть привлечен к ответственности по требованиям Рекламодателя.</p>
		</li>
	</ol>
</li>
</ol>


<ol start="5" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>5. Стоимость и порядок оказания Услуг</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.1. Стоимость Услуг формируется
				исходя из введенных Рекламодателем данных и критериев Рекламной компании, стоимости одного
				Клика.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Стоимость Услуг формируется
				автоматически в реальном времени и отображается в Личном кабинете.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.2. Путем запуска Рекламной
				компании Рекламодатель соглашается со стоимостью Услуг.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.3. Стоимость Услуг
				рассчитывается исходя из стоимости одного Клика и количества перехода пользователей Сервиса по
				Типсу посредством Клика.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.4. Стоимость Услуг за каждый
				Клик списывается с баланса Рекламодателя в автоматическом режиме.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.5. Создание Рекламной кампании
				возможно исключительно при положительном балансе Лицевого счета.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае недостаточности денежных
				средств на Лицевом счете, вновь создаваемая Рекламная кампания не подлежит запуску, а
				действующая Рекламная кампания прекращается (приостанавливается).
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.6. Расчет стоимости Услуг
				размещения производится в рублях РФ.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.7. Услуги Администратора
				включают в себя НДС по ставке, установленной действующим законодательством Российской Федерации.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.8. Администратор ежемесячно
				формирует и отправляет Рекламодателю Акт об оказанных Услуг (далее – «Акт»), подтверждающий факт
				оказания Услуг за Отчетный период.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Акт направляется в течение 7
				(Семи) рабочих дней с момента окончания Отчетного периода.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Формирование Акта осуществляется
				электронным способом посредством Сервиса (если такая опция доступна) либо путем направления в
				адрес Рекламодателя сканированной копии Акта, подписанной Администратором, в том числе
				электронной цифровой подписью.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель обязуется подписать
				и предоставить Администратору экземпляр Акта или мотивированный отказ от подписания Акта в
				течение 5 (пяти) рабочих дней с момента его получения.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Если в указанный срок
				Рекламодатель не направит Акт либо мотивированный отказ от подписания Акта, Акт считается
				подписанным, а Услуги надлежащим образом оказанными и принятыми Рекламодателем.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.9. Администратор выставляет
				Рекламодателю счет-фактуру в сроки, предусмотренные действующим законодательством РФ и
				направляет ее в Личном кабинете.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.10. Стороны вправе использовать
				систему электронного документооборота (далее- «ЭДО»), позволяющую подписывать документы
				электронной подписью (далее – «ЭП»).
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">5.11. Вместо Акта и счета-фактуры
				Стороны вправе использовать универсальный передаточный документ (далее – «УПД») в порядке и
				сроки, предусмотренные Сторонами для подписания Акта.
			</p>
		</li>
	</ol>
</li>
</ol>


<ol start="6" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>6. Права и обязанности Рекламодателя</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>6.1. Рекламодатель вправе:</b>
			</p>

			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.1.1. Знакомиться с
						Данными статистики и осуществлять контроль над размещением Рекламы (Типсов) доступными
						на Сервисе способами.
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.1.2. По своему
						усмотрению изменять условия Рекламной кампании в рамках возможностей Сервиса.
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.1.3. Удалить свою
						Учетную запись
					</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">После удаления Учетной
						записи доступ к Сервису прекращается.
					</p>
				</li>
			</ol>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>6.2. Рекламодатель обязан:</b>
			</p>
			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.1. Соблюдать условия
						Соглашения, требования действующего законодательства, а также права и законные интересы
						Администратора, иных пользователей, правообладателей Контента и любых третьих лиц;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.2. Оплачивать Услуги
						Администратора;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.3. Использовать Сервис
						исключительно для целей размещения Рекламы;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.4. Воздерживаться от
						осуществления действий, направленных на дестабилизацию работы Сервиса, осуществления
						попыток несанкционированного доступа к Сервису, результатам интеллектуальной
						деятельности, размещенным на нем, обходу ограничений, установленных на Сервисе, от
						осуществления любых иных действий, нарушающих права Администратора, правообладателей,
						пользователей и/или третьих лиц;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.5. Принимать
						надлежащие меры для обеспечения сохранности своей Учетной записи. </p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель несет
						ответственность за все действия, совершенные на Сервисе под его Учетной записью.</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель не имеет
						права передавать, уступать, продавать, передавать в пользование и т.п. Учетную запись
						либо данные от Учетной записи третьим лицам без предварительного согласия
						Администратора;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.6. Передать
						Администратору всю необходимую, полную и достоверную информацию для оказания Услуг, в
						том числе при Регистрации.</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Указание данных третьих
						лиц либо их размещение на Сервисе без их согласия не допускается.</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель обязуется
						самостоятельно следить за актуальностью указанных им данных при Регистрации, в том числе
						контактных, банковских реквизитов, своевременно обновлять их.</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель гарантирует
						Администратору, что представленная при Регистрации контактная информация действительно
						принадлежат ему и любые сообщения, направленные или полученные с использованием такой
						контактной информации, будут считаться соответственно направленными или полученными
						Рекламодателем.</p>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае изменения
						контактной информации и не сообщения Рекламодателем об этом, все неблагоприятные
						последствия, которые могут возникнуть из-за этого, несет непосредственно Рекламодатель;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.7. Иметь все
						необходимые лицензии, разрешения и сертификаты и предоставлять их надлежащие копии
						Администратору, если объект рекламирования подлежит обязательной сертификации или
						лицензированию;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.8. Обеспечить наличие
						рекламируемого товара и возможность его приобретения пользователями в период действия
						Рекламной кампании;</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9. Не размещать, не
						сообщать, не предоставлять или не распространять какую-либо информацию:</p>

					<ol>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.1.
								противоречащую требованиям законодательства или общепризнанным нравственным
								требованиям, в том числе содержащую лексику нецензурного характера, непристойные
								или порнографические тексты и/или изображения;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.2.
								содержащую угрозы, оскорбления, порочащую честь, достоинство и/или деловую
								репутацию каких-либо лиц или нарушающую тайну частной жизни или иную охраняемую
								законом тайну;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.3.
								содержащую описание насилия, жестокого обращения с животными, описание способов
								суицида или подстрекающую к подобным действиям;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.4.
								содержащую экстремистские материалы;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.5.
								разжигающую любого рода ненависть, рознь или вражду;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.6.
								содержащую информацию о преступной деятельности любых лиц;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.7. имеющую
								ложный или мошеннический характер;</p>
						</li>
						<li>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.9.8. иным
								образом нарушающую требования законодательства и/или права любых лиц.</p>
							<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">В случае выявления
								фактов нарушения настоящего пункта, Администратор имеет право по своему
								усмотрению: удалить такую информацию и/или заблокировать Учетную запись.</p>

						</li>
					</ol>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">6.2.10. Рекламодатель
						также несет иные обязанности, как предусмотренные Соглашением, так и действующим
						законодательством Российской Федерации.</p>
				</li>
			</ol>
		</li>
	</ol>
</li>
</ol>

<ol start="7" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>7. Права и обязанности Администратора</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>7.1. Администратор вправе:</b>
			</p>
			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.1. Определять
						(изменять) функциональный состав Сервиса, его внешний вид и иные элементы Сервиса;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.2. Полностью
						прекратить или приостановить оказание Услуг в рамках Сервиса без какого-либо согласия
						Рекламодателя.
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.3. Решать вопросы,
						связанные с коммерческим использованием Сервиса, на что Рекламодатель дает ему
						согласие путем акцепта настоящего Соглашения;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.4. Изменять содержание
						Контента, в том числе заменять, удалять Контент без какого-либо согласия Рекламодателя;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.5. Разрешать и
						ограничивать доступ к Сервису в соответствии с Соглашением и законодательством
						Российской Федерации;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.6. В случае нарушения
						Рекламодателем Соглашения приостановить или прекратить доступ Рекламодателю к Сервиса в
						одностороннем порядке, а также блокировать возможность использования Сервиса
						(блокировать авторизацию и/или IP-адреса, конкретное устройство Рекламодателя);
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.7. Применять любые
						действия с целью недопущения несанкционированного доступа к Сервису, размещенному
						на нем Контенту, дестабилизации работы Сервиса и других действий, нарушающих права и
						законные интересы Администратора и/или правообладателей Контента;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.8. В одностороннем
						порядке вносить изменения и дополнения в Соглашение;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.9. Осуществлять
						коммуникацию с Рекламодателем по всем каналам связи, сведения о которых предоставлены
						Рекламодателем;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.10. Осуществлять
						обработку персональных данных Рекламодателя на условиях, предусмотренных настоящим
						Соглашением и политикой конфиденциальности (или иным аналогичным документом,
						размещенным в Сервисе);
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.11. Привлекать любых
						третьих лиц для исполнения Соглашения без какого-либо согласования с Рекламодателем;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.1.12. В случае нарушения
						Рекламодателем условия Соглашения требовать возмещения убытков, компенсации штрафов и
						расходов, которые могут возникнуть у Администратора из-за действий Рекламодателя.
					</p>
				</li>
			</ol>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><b>7.2. Администратор обязан:</b>
			</p>
			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.2.1. Обеспечить
						техническую возможность получения Рекламодателем доступа к Сервису в порядке и на
						условиях, определенных настоящим Соглашением.
					</p>
				</li>
			</ol>
		</li>
		<li>

			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">7.3. Администратор не осуществляет
				предварительную модерацию Рекламы (Типсов).
			</p>

		</li>
	</ol>
</li>
</ol>

<ol start="8" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>8. Интеллектуальная собственность</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">8.1. Администратор является
				правообладателем Сервиса, всех его элементов, частей, фрагментов, исходного, объектного кода,
				визуального оформления, текстов и иных результатов интеллектуальной деятельности входящих в
				Сервис либо размещенных на нем, в том числе Контента.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Все права на результаты
				интеллектуальной деятельности и средства индивидуализации защищены действующим законодательством
				Российской Федерации и соответствующими нормами международного права.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Соглашение не предоставляет
				Рекламодателю каких-либо прав на распоряжение вышеуказанными результатами интеллектуальной
				деятельности.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Любое использование вышеуказанных
				результатов интеллектуальной деятельности за пределами прав, предоставленных Рекламодателю по
				Соглашению, является неправомерным и влечет гражданскоправовую, административную и уголовную
				ответственность и иные негативные последствия, предусмотренные Соглашением.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">8.2. Рекламодателю не
				предоставляется право на скачивание, создание копий, переработку, распространение, публичный
				показ, публичное исполнение, сообщение в эфир или по кабелю, прокат, ретрансляцию, доведение до
				всеобщего сведения результатов интеллектуальной деятельности и средств индивидуализации, не
				принадлежащие Рекламодателю, а также не предоставляется право на их размещение на товарах, при
				выполнении работ и оказании услуг, на документации, в объявлениях, на вывесках и в рекламе, а
				также в сети Интернет, в том числе в доменных именах.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель не вправе
				копировать, скачивать, распространять, передавать, транслировать, демонстрировать, продавать,
				лицензировать, изменять, преобразовывать или каким-либо образом использовать Контент, не
				принадлежащий ему.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Запрет, установленный настоящим
				пунктом, в равной степени распространяется как на полное, так и на частичное/фрагментарное
				использование результатов интеллектуальной деятельности и средств индивидуализации и их
				отдельных охраняемых элементов, используемых/размещенных на Сервисе.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">8.3. Рекламодатель обязуется не
				применять каких-либо средств, методов и способов, прямо или косвенно направленных на обход
				технических средств защиты, используемых на Сервисе.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Нарушение данного пункта
				признается нарушением авторских прав, в связи с чем к Рекламодателю могут быть применены меры
				ответственности по Соглашению и действующему законодательству Российской Федерации.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">8.4. Нарушение Рекламодателем
				настоящего Раздела Соглашения является безусловным и бесспорным основанием для одностороннего
				отказа Администратора от Соглашения и блокировки доступа к Сервису.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">8.5. Если у Администратора
				возникнут какие-либо обязательства перед третьими лицами из-за нарушения Рекламодателем условия
				настоящего Раздела, в том числе по выплате штрафных санкций, возмещению убытков, выплате
				компенсаций, Рекламодатель обязан компенсировать Администратору такие суммы в полном объеме.
			</p>
		</li>
	</ol>
</li>
</ol>

<ol start="9" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>9. Ограничения использования Сервиса</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm"><i>9.1. Помимо иных ограничений в
					рамках Соглашения, Рекламодателям запрещается:</i>
			</p>
			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">9.1.1. Пользоваться
						Сервисом с помощью автоматических программных средств, таких как (но не ограничиваясь):
						боты, ботнеты и сборщики контента.
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">9.1.2. Обходить
						ограничения, а также средства защиты, которые действуют на Сервисе;
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">9.1.3. Собирать любую
						информацию, которая может быть использована для идентификации личности пользователей
						Сервиса.
					</p>
				</li>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">9.1.4. Беспочвенно
						обвинять других пользователей: отправлять ложные жалобы, отмечать контент как
						недопустимый, подавать жалобы на нарушение авторских прав.
					</p>
				</li>
			</ol>
		</li>
	</ol>
</li>
</ol>


<ol start="10" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>10. Изменения, улучшения и обновления Сервиса</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">10.1. Сервис находится в стадии
				постоянного улучшения, в связи с чем у Сервиса могут как появляться, так и исчезать новые
				функции, опции, инструменты.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Администратор приложит все усилия
				для того, чтобы обновление Сервиса осуществлялась максимально комфортным для Рекламодателя
				образом и не влияло на доступ Рекламодателя к Сервису, при этом в период установки обновлений
				Сервис может быть временно недоступен, либо могут наблюдаться технические сбои в работе
				Сервиса.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">10.2. Любые изменения, вносимые в
				Сервис, обновления, изменение Контента, осуществляются Администратором по своему усмотрению без
				какого-либо согласования с Рекламодателем.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Продолжая пользоваться Сервисом,
				Рекламодатель выражает свое безоговорочное согласие со всеми изменениями Сервиса и не вправе
				предъявлять какие-либо претензии Администратору.
			</p>
		</li>
	</ol>
</li>
</ol>

<ol start="11" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>11. Ответственность Сторон</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.1. За нарушение положений
				Соглашения Рекламодатель несет ответственность, указанную в нем, а также в соответствии с
				применимым законодательством.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.2. Администратор имеет право
				ограничить доступ Рекламодателю к Сервису, заблокировать и/или удалить его Учетную запись,
				блокировать/удалить размещенную им Рекламу.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.3. Администратор не несет
				ответственности и не предоставляет Рекламодателю каких-либо гарантий в отношении использования
				Сервиса, включая соответствие Сервиса ожиданиям Рекламодателя, безошибочную и бесперебойную
				работу Сервиса.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Сервис и существующие в нем
				возможности предоставляются Рекламодателю «как есть» («as is»).
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель самостоятельно
				принимает на себя любые риски, связанные с использованием Сервиса, в том числе риски
				возникновения у Рекламодателя в связи с использованием Сервиса каких-либо убытков или потерь, а
				также причинения какого-либо ущерба или вреда, и Администратор не несет ответственности за это.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.4.	Администратор не несет ответственности за технические сбои и неполадки в работе пользовательских устройств, иного оборудования, используемого Рекламодателем, сетей связи, программного обеспечения, серверного оборудования, сервисов электронной почты, а также за любые убытки, которые могут быть вызваны такими сбоями и неполадками.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.5.	Администратор не несет ответственности и не возмещает Рекламодателю никакие денежные средства в случаях несанкционированного использования Учетной записи Рекламодателя третьими лицами. 
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель самостоятельно несет полную ответственность за сохранение в тайне от третьих лиц его регистрационных данных (логина, пароля), а также за недопуск третьих лиц к каналам связи, посредством которых возможно изменение или восстановление регистрационных данных Рекламодателя.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.6.	Администратор не несет ответственности перед третьими лицами и иными пользователями за какие-либо действия Рекламодателя, включая Рекламу (Типсы). 
			</p>

			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Рекламодатель самостоятельно несет полную ответственность за любые действия, совершенные с использованием его Учетной записи, в том числе за неблагоприятные последствия для него.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Если к Администратору будут предъявлены какие-либо претензии или требования, в том числе об уплате штрафов и компенсаций, в связи с действиями, указанными выше, Рекламодатель обязуется возместить такие штрафы и компенсации в добровольном порядке в течение 10 (Десяти) календарных дней с момента получения соответствующего требования от Администратора.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.7.	Стороны признают и подтверждают, что в случае перехода Рекламодателя по каким-либо гиперссылкам, размещенным на Сервисе другими пользователями, Администратор не несет какой-либо ответственности за доступность интернет-ресурсов по таким ссылкам и не контролирует их содержание, в связи с чем не несет ответственности за любые последствия перехода Рекламодателя по таким ссылкам. 
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.8.	Рекламодатель гарантирует, что:
			</p>
			<ol>
				<li>
					<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.8.1.	Не нарушает своими действиями законные права и интересы третьих лиц (включая, но не ограничиваясь, авторские, смежные, патентные права, право на неприкосновенность частной жизни, на защиту чести, достоинства и доброго имени, право на изображение гражданина и т.д.) и действующее российское законодательство. 
					</p>
				</li>
			</ol>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">11.9.	Для защиты свои прав Администратор имеет право предоставлять третьим лицам настоящее Соглашение, а также данные Рекламодателя, в связи с чем Рекламодатель дает Администратору согласие на осуществление таких действий.
			</p>
		</li>
	</ol>
</li>
</ol>


<ol start="12" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>12. Персональные данные</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">12.1. Все правила и условия
				обработки данных Рекламодателя, включая персональные данные, определяются в отдельном документе,
				размещенном в Сервисе.
			</p>
		</li>
	</ol>
</li>
</ol>


<ol start="13" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>13. Разрешение споров</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">13.1. Все споры и разногласия,
				возникающие при исполнении Соглашения, использовании Сервиса, решаются Сторонами путем
				переговоров и в претензионном порядке
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">13.2. Претензии могут направляться
				друг другу с использованием адресов электронной почты, указанных каждой из сторон.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">13.3. Если достичь согласия путем
				переговоров и в претензионном порядке не представляется возможным, споры решаются в судебном
				порядке в соответствии с действующим законодательством Российской Федерации, по месту
				нахождения Администратора.
			</p>
		</li>
	</ol>
</li>
</ol>

<ol start="14" style="margin-top: 0.41cm">
<li>
	<h1 class="western" align="left"><b><i>14. Заключительные положения</i></b></h1>
	<ol>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">14.1. Недействительность
				какого-либо условия Соглашения не влечет недействительности прочих его условий. В этом случае
				Стороны руководствуются Соглашением без учета условия, не имеющего юридической силы.
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">14.2. Во всем остальном, что не
				предусмотрено Соглашением, Стороны руководствуются положениями действующего законодательства
				Российской Федерации.</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">14.3. Соглашение заключено на
				неопределенный срок. Прекращение или временная приостановка использования Сервиса
				Рекламодателем, без удаления Учетной записи, не влечет прекращения действия Соглашения.</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">14.4. Администратор оставляет за
				собой право изменять условия Соглашения в одностороннем порядке в любое время по своему
				усмотрению.</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Обновленное Соглашение
				распространяются на всех Рекламодателей с момента внесения изменений в Соглашение.</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Продолжая использовать Сервис
				после внесения изменений в Соглашение, Рекламодатель подтверждает свое согласие с обновленным
				Соглашением.</p>
		</li>
		<li>

			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">14.5. По любым вопросам работы
				Сервиса, а также для направления юридически значимых сообщений, в том числе, претензий,
				Рекламодатель может обращаться к Администратору по адресу электронной почты <span
					style="letter-spacing: 0.2pt"><span><a href='mailto:info@atips.ru'
							target='_blank'>info@atips.ru</a>.</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Претензии должны быть
				обоснованными и подтверждаться доказательствами (документам).
			</p>
		</li>
		<li>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">14.6. Положения настоящего
				Соглашения применяются исходя из версии Сервиса и его функциональных возможностей.
			</p>
			<p align="justify" style="margin-top: 0.21cm; margin-bottom: 0cm">Если Сервисом не предусмотрены те
				или иные функциональные возможности, ссылки на которые имеются в Соглашении, положения таких
				пунктов не применяются Сторонами до момента появления соответствующих функциональных
				возможностей.
			</p>
		</li>
	</ol>
</li>
</ol>
`;
