const initialState = {
    token: "",
};

const AuthorizationReducer = (login = initialState, action) => {
    switch (action.type) {
        case "POST_LOGIN":
            return {...login, token: action.login.data.token };
        case "POST_LOGOUT":
            return initialState;
        case "POST_REGISTER":
            return initialState;
        default:
            return login;
    }
};

export const AuthUserReducer = (state = [], action) => {
    switch (action.type) {
        case "GET_AUTH_USER":
            return action.user.data[0];
        default:
            return state;
    }
};

export { AuthorizationReducer };