import {useBaseMutation} from "../../Actions/shared";
import {getSellerId} from "../constants";
import {Product} from "../types";
import {useGetPlatforms} from "../shared/useGetPlatforms";
import {useGetSeller} from "../seller";

type useCreateProductProps = {
	isProduct: boolean;
};
export const useCreateProduct = ({
	isProduct,
	...props
}: useCreateProductProps) => {
	const {atipsPlatform} = useGetPlatforms();

	const {mutation, responseData, error} = useBaseMutation<
		Product,
		unknown,
		any
	>({
		url: "/domain/products",
		...props,
	});

	const createProduct = (product) => {
		const newProduct = {
			...product,
			published: false,
			seller_id: Number(getSellerId()),
			platform_id: atipsPlatform?.id,
			section: `Категории ${isProduct ? "товаров" : "услуг"}`,
		};
		mutation.mutate(newProduct);
	};
	return {
		mutation,
		responseData,
		createProduct,
		isCreatingProduct: mutation.isLoading,
		createProductErr: error,
	};
};
