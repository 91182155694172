import axios from "axios";
import { ATIPS_SELLER_URL } from "../api/constants";

export const getBrightboxes = (arg) => {
	if (arg && !!arg.filters && arg.filters !== null) {
		for (let key in arg.filters) {
			// arg = { ...arg, [key]: JSON.stringify(arg.filters[key]) };
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}

	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/tip`, {
				params: {
					...arg,
				},
			})
			.then((brightboxes) => {
				dispatch({
					type: "GET_BRIGHTBOXES",
					brightboxes,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};