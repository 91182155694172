import {useBaseQuery, useBaseQueryProps} from "../../Actions/shared";
import {Options, Platform} from "../types";
import {findByKey} from "../../utils/helpers/index";

type useGetPlatformsResult = {
	platforms: Platform[];
	atipsPlatform: Platform;
};
export const useGetPlatforms = (
	props?: Partial<useBaseQueryProps>,
): useGetPlatformsResult => {
	const {data, ...query} = useBaseQuery<{data: Platform[]}>({
		url: "/platform",
		queryKey: "platforms",
		...props,
	});

	const platforms = data?.data || [];

	const getPlatformById = (platform_id: number): Platform => {
		return findByKey<Platform>({arr: platforms, key: "id", value: platform_id});
	};

	const getPlatformByProductLink = ({link}) => {
		if (Array.isArray(platforms)) {
			const productPlatform = platforms?.find((platform) => {
				return link?.includes(platform?.title?.toLowerCase()) && platform;
			});

			return productPlatform || platforms[0];
		}
	};
	return {
		platforms,
		atipsPlatform: platforms?.[0],
		getPlatformByProductLink,
		getPlatformById,
		...query,
	};
};

export const useGetFormats = (props?: useBaseQueryProps) => {
	const {data, ...query} = useBaseQuery<{data: Platform[]}>({
		url: "/format",
		queryKey: "formats",
		...props,
	});

	const formats = data?.data;

	return {
		formats: data?.data,
		...query,
	};
};
