import {
	QueryObserverBaseResult,
	QueryObserverOptions,
	MutationOptions,
} from "react-query";
import {useBaseQuery, useBaseMutation, getFrames} from "./shared";

export const getPlatforms = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult => {
	const query: any = useBaseQuery({
		url: "/platform",
		queryKey: "platforms",
		...props,
	});

	return {...query, platforms: query?.data?.data};
};

export const getProductPlatform = ({platforms, link}) => {
	if (Array.isArray(platforms)) {
		const productPlatform = platforms?.find((platform) => {
			return link?.includes(platform?.title?.toLowerCase()) && platform;
		});

		return productPlatform || platforms[0];
	}
};

export const getProductAttributesValue = ({colors, colorsID}) => {
	if (Array.isArray(colors)) {
		const productColors = colorsID?.map((colorID) => {
			return colors.find(({id, title}) => id === colorID && {id, title});
		});

		return productColors;
	}
};
export const getProduct = ({
	id,
	...props
}: QueryObserverOptions & {id: string}): QueryObserverBaseResult =>
	useBaseQuery({
		url: `/product/${id}`,
		queryKey: "product",
		...props,
	});

export const parseProductByUrl = (props: MutationOptions = {}) =>
	useBaseMutation({
		baseURLKey: "parse",
		url: "/parse",
		retry: 4,
		...props,
	});

export const createProductMutation = ({...rest}: MutationOptions = {}) => {
	const {mutation, responseData, error} = useBaseMutation({
		url: "/domain/products",
		...rest,
	});

	const createProduct = (product) => {
		mutation.mutate(product);
	};
	return {
		mutation,
		responseData,
		createProduct,
		isCreatingProduct: mutation.isLoading,
		createProductError: error,
	};
};

export const updateProductAdMutation = ({
	id,
	...rest
}: {id: any} & MutationOptions) => {
	const {mutation, responseData, error} = useBaseMutation({
		...rest,

		url: id ? `/domain/products/${id}` : "/domain/products",
		method: id ? "put" : "post",
		isApiKeyRequired: false,
	});
	const updateProduct = (data: any) => {
		mutation.mutate(data);
	};
	return {mutation, responseData, error, updateProduct, isSavingProduct: mutation?.isLoading};
};
export const deleteProductMutation = ({
	id,
	...rest
}: {id: any} & MutationOptions) => {
	const {mutation, responseData, error} = useBaseMutation({
		url: `/product/${id}`,
		method: "delete",
		...rest,
	});
	return {mutation, responseData, error};
};
