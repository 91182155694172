import {useCustomMutation} from "../useCustomMutation";

type Params = {email: string};

export const useForgotPassword = (props: any) => {
	const {onSuccess} = props || {};

	const {mutation, ...rest} = useCustomMutation({
		baseURLKey: "seller",
		url: `/auth/forgot-password`,
		method: "post",
		onSuccess: (res: any) => {
			onSuccess && onSuccess(res);
		},
		...props,
	});

	const sendEmailForResetPassword = ({email}: Params) => {
		mutation.mutate({email});
	};

	return {sendEmailForResetPassword, ...rest};
};

export const useResetPassword = (props: any) => {
	const {onSuccess} = props || {};

	const {mutation, ...rest} = useCustomMutation({
		baseURLKey: "seller",
		url: `/auth/reset-password`,
		method: "post",
		onSuccess: (res: any) => {
			onSuccess && onSuccess(res);
		},
		...props,
	});

	const resetPassword = ({
		token,
		password,
	}: {
		token: string;
		password: string;
	}) => {
		mutation.mutate({token, password});
	};

	return {resetPassword, ...rest};
};
