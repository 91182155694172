import {useNavigate} from "react-router-dom";
import {useQueryClient} from "react-query";
import {useCustomMutation, useBaseMutationProps} from "../useCustomMutation";

export const useLogout = ({
	onSettled,
	...props
}: useBaseMutationProps & {onSettled: () => void}) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const {mutation} = useCustomMutation({
		baseURLKey: "seller",
		url: "/auth/jwt/logout",
		onSuccess: () => {
			localStorage.clear();
			queryClient?.removeQueries();
			onSettled && onSettled();
		},
		onError: () => {
			localStorage.clear();
			queryClient?.removeQueries();
			onSettled && onSettled();
		},

		...props,
	});

	const logout = () => {
		mutation.mutate({});
	};

	return {logout};
};
