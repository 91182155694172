import {FC, useState} from "react";
import {Button} from "../buttons/Button";
import {useGetUser} from "../../api/user/index";
import useTimeoutFn from "../../utils/hooks/useTimeoutFn";
import {useRequestVerifyToken} from "../../api/auth/index";
import React from "react";

export type LabelProps = {};

export const VerifyEmailHint: FC<LabelProps> = (props) => {
	const {user} = useGetUser();

	const [isSuccess, setIsSuccess] = useState(false);
	const [isReady, cancel, resetSaveResult] = useTimeoutFn(() => {
		setIsSuccess(false);
	}, 2000);

	const {requestVerifyToken, isRequestVerifyTokenLoading} =
		useRequestVerifyToken({
			onSuccess: () => {
				setIsSuccess(true);
				resetSaveResult();
			},
		});

	const handleVerifyClick = () => {
		requestVerifyToken({email: user?.email});
	};

	return (
		<div className='email-veirify-hint'>
			<div className='email-veirify-hint__left'>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='white'
					{...props}
					xmlns='http://www.w3.org/2000/svg'>
					<g clipPath='url(#clip0_7197_40268)'>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37259 0 12 0C18.6274 0 24 5.37258 24 12ZM11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17ZM11 13V14H13V13L13 7V6H11V7L11 13Z'
							fill='inherit'
						/>
					</g>
					<defs>
						<clipPath id='clip0_7197_40268'>
							<rect width='24' height='24' fill='white' />
						</clipPath>
					</defs>
				</svg>
				<p>Email адрес не подтвержден</p>
			</div>

			<Button
				onClick={handleVerifyClick}
				text={isSuccess ? "Отправлено" : "Отправить письмо еще раз"}
				isLoading={isRequestVerifyTokenLoading}
				disabled={isRequestVerifyTokenLoading}
				size='xss'
				type='button'
			/>
		</div>
	);
};
