import axios from "axios";
import { removeEmpty } from "./ActionsScirpts";
import { ATIPS_ROOT_URL } from "../api/constants";

export const getStatistic = (arg) => {
	let id = null;
	if (arg.hasOwnProperty("id")) {
		id = arg.id;
		delete arg.id;
	}
	if (!!arg.filters && arg.filters !== null) {
		for (let key in arg.filters) {
			if (arg.filters[key] !== null) {
				arg = { ...arg, [key]: arg.filters[key] };
			}
		}
		delete arg.filters;
	}
	if (!!arg.sideFilters && arg.sideFilters !== null) {
		const clearSideFilters = removeEmpty(arg.sideFilters);
		for (let key in clearSideFilters) {
			if (clearSideFilters[key] !== null) {
				arg = { ...arg, [key]: clearSideFilters[key] };
			}
		}
		delete arg.sideFilters;
	}
	return async (dispatch) => {
		dispatch({
			type: "SHOW_STATISTIC_LOADER",
		});
		await axios
			.get(`${ATIPS_ROOT_URL}/domain/statistics/50/0${!!id ? "?atips=" + id : ""}`, {
				params: {
					...arg,
				},
			})
			.then((statistic) => {
				dispatch({
					type: "GET_STATISTIC",
					statistic,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_STATISTIC_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};