import * as React from "react";
import {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import "./styles/PaginationBlock.scss";
import {Button} from "../../../ui-kit/buttons/Button";

const PaginationBlock = ({
	page,
	total,
	setPage,
	fetchNextPage,
	searchParams,
	setSearchParams,
}) => {
	const isLastPage = page === total;

	const onPageChange = (pageNumber) => {
		setPage(pageNumber);
		searchParams && searchParams.set("page", pageNumber);
		setSearchParams && setSearchParams(searchParams);
		// setSearchParams && setSearchParams({page: pageNumber});
	};

	if (!total || total <= 1) return null;
	return (
		<>
			{/* {!isLastPage && (
				<Button
					text='Показать еще'
					variant='secondary'
					size='l'
					className='mh-auto'
					onClick={fetchNextPage}
					type='button'
				/>
			)} */}
			<footer className='section-pagination'>
				<div className='pages-counts'>
					<ul className='pages-list'>
						<li className='pages-list__item button button_left'>
							<button
								disabled={page == 1}
								className='button_left-arrow'
								onClick={() => onPageChange(page - 1)}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M15.4142 8.00015L14.7071 8.70726L11.4142 12.0002L14.7071 15.293L15.4142 16.0002L14 17.4144L13.2929 16.7073L9.29289 12.7073C8.90237 12.3167 8.90237 11.6836 9.29289 11.293L13.2929 7.29304L14 6.58594L15.4142 8.00015Z'
										fill='#212121'></path>
								</svg>
							</button>
						</li>
						{/* Данный вывод подточен только для тех случаев, когда кол-во страниц больше 2
                        не стал заморачиваться над логикой отображения страниц
                        *Временное решение*
                    */}
						{new Array(total).fill(1).map((countt, index) => {
							const count = index + 1;
							return (page - count >= 0 && page - count <= 2) ||
								(count - page >= 0 && count - page <= 2) ||
								count == 1 ||
								count == total ? (
								<li
									onClick={() => onPageChange(count)}
									className={`pages-list__item ${
										page == count ? "current" : ""
									}`}
									key={index}>
									{Number(count)}
								</li>
							) : page - count == 3 || count - page == 3 ? (
								<li className={`pages-list__item `} key={index}>
									...
								</li>
							) : null;
						})}
						<li className='pages-list__item button button_right'>
							<button
								disabled={isLastPage}
								className='button_right-arrow'
								onClick={() => onPageChange(page + 1)}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M8.58569 8.00015L9.2928 8.70726L12.5857 12.0002L9.2928 15.293L8.58569 16.0002L9.99991 17.4144L10.707 16.7073L14.707 12.7073C15.0975 12.3167 15.0975 11.6836 14.707 11.293L10.707 7.29304L9.99991 6.58594L8.58569 8.00015Z'
										fill='#212121'></path>
								</svg>
							</button>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
};

export default connect(null, null)(PaginationBlock);
