import React, {useContext} from "react";
import cn from "classnames";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./styles/Sidebar.scss";
import {useLogout} from "../../../api/auth/index";

import {AsideToggleContext} from "../../sellerArea/context/AsideToggleContext";
import {CardIcon, PersonIcon} from "../../../ui-kit/Icons/Icons";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import { LogoutProfileModal } from "../../sellerArea/ProfilePage/LogoutProfileModal";

const Sidebar = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isHomePage = location?.pathname === "/";
	const { handleModal } = useContext(ModalContext);
	const {toggle} = useContext(AsideToggleContext);
	// const {logout} = useLogout({
	// 	onSettled: () => {
	// 		navigate("/sign-in");
	// 	},
	// });
	const handleLogoutModal = () => {
		handleModal(
			<LogoutProfileModal/>
		);
	};

	return (
		<aside
			className={`aside_menu animte-menu ${toggle ? "closed" : ""}`}
			id='main-menu'>
			<div
				onClick={() => {
					!isHomePage && navigate("/");
				}}
				className={cn("inner-logo", {
					pointer: !isHomePage,
				})}
				id='inner-logo'>
				<div id='inner-logo-colors' className='animte-menu'>
					<div className='inner-logo-color animte-menu'>
						<img src='images/logo_color.svg' alt='color' />
					</div>
					<div className='inner-logo-color animte-menu'>
						<img src='images/logo_color.svg' alt='color' />
					</div>
					<div className='inner-logo-color animte-menu'>
						<img src='images/logo_color.svg' alt='color' />
					</div>
				</div>
				<img
					src='images/logo.svg'
					alt='logotype'
					id='logo-inner-image'
					className='animte-menu'
				/>
			</div>
			<ul className='inner-menu-list inner-menu'>
				<li
					className={`inner-menu-list__item ${
						location.pathname === "/" ? "active" : ""
					}`}>
					<Link to='/'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M11.4855 0.142507C11.8022 -0.0475024 12.1978 -0.0475023 12.5145 0.142507L22.5145 6.14251C22.8157 6.32323 23 6.64874 23 7L23 22C23 22.5523 22.5523 23 22 23H16H8H6.8H2C1.44772 23 1 22.5523 1 22L1 7C1 6.64874 1.1843 6.32323 1.4855 6.14251L11.4855 0.142507ZM12 2.16619L3 7.56619V21H6.8H7L7 19L7 16L7 12C7 11.4477 7.44772 11 8 11L16 11C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12V16V19V21H21L21 7.56619L12 2.16619ZM15 21V19L15 16V13L9 13V16V19V21H15Z'
								fill='#212121'
							/>
						</svg>
						<span className='animte-menu'>Главная</span>
					</Link>
				</li>
				<li
					className={`inner-menu-list__item ${
						location.pathname.includes("/product") ? "active" : ""
					}`}>
					<Link to='/products'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M11.5701 0.0971395C11.8421 -0.0323798 12.1579 -0.0323798 12.4299 0.0971395L23.4299 5.33524C23.7782 5.50106 24 5.85239 24 6.2381V17.7619C24 18.1476 23.7782 18.4989 23.4299 18.6648L12.4299 23.9029C12.1579 24.0324 11.8421 24.0324 11.5701 23.9029L0.570066 18.6648C0.221826 18.4989 0 18.1476 0 17.7619L0 6.2381C0 5.85239 0.221826 5.50106 0.570066 5.33524L11.5701 0.0971395ZM2 17.1305L2 8.88301L10.9998 13.296L10.9998 21.4161L2 17.1305ZM22 17.1305L12.9998 21.4163L12.9998 13.296L22 8.88282V17.1305ZM17.6448 4.79561L21.7784 6.76398L11.9998 11.5588L8.02069 9.60769L17.6448 4.79561ZM5.7628 8.50056L15.3542 3.70485L12 2.10759L2.22139 6.76407L5.7628 8.50056Z'
								fill='#212121'></path>
						</svg>
						<span className='animte-menu'>Товары</span>
					</Link>
				</li>
				<li
					className={`inner-menu-list__item ${
						location.pathname.includes("/service") ? "active" : ""
					}`}>
					<Link to='/services'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M0 5C0 4.44772 0.447715 4 1 4H12H14H16H23C23.5523 4 24 4.44772 24 5V9C24 9.55228 23.5523 10 23 10C22.7632 10 22.2278 10.1483 21.7497 10.5309C21.3032 10.8881 21 11.3717 21 12C21 12.6283 21.3032 13.1119 21.7497 13.4691C22.2278 13.8517 22.7632 14 23 14C23.5523 14 24 14.4477 24 15V19C24 19.5523 23.5523 20 23 20H16H14H12H1C0.447715 20 0 19.5523 0 19V15C0 14.4477 0.447715 14 1 14C1.23676 14 1.77215 13.8517 2.2503 13.4691C2.69679 13.1119 3 12.6283 3 12C3 11.3717 2.69679 10.8881 2.2503 10.5309C1.77215 10.1483 1.23676 10 1 10C0.447715 10 0 9.55228 0 9V5ZM14 6H12H2V8.16726C2.51279 8.32836 3.04037 8.60168 3.4997 8.96913C4.30321 9.61195 5 10.6283 5 12C5 13.3717 4.30321 14.3881 3.4997 15.0309C3.04037 15.3983 2.51279 15.6716 2 15.8327V18H12H14L14 17L14 16L16 16V17V18H22V15.8327C21.4872 15.6716 20.9596 15.3983 20.5003 15.0309C19.6968 14.3881 19 13.3717 19 12C19 10.6283 19.6968 9.61195 20.5003 8.96913C20.9596 8.60168 21.4872 8.32836 22 8.16726V6H16V7V8H14V7V6ZM16 10V11V13V14H14V13V11V10H16Z'
								fill='#212121'></path>
						</svg>
						<span className='animte-menu'>Услуги</span>
					</Link>
				</li>
				<li
					className={`inner-menu-list__item ${
						location.pathname === "/bloggers" ||
						location.pathname.includes("/blogger")
							? "active"
							: ""
					}`}>
					<Link to='/bloggers'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M12.0001 2C12.3807 2 12.7284 2.2161 12.8968 2.55743L15.6031 8.04091L21.6545 8.92022C22.0311 8.97496 22.3441 9.2388 22.4617 9.60081C22.5793 9.96283 22.4812 10.3602 22.2087 10.6259L17.8299 14.8942L18.8636 20.9211C18.9279 21.2963 18.7737 21.6755 18.4657 21.8992C18.1578 22.1229 17.7495 22.1524 17.4126 21.9753L12.0001 19.1298L6.58758 21.9753C6.25067 22.1524 5.8424 22.1229 5.53446 21.8992C5.22651 21.6755 5.07229 21.2963 5.13664 20.9211L6.17033 14.8942L1.79152 10.6259C1.51895 10.3602 1.42085 9.96283 1.53847 9.60081C1.6561 9.2388 1.96905 8.97496 2.34573 8.92022L8.3971 8.04091L11.1034 2.55743C11.2718 2.2161 11.6195 2 12.0001 2ZM12.0001 5.25955L9.95791 9.39748C9.81224 9.69262 9.53068 9.89719 9.20497 9.94452L4.63849 10.6081L7.94283 13.829C8.17851 14.0587 8.28606 14.3897 8.23042 14.7141L7.45037 19.2622L11.5348 17.1149C11.8261 16.9617 12.1741 16.9617 12.4654 17.1149L16.5498 19.2622L15.7698 14.7141C15.7141 14.3897 15.8217 14.0587 16.0574 13.829L19.3617 10.6081L14.7952 9.94452C14.4695 9.89719 14.1879 9.69262 14.0423 9.39748L12.0001 5.25955Z'
								fill='#212121'></path>
						</svg>
						<span className='animte-menu'>Блогеры</span>
					</Link>
				</li>
				<li
					className={`inner-menu-list__item ${
						location.pathname === "/statistic" ? "active" : ""
					}`}>
					<Link to='/statistic'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z'
								fill='#212121'></path>
						</svg>
						<span className='animte-menu'>Статистика</span>
					</Link>
				</li>
				<li
					className={`inner-menu-list__item ${
						location.pathname === "/payments-history" ? "active" : ""
					}`}>
					<Link to='/payments-history'>
						<CardIcon />
						<span className='animte-menu'>История платежей</span>
					</Link>
				</li>
				<li
					className={`inner-menu-list__item ${
						location.pathname === "/profile" ? "active" : ""
					}`}>
					<Link to='/profile'>
						<PersonIcon />
						<span className='animte-menu'>Профиль</span>
					</Link>
				</li>
			</ul>

			<button className='button_logout' onClick={handleLogoutModal}>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M11 1V11H13V1H11ZM3 12.0476C3 8.44058 5.14515 5.32707 8.24421 3.90936L7.41221 2.09064C3.63168 3.82009 1 7.62543 1 12.0476C1 18.1009 5.92937 23 12 23C18.0706 23 23 18.1009 23 12.0476C23 7.62543 20.3683 3.82009 16.5878 2.09064L15.7558 3.90936C18.8549 5.32707 21 8.44058 21 12.0476C21 16.9874 16.9751 21 12 21C7.02493 21 3 16.9874 3 12.0476Z'
						fill='#212121'
					/>
				</svg>
				<span className='animte-menu'>Выйти</span>
			</button>
		</aside>
	);
};

export default Sidebar;
