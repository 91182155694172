import {
	PlusIcon,
	PlusBigIcon,
	DeleteIcon,
	RefreshIcon,
	Edit2SmallIcon,
	CloseIcon,
	CloseSmallIcon,
	CloseMiddleIcon,
	CardIcon,
	FilterIcon,
	DropNextIcon,
	CommentDotsIcon,
	AttachIcon,
	SendIcon,
	DocTextIcon,
} from "./Icons";
import { createElement } from "react";
import { ArrowPrevIcon } from "./NavIcons";

export const IconsMap = {
	close: CloseIcon,
	close_small: CloseSmallIcon,
	close_middle: CloseMiddleIcon,
	plus: PlusIcon,
	plus_big: PlusBigIcon,
	delete: DeleteIcon,
	refresh: RefreshIcon,
	edit2_small: Edit2SmallIcon,
	card: CardIcon,
	arrow_prev: ArrowPrevIcon,
	filter: FilterIcon,
	drop_next: DropNextIcon,
	comment_dots: CommentDotsIcon,
	send: SendIcon,
	attach: AttachIcon,
	doc_text: DocTextIcon
};

export const createIcon = ({
	icon,
	svgProps = {},
}: {
	icon: keyof typeof IconsMap;
	svgProps?: any;
}) => createElement(IconsMap[icon], Object.assign(svgProps));
