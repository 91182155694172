import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { EmptyNotification, ErrorNotification } from "./notifications.js";
import {
	SelectedBlock,
	StyledClearButton,
	DropDownContainer,
	DropDownWrapper,
	DropDownBackDrop,
	ListWrapper,
	StyledInput,
	DropDownListItem,
	LoaderWrapper,
} from "./blocks.jsx";

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 300);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
};

const Search = ({
	results = [],
	value,
	onChange,
	onSelect,
	onDDClick,
	onClose,
	isOpen,
	onClear,
	selected,
	status,
	resolveTitle,
	placeholder = "Поиск по названию",
	loadNext,
	isDeletable = true,
}) => {
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const resultContainer = useRef(null);
	const [defaultValue, setDefaultValue] = useState("");

	const handleSelection = (selectedIndex) => {
		const selectedItem = results[selectedIndex];
		if (!selectedItem) return resetSearchComplete();
		onSelect && onSelect(selectedItem);
		resetSearchComplete();
	};

	const debouncedSearch = useDebounce(() => defaultValue, 300);

	useEffect(() => {
		onChange && onChange(debouncedSearch);
	}, [debouncedSearch]);

	const resetSearchComplete = useCallback(() => {
		setDefaultValue("");
		onClose();
	}, [defaultValue]);

	const handleChange = (e) => {
		const value = e.target.value;
		setDefaultValue(value);
	};

	useEffect(() => {
		if (!resultContainer.current) return;

		resultContainer.current.scrollIntoView({
			block: "center",
		});
	}, [focusedIndex]);

	useEffect(() => {
		if (value) setDefaultValue(value);
	}, [value]);

	const handleScrollChange = (e) => {
		const target = e.target;
		if (target.scrollHeight - target.clientHeight - target.scrollTop < 50) {
			loadNext();
		}
	};
	return (
		<DropDownContainer className="input-field">
			<SelectedBlock className="select-block multiselect" onClick={onDDClick}>
				{selected?.length ? selected : "Все"}
			</SelectedBlock>
			{isDeletable && (
				<StyledClearButton
					className={`${!!selected?.length > 0 ? "active" : ""}`}
					onClick={(e) => (e.stopPropagation(), e.preventDefault(), onClear())}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
							fill="#212121"
						></path>
					</svg>
				</StyledClearButton>
			)}
			{isOpen ? (
				<DropDownWrapper>
					<div style={{ position: "relative" }}>
						<StyledInput
							type="text"
							placeholder={placeholder}
							value={defaultValue}
							onChange={handleChange}
						/>
						{status.type === "load" && <LoaderWrapper />}
					</div>
					<DropDownBackDrop onClick={onClose} />
					<ListWrapper onScroll={handleScrollChange} tabIndex="1">
						{results?.length > 0 &&
							results.map((item, index) => {
								if (item.title !== selected)
									return (
										<DropDownListItem
											key={index}
											onClick={() => handleSelection(index)}
											ref={index === focusedIndex ? resultContainer : null}
										>
											{resolveTitle ? resolveTitle(item) : item.title}
										</DropDownListItem>
									);
							})}
						{status.type === "load" && results.length === 0 && (
							<EmptyNotification>Загрузка...</EmptyNotification>
						)}
						{results.length === 0 && status.type === "ready" && (
							<EmptyNotification>Ничего не найдено</EmptyNotification>
						)}
						{status.type === "error" && <ErrorNotification>{status.message}</ErrorNotification>}
					</ListWrapper>
				</DropDownWrapper>
			) : null}
		</DropDownContainer>
	);
};

export default Search;
