import cn from "classnames";
import React, {createElement, ComponentType, FC, useState} from "react";
import {useController} from "react-hook-form";

import {Input, InputProps} from "./Input";
import {Error, ErrorProps} from "./Error";
import {Label, LabelProps} from "./Label";
import {InputWithMask} from "./InputWithMask";

import {useToggle} from "../../utils/hooks/useToggle";
import {VerifyEmailHint} from "./VerifyEmailHint";

export type FieldProps = InputProps &
	LabelProps & {
		className?: string;
		fieldWrapClass?: string;
		size?: "1/4" | "1/3" | "1/2";
		component?: any;
		inputWrapClass?: string;
		isEmailNotVerifyHint?: boolean;

		afterInputChildren?: any;

		error?: ErrorProps["error"];
	};

export const Field: FC<FieldProps> = ({
	size,
	fieldWrapClass,
	className,
	isEmailNotVerifyHint,
	inputWrapClass,
	component,
	afterInputChildren,
	label,
	error,
	...rest
}) => {
	const [isPassVisible, toggleIsPassVisible] = useToggle();

	const baseClass = "field";
	const sizeClass = {
		"1/2": `${baseClass}--half`,
		"1/3": `${baseClass}--third`,
		"1/4": `${baseClass}--fourth`,
	};

	const isPassType = rest?.type === "password";

	const inputComponent =
		component ||
		{
			text: Input,
			tel: InputWithMask,
		}[rest?.type || "text"] ||
		Input;
	return (
		<div
			className={cn(baseClass, className, {
				[`${sizeClass[size]}`]: size,
			})}>
			<div className='field-wrap'>
				<Label label={label} {...rest} />

				<div className={cn("input-field", inputWrapClass)}>
					{createElement(
						inputComponent,
						Object.assign({
							id: rest.name,
							...rest,
							type: isPassType && isPassVisible ? "text" : rest?.type,
						}),
					)}
					{isEmailNotVerifyHint && <VerifyEmailHint />}

					{isPassType && (
						<button
							className={cn(`input-field-eye input-icon`, {
								"show-password": isPassVisible,
								"input-field-eye--open": isPassVisible,
							})}
							onClick={toggleIsPassVisible}
							type='button'
						/>
					)}
				</div>
				{afterInputChildren}
				{/* <Error error={fieldState?.error} /> */}
			</div>
		</div>
	);
};

{
	/* <div className={`form-field ${formFieldErrorState ? "error" : ""}`}>
						<div className='form-field-label'>Логин</div>
						<div className='input-field'>
							<input
								id='login-input'
								name='login'
								value={authState.login}
								onChange={(e) => handleAuthChange(e)}
							/>
						</div>
						<div className='form-field-error'>Проверьте правильность ввода</div>
					</div> */
}
