import {useCustomMutation} from "../useCustomMutation";

type Params = {username: string; password: string};

export const useLogin = (props: any) => {
	const {onSuccess} = props || {};

	const {mutation, ...rest} = useCustomMutation({
		baseURLKey: "seller",
		url: `/auth/jwt/login`,
		method: "post",
		...props,
	});

	const login = ({username, password}: Params) => {
		const formData = new FormData();
		formData.append("username", username);
		formData.append("password", password);
		mutation.mutate(formData);
	};

	return {login, ...rest};
};
