import * as React from "react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import newPreloaderJson from "../../components/sellerArea/new-preloader.json";
import cn from "classnames";

type LoaderProps = {
	isLoading?: boolean;
	className?: string;
};

export const Loader: React.FunctionComponent<LoaderProps> = ({className}) => {
	// if (!isLoading) return null;
	return (
		<div className={cn(`main-loader-wrapper active`, className)}>
			<Lottie
				loop
				animationData={newPreloaderJson}
				play
				style={{width: 50, height: 50}}
			/>
		</div>
	);
};
