import React from "react";
import { connect } from "react-redux";

const handleStatisticFilteredClick = (value, mainFilterState, setMainFilterState) => {
	if (
		mainFilterState?.order_by === value &&
		(mainFilterState?.reversed_ === false || mainFilterState?.reversed_ === true)
	) {
		setMainFilterState({
			...mainFilterState,
			order_by: value,
			reversed_: !mainFilterState.reversed_,
			page: 1,
		});
	} else {
		setMainFilterState({
			...mainFilterState,
			order_by: value,
			reversed_: false,
			page: 1,
		});
	}
};
const StatisticTitlesComponent = ({ activeColumnState, paramsState, setParamsState }) => {
	return (
		<div className="list-table-header stat-list">
			{Object.keys(activeColumnState).map((key, index) => (
				<div
					className={`list-table-header-element ${
						!activeColumnState[key]?.active ? "hidden" : ""
					} ${activeColumnState[key].sortable ? "" : "non-sort"}`}
					style={{ width: activeColumnState[key]?.width }}
					key={index}
					onClick={() =>
						activeColumnState[key].sortable &&
						handleStatisticFilteredClick(key, paramsState, setParamsState)
					}
				>
					<span>{activeColumnState[key].title}</span>
				</div>
			))}
		</div>
	);
};

export default connect(null, null)(StatisticTitlesComponent);
