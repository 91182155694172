import {useBaseMutation} from "../../Actions/shared";
import {ATIPS_IO} from "../constants";

type uploadBody = {
	file: string;
};
type uploadResponse = {
	filename: string;
};
type useUploadImgResult = {
	uploadImg: ({file}: uploadBody) => void;
	isUploadingImg: boolean;
	[prop: string]: any;
};

type useUploadImgProps = {
	onSuccess?: ({imgUrl}: {imgUrl: string}) => void;
	params?: string;
};
export const useUploadImg = ({
	onSuccess,
	params = 'width=300&height=300&crop=1',
	...props
}: useUploadImgProps = {}): useUploadImgResult => {
	const {mutation, ...rest} = useBaseMutation({
		url: `/uploads/upload.php?${params}`,
		baseURLKey: "atips_io",
		onSuccess: (res: any) => {
			const data: uploadResponse = res?.data;
			// @ts-ignore
			onSuccess && onSuccess({imgUrl: `${ATIPS_IO}${data?.filename}`});
		},
		isApiKeyRequired: false,
		...props,
	});

	const uploadImg = ({file}) => {
		const formData = new FormData();
		formData.append("file", file);
		mutation.mutate(formData);
	};
	return {
		uploadImg,
		isUploadingImg: mutation?.isLoading,
		...rest,
	};
};
