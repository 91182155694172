export const getFormData = (object) => {
	const formData = new FormData();
	Object.keys(object).forEach((key) => formData.append(key, object[key]));
	return formData;
};

export function buildFormData(formData, data, parentKey) {
	if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
		Object.keys(data).forEach((key) => {
			buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
		});
	} else {
		const value = data == null ? null : data;

		formData.append(parentKey, value);
	}
}

export function jsonToFormData(data) {
	const formData = new FormData();

	buildFormData(formData, data);

	return formData;
}

export function removeEmpty(obj) {
	return Object.fromEntries(
		Object.entries(obj).filter(([_, v]) => v !== null && v !== "" && v !== [])
	);
}