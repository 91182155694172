import axios from "axios";
import { ATIPS_ROOT_URL } from "../api/constants";

export const getVideoFormats = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_ROOT_URL}/format`)
			.then((videoFormat) => {
				dispatch({
					type: "GET_VIDEO_FORMATS",
					videoFormat,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};