import React, { useState, useEffect } from "react";
import { Button } from "../../../ui-kit/buttons/Button";

import "./styles/CookiesNotifications";

export const CookiesNotifications = ({ }) => {
	const [isOpen, setIsOpen] = useState(true);

	const handleClose = () => {
		document.cookie = "cookie=true;max-age=31536000";
		setIsOpen(false);
	};

	const getCookie = (name) => {
		let matches = document.cookie.match(
			new RegExp(
				"(?:^|; )" +
				name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
				"=([^;]*)",
			),
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	};

	useEffect(() => {
		if (getCookie("cookie")) {
			setIsOpen(false);
		}
	}, []);

	if (getCookie("cookie") || !isOpen) {
		return null;
	}
	return (
		<div className='home-page-cookies bg-100 row between middle'>
			<div className='home-page-cookies__title'>
				<p className='T14-list'>
					Оставаясь на сайте, вы соглашаетесь с использованием файлов cookie.
				</p>
			</div>

			<Button text='Хорошо' variant='primary' size='s' onClick={handleClose} />
		</div>
	);
};
