import Multiselect from "multiselect-react-dropdown";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {handleToggleOpenClick} from "../../../scripts/PersonalAreaScripts";
import {takeId} from "../../scripts";
import "./sideFiltersStyle.scss";

const SideCitiesFilters = ({citiesList, sideFilters, setSideFilters}) => {
	const [citiesState, setCitiesState] = useState([]);
	const [selectedValues, setSelectedValues] = useState([]);

	useEffect(() => {
		setCitiesState(citiesList);
	}, [citiesList]);

	useEffect(() => {
		// обнуление
		if (sideFilters.region === null) {
			setSelectedValues([]);
		}
	}, [sideFilters]);

	useEffect(() => {
		if (selectedValues.length > 0)
			setSideFilters({...sideFilters, region: takeId(selectedValues)});
	}, [selectedValues]);

	return (
		<div className='statistics-filter-items-block'>
			<div
				className='statistics-filter-items-block-title'
				onClick={(e) => handleToggleOpenClick(e)}>
				Город
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z'
						fill='#212121'></path>
				</svg>
			</div>
			<div className='statistics-filter-items-block-subitems'>
				<div className='flex-block flex-start' style={{zIndex: 4}}>
					<div className='form-field' style={{width: "100%"}}>
						<div className='input-field'>
							<Multiselect
								className='multiCustom side-filters_city'
								options={citiesState} // Options to display in the dropdown
								displayValue='title' // Property name to display in the dropdown options
								selectedValues={
									selectedValues?.length > 0 && selectedValues[0] !== null
										? selectedValues
										: ""
								} // Preselected value to persist in dropdown
								onSelect={(selectedList, selectedItem) =>
									setSelectedValues(selectedList)
								} // Function will trigger on select event
								onRemove={(selectedList, removeItem) =>
									setSelectedValues(selectedList)
								} // Function will trigger on remove event
								hidePlaceholder={true}
								avoidHighlightFirstOption={true}
								emptyRecordMsg='Нет доступных'
								customCloseIcon={
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z'
											fill='#212121'
										/>
									</svg>
								}
								placeholder='Выберите'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		citiesList: state.citiesList,
	};
};

// const mapDispatchToProps = {
// getCategories,
// };

export default connect(mapStateToProps, null)(SideCitiesFilters);
