import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { removeEmpty } from "../../../../Actions/ActionsScirpts";
import { getCategories } from "../../../../Actions/CategoriesActions";
import { getCities, getCountries } from "../../../../Actions/LocationActions";
import { getVideoGenres } from "../../../../Actions/videos-actions/VideosActions";
import { handleToggleOpenClick } from "../../scripts/PersonalAreaScripts";
import SideCatregoryFilters from "./SideFilters/SideCatregoryFilters.jsx";
import SideCitiesFilters from "./SideFilters/SideCitiesFilters.jsx";
import SideClickStatusFilters from "./SideFilters/SideClickStatusFilters.jsx";
import SideCountryFilters from "./SideFilters/SideCountryFilters.jsx";
import SideGenderFilters from "./SideFilters/SideGenderFilters.jsx";
import { initialSideFiltersState } from "./static";

const StatisticEditFilterSlide = ({
	status,
	setStatus,
	categoriesList,
	getCategories,
	getVideoGenres,
	genresList,
	citiesList,
	getCities,
	countriesList,
	getCountries,
	paramsState,
	setParamsState,
}) => {
	const [sideFilters, setSideFilters] = useState(initialSideFiltersState);
	useEffect(() => {
		if (categoriesList && status?.active && status?.name === "filter") {
			categoriesList.categories.length === 0 && getCategories();
			genresList.length === 0 && getVideoGenres();
			citiesList.length === 0 && getCities();
			countriesList.length === 0 && getCountries();
		}
	}, [status]);

	const clearFilters = () => {
		setSideFilters(initialSideFiltersState);
	};

	const applySideFilters = () => {
		const clearSideFilter = removeEmpty({ ...sideFilters });
		setParamsState({ ...paramsState, sideFilters: clearSideFilter });
	};
	return (
		<div
			id="statistics-filter"
			className={`show-statistic-filter ${
				status.active && status.name === "filter" ? "active" : ""
			}`}
		>
			<div className="statistics-filter-scroller">
				<div className="statistics-filter-title">Все фильтры</div>
				<div
					className="statistics-filter-close action-button"
					onClick={() => setStatus({ active: false, name: "" })}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="statistics-filter-items">
					<SideCatregoryFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					{/* <SideGenresFilters
						sideFilters={sideFilters}
						setSideFilters={setSideFilters}
					/> */}
					<SideGenderFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<div className="statistics-filter-items-block">
						<div
							className="statistics-filter-items-block-title"
							onClick={(e) => handleToggleOpenClick(e)}
						>
							Возраст
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8.00015 9.58569L8.70726 10.2928L12.0002 13.5857L15.293 10.2928L16.0002 9.58569L17.4144 10.9999L16.7073 11.707L12.7073 15.707C12.3167 16.0975 11.6836 16.0975 11.293 15.707L7.29304 11.707L6.58594 10.9999L8.00015 9.58569Z"
									fill="#212121"
								></path>
							</svg>
						</div>
						{/* <div className='statistics-filter-items-block-subitems'>
							<div className='flex-block flex-start'>
								<div className='form-field' style={{width: "147px"}}>
									<div className='form-field-label'>От</div>
									<div className='input-field'>
										<input value='' />
									</div>
								</div>
								<div className='form-field' style={{width: "147px"}}>
									<div className='form-field-label'>До</div>
									<div className='input-field'>
										<input value='' />
									</div>
								</div>
							</div>
						</div> */}
					</div>
					<SideCountryFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideCitiesFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
					<SideClickStatusFilters sideFilters={sideFilters} setSideFilters={setSideFilters} />
				</div>
			</div>
			<div className="statistics-buttons">
				<div
					className="button action-button border-button"
					style={{ width: "auto" }}
					onClick={() => clearFilters()}
				>
					<span style={{ whiteSpace: "nowrap" }}>Очистить все</span>
				</div>
				<div className="button" onClick={() => applySideFilters()}>
					<span>Применить</span>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
		categoriesList: state.categoriesList,
		genresList: state.videoGenresList,
		citiesList: state.citiesList,
		countriesList: state.countriesList,
	};
};

const mapDispatchToProps = {
	getCategories,
	getVideoGenres,
	getCities,
	getCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticEditFilterSlide);
