import {useBaseMutation} from "../../Actions/shared";
import {User} from "../types";
import {useGetUser} from "../user/index";
import {useUploadImg} from "./useUploadImg";

type useSendFeedbackProps = {
	onSuccess?: () => void;
};
export const useSendFeedback = (props?: any) => {
	let textValue;
	const {user} = useGetUser();

	const {mutation} = useBaseMutation<User, unknown, any>({
		baseURLKey: "atips_io",
		url: `/marketmessage/`,
		method: "post",
		isApiKeyRequired: false,
		...props,
	});

	const sendFeedbackMutation = (data?: {text: string; image?: string}) => {
		mutation.mutate({
			id: localStorage.getItem("seller_id"),
			title: user?.name,
			...data,
		});
	};
	const {uploadImg, isUploadingImg} = useUploadImg({
		onSuccess: ({imgUrl}) => {
			sendFeedbackMutation({image: imgUrl, text: textValue});
		},
		params: ""
	});

	const sendFeedback = ({file, text}: {text: string; file?: string}) => {
		textValue = text;
		file ? uploadImg({file}) : sendFeedbackMutation({text});
	};

	return {
		sendFeedback,
		isSendingFeedback: mutation?.isLoading || isUploadingImg,
	};
};
