export const takeId = (elem) => {
    let iter = [];
    elem.map((item) => {
        iter.push(item && item.id);
    });
    return iter;
};


export const formatDate = (value) => {
        const currentDate = new Date(value);
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        return `${day}.${String(month).length === 1 ? `0${month}` : month}.${year}, ${
		hours < 10 ? "0" + hours : hours
	}:${minutes < 10 ? "0" + minutes : minutes}`;
};