import React, { useState, useEffect, useContext } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useRegistermutation } from "../../Actions/AuthorizationActions";
import "./styles/Authorization.scss";
import "./styles/Registration";
import { useNavigate } from "react-router-dom";

import { Button } from "../../ui-kit/buttons/Button";
import { Field } from "../../ui-kit/forms/Field";
import { InputWithMask } from "../../ui-kit/forms/InputWithMask";
import { Error } from "../../ui-kit/forms/Error";
import { Checkbox } from "../../ui-kit/forms/Checkbox";
import { useRequestVerifyToken } from "../../api/auth/index";
import { useToggle } from "../../utils/hooks/useToggle";
import { useTinkoffAuthUrlQuery } from "../../api/auth/useTinkoffAuthMutations";
import { useCreateSeller } from "../../api/seller/index";
import { useGetCountries, useGetPlatforms } from "../../api/shared/index";
import { useRegisterWithInn } from "./api/useRegisterWithInn";
import { Select } from "../../ui-kit/forms/Select";
import { DropdownCitySearch } from "../commons/DropdownCitySearch.jsx";
import { SuccessRegisterModal } from "./SuccessRegisterModal.jsx";
import { ModalContext } from "../modal/ModalContext/ModalContext";
import { render } from "react-dom";

const Authorization = () => {
	let navigate = useNavigate();
	const { handleModal } = useContext(ModalContext);

	const { register, handleSubmit, control, reset, setValue, watch } = useForm({
		mode: "onChange",
	});
	const { isValid } = useFormState({
		control,
	});
	const INN = watch("inn");
	const values = watch();

	const { createSeller } = useCreateSeller();
	const { atipsPlatform } = useGetPlatforms();

	const [isTinkQueryEnabled, toggleIsTinkQueryEnabled] = useToggle();
	const tinkoffAuthUrlQuery = useTinkoffAuthUrlQuery({
		enabled: isTinkQueryEnabled,
	});

	const [registerError, setRegisterError] = useState(false);
	const [innError, setInnError] = useState(false);

	const [currentStep, setStep] = useState(1);
	const [isSubmitBtn, setIsSubmitBtn] = useState(false);

	const isFirstStep = currentStep === 1;
	const isSecondStep = currentStep === 2;
	const isLastStep = currentStep === 3;
	let error_status = "";

	const { requestVerifyToken } = useRequestVerifyToken();

	const { countries = [] } = useGetCountries();

	const mutationRegister = useRegistermutation({
		onSuccess: (res) => {
			const { data } = res || {};
			const seller = {
				title: data.name,
				blocked: true,
				external_id: data.id,
				platforms: [
					{
						platform_id: atipsPlatform?.id,
						price: atipsPlatform?.default_price,
					},
				],
				city: values?.city?.title || "Москва",
				city_id: values?.city?.id || 368,
				country: values?.country?.title || countries[0]?.title,
				country_id: values?.country?.id || countries[0]?.id,
				inn: values.inn,
			};

			createSeller.mutate(seller);
			requestVerifyToken({ email: data.email });
			handleModal(<SuccessRegisterModal />);
			navigate("/sign-in");
		},
		onError: (data) => {
			console.log(data.response.data.detail);
			if (data?.response?.data?.detail && data.response.data.detail === "USER_EMAIL_ALREADY_EXISTS")
				setRegisterError({
					msg: "Данный Email уже используется",
				});
			else
				setRegisterError({
					msg: "Произошла ошибка при сохранении данных",
				});
		},
	});

	const [loadedData, setLoadedData] = useState();

	const miutationINN = useRegisterWithInn({
		onSuccess: (res) => {
			if (res.inn === undefined) {
				setInnError(true);
			} else {
				setInnError(false);
				setLoadedData(res);
				setValue("name", res?.name);
				setValue("inn", res?.inn);
				setValue("ogrn", res?.ogrn);
				setValue("legal_address", res?.legal_address);
				setValue("actual_address", res?.actual_address);
				setValue("ceo_name", res?.ceo_name);
				setValue("contact_name", res?.contact_name);
				setValue("kpp", res?.kpp);
			}

			// reset(res);
		},
	});

	const onSubmit = (data) => {
		const { password_repeat, agreement_offer, agreement_privacy, ...registerData } = data;
		if (registerData.password !== password_repeat) {
			setRegisterError({
				msg: "Неверно введен повтор пароля",
			});
		} else {
			mutationRegister.mutate({
				...registerData,
				contract_offer_accepted: true,
				// ...loadedData,
			});
		}
	};

	const fillFormByINN = () => {
		miutationINN.mutate({
			query: INN,
		});
	};

	useEffect(() => {
		setValue("city", { title: "Москва", id: 368 });
	}, []);

	const isFirstStepFilled =
		INN &&
		(values?.ogrn.length === 13 || values?.ogrn.length === 15) &&
		values?.name &&
		values?.legal_address &&
		values?.actual_address &&
		values?.ceo_name &&
		values?.contact_name &&
		values?.contact_phone_number?.length === 14;

	const isSecondStepFilled = values?.password == values?.password_repeat;

	const regSteps = {
		step1: [
			{
				label: "ОГРН или ОГРНИП*",
				name: "ogrn",
				mask: "999999999999999",
				component: InputWithMask,
				minLength: 13,
				maxLength: 15,
				// minLength: 15,
				// disabled: true,
			},
			{
				label: "Название организации*",
				name: "name",
				// disabled: true,
			},
			{
				label: "Юридический адрес*",
				name: "legal_address",
			},
			{
				label: "Фактический адрес*",
				name: "actual_address",
			},
			{
				label: "ФИО гендиректора или представителя по доверенности*",
				name: "ceo_name",
			},
			{
				label: "ФИО контактного лица*",
				name: "contact_name",
			},
			{
				label: "Телефон контактного лица*",
				name: "contact_phone_number",
				component: InputWithMask,
				minLength: 14,
				mask: "+7\\ 999 9999999",
				placeholder: "+7",
				maskPlaceholder: "+7",
			},
		],
		step2: [
			{
				label: "Email для входа*",
				name: "email",
			},
			{
				label: "Придумайте пароль*",
				name: "password",
				type: "password",
			},
			{
				label: "Повторите пароль*",
				name: "password_repeat",
				type: "password",
			},
		],
		step3: [
			{
				label: "ИНН*",
				name: "bank_inn",
				mask: "9999999999",
				component: InputWithMask,
				minLength: 10,
				required: true,
			},
			{
				label: "КПП",
				name: "kpp",
				mask: "999999999",
				component: InputWithMask,
				minLength: 9,
				required: false,
			},
			{
				label: "Расчетный счет*",
				name: "checking_account",
				size: "1/2",
				mask: "99999999999999999999",
				component: InputWithMask,
				minLength: 20,
				required: true,
			},
			{
				label: "Банк*",
				name: "bank_name",
				size: "1/2",
				required: true,
			},
			{
				label: "Корреспондентский счет*",
				name: "correspondent_account",
				size: "1/2",
				mask: "99999999999999999999",
				component: InputWithMask,
				minLength: 20,
				required: true,
			},
			{
				label: "БИК*",
				name: "bik",
				size: "1/2",
				mask: "999999999",
				component: InputWithMask,
				minLength: 9,
				required: true,
			},
		],
		subtitle: {
			1: "Контактные данные",
			2: "Аккаунт",
			3: "Банковские реквизиты",
		},
	};
	let externalFields = [
		{
			name: "country",
			label: "Страна",
			component: Select,
			options: countries,
			value: countries[0],
		},
		{
			name: "city",
			label: "Город",
		},
	];
	// useEffect(() => {
	// 	const isCurrentStepAlreadyFilled = filledSteps.find(
	// 		(number) => number === currentStep,
	// 	);
	// 	if (isCurrentStepAlreadyFilled && !isValid) {
	// 		setFilledSteps(filledSteps.filter((step) => step === currentStep));
	// 	} else if (!isCurrentStepAlreadyFilled && isValid) {
	// 		setFilledSteps([...filledSteps, currentStep]);
	// 	}
	// }, [isValid]);
	return (
		<section className="auth" onKeyPress={(e) => e.key === "Enter" && handleSubmit(onSubmit)}>
			<div style={{ display: "none" }} id="commit-hash">
				{`COMMIT HASH: ${process.env.COMMIT_HASH}`}
			</div>
			<form onSubmit={handleSubmit(onSubmit)} id="reg-form" className="auth-form reg-form">
				<img src="images/logo.svg" alt="" id="logo" />
				<h4 className="title">Регистрация</h4>
				{/* <Button
					text='Зарегистрироваться c'
					size='l'
					variant='tinkoff'
					className='mh-auto'
					onClick={toggleIsTinkQueryEnabled}
				/>
				<div className='auth-form__or-dividor cross-line'>
					<span>или</span>
				</div> */}
				<div className="reg-form__steps cross-line">
					{[1, 2, 3].map((x, i) => (
						<div
							className={cn("reg-form__steps-item", {
								"reg-form__steps-item--active": x === currentStep,
								"reg-form__steps-item--passed": x < currentStep || (currentStep === 3 && isValid),
							})}
							key={i}>
							{x}
						</div>
					))}
				</div>
				{currentStep === 1 && (
					<>
						<h5 className="subtitle">Местность регистрации</h5>
						<div className="row cGap-16 reg-form__field-inn bottom">
							{externalFields.map(({ isHidden, ...rest }, i) => {
								if (isHidden) return null;
								if (rest.name === "city") {
									return (
										<div className="field">
											<div className="field-wrap">
												<label htmlFor="city" className="label flex justify-justify ">
													Город
												</label>
												<DropdownCitySearch
													isDeletable={false}
													selected={values?.city?.title}
													onSelect={(newValue) => setValue("city", newValue)}
												/>
											</div>
										</div>
									);
								}
								return (
									<Field
										value={values[rest?.name]}
										{...rest}
										key={i}
										inputActions={{
											register,
											control,
											setValue,
										}}
									/>
								);
							})}
						</div>
					</>
				)}
				<h5 className="subtitle">{regSteps.subtitle[currentStep]}</h5>
				{currentStep === 1 && (
					<div
						id="register-inn"
						className={"row cGap-16 reg-form__field-inn bottom " + (innError ? "error-field" : "")}>
						<Field
							component={InputWithMask}
							minLength={10}
							maxLength={12}
							mask="999999999999"
							// type="number"
							name="inn"
							// required
							inputActions={{ register, control }}
							className="flex1"
							label="ИНН*"
						/>
						<Button
							disabled={!INN}
							onClick={fillFormByINN}
							variant="secondary"
							text="Загрузить из ИНН"
							size="s"
							type="button"
							className="mb-24"
							isLoading={miutationINN?.isLoading}
						/>
					</div>
				)}
				<div className="fields-container">
					{currentStep === 1 &&
						regSteps.step1.map((field, i) => {
							return <Field {...field} required inputActions={{ register, control }} key={i} />;
						})}
					{currentStep === 2 &&
						regSteps.step2.map((field, i) => {
							return <Field {...field} required inputActions={{ register, control }} key={i} />;
						})}
					{currentStep === 3 &&
						regSteps.step3.map((field, i) => {
							return (
								<Field
									{...field}
									required={field.required}
									inputActions={{ register, control }}
									key={i}
								/>
							);
						})}
				</div>
				<div className="reg-form__btn-group">
					{registerError && <Error error={registerError} errorType={registerError?.msg} />}
					{currentStep > 1 && (
						<Button
							text="Назад"
							variant="secondary"
							onClick={() => {
								setIsSubmitBtn(false);
								setStep(currentStep - 1);
							}}
							size="l"
						/>
					)}
					<Button
						disabled={
							isFirstStep ? !isFirstStepFilled : !isValid || (isSecondStep && !isSecondStepFilled)
						}
						text={isLastStep ? "Зарегистрироваться" : "Продолжить"}
						type={isSubmitBtn ? "submit" : "button"}
						onClick={() => {
							if (isLastStep) {
								setIsSubmitBtn(true);
							} else {
								setStep(currentStep + 1);
								setIsSubmitBtn(false);
							}
						}}
						size="l"
						variant="purple"
					/>
				</div>

				{isLastStep && (
					<div className="mt-24 mb-38">
						<Checkbox
							label={
								<>
									Я согласен с условиями{" "}
									<Link target="_blank" to="/privacy">
										политики конфиденциальности
									</Link>
								</>
							}
							name="agreement_privacy"
							inputActions={{ register, control }}
							className="reg-form__agree-checkbox mb-10"
							required
						/>
						<Checkbox
							label={
								<>
									Я принимаю условия{" "}
									<Link target="_blank" to="/legal">
										пользовательского соглашения — оферты
									</Link>
								</>
							}
							name="agreement_offer"
							inputActions={{ register, control }}
							className="reg-form__agree-checkbox"
							required
						/>
					</div>
				)}
				<p className="auth-form__reg-hint">
					Уже есть профиль в Atips? Тогда
					<Link to="/sign-in"> Войдите</Link>
				</p>
			</form>
		</section>
	);
};

export default Authorization;
// const mapStateToProps = (state) => {
// 	return {
// 		login: state.login,
// 	};
// };

// const mapDispatchToProps = {
// 	postLogin,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
// disabled={!isValid}
