import {useCustomQuery} from "../useCustomQuery";

type Params = {email: string};

export const useTinkoffAuthUrlQuery = (props: {enabled?: boolean}) => {
	const query = useCustomQuery({
		baseURLKey: "seller",
		url: "/tinkoff-oauth/authorize",
		queryKey: "tinkoff-auth-url",
		onSuccess: (data: any) => {
			const authUrl = data?.data?.authorization_url;
			if (typeof window !== undefined && authUrl) {
				const win = window.open(authUrl, "_blank");
				authUrl && win?.focus();
			}
		},
		...props,
	});

	return query;
};

export type TinkoffCallbackProps = {
	state?: string | null;
	code?: string | null;
} | null;

export const useTinkoffAuthCallback = (
	props: TinkoffCallbackProps & {onSuccess: () => void},
) => {
	const {code, state, onSuccess} = props || {};

	const query = useCustomQuery({
		baseURLKey: "seller",
		url: `/tinkoff-oauth/callback?code=${code}&state=${state}`,
		enabled: Boolean(state) && Boolean(code),
		onSuccess: (res: any) => {
			// сервер возвращает  access_token и user_id
			const accessToken = res?.["access_token"];
			const user_id = res?.["user_id"];
			accessToken && localStorage.setItem("token", accessToken);
			user_id && localStorage.setItem("userid", user_id);
			localStorage.setItem("refresh_token", res?.["refresh_token"]);

			onSuccess();
		},
		...props,
	});
};
