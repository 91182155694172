import {useCustomMutation} from "../useCustomMutation";

export const useRequestVerifyToken = (props: any) => {
	const {mutation} = useCustomMutation({
		baseURLKey: "seller",
		url: "/auth/request-verify-token",
		...props,
	});

	const requestVerifyToken = (data: {email: string}) => {
		mutation.mutate(data);
	};

	return {requestVerifyToken, isRequestVerifyTokenLoading: mutation?.isLoading};
};

export const useVerifyEmail = (props: any) => {
	const {mutation} = useCustomMutation({
		baseURLKey: "seller",
		url: "/auth/verify",
		...props,
	});

	const verifyEmail = (data: {token: string}) => {
		mutation.mutate(data);
	};

	return {verifyEmail};
};
