import {useMutation, MutationOptions, UseMutationResult} from "react-query";
import React, {useState} from "react";
import {useAxios, useAxiosProps } from "./useAxios";

export type useBaseMutationProps = MutationOptions & useAxiosProps;

export const useCustomMutation = <
	Response = unknown,
	Error = unknown,
	Params = unknown,
>({
	baseURLKey,
	url,
	method = "post",
	onSuccess,
	onError,
	...rest
}: useBaseMutationProps & {[prop: string]: any}) => {
	const [error, setError] = useState();
	const [responseData, setResponseData] = useState(null);

	const mutation = useMutation(
		(data: any) =>
			useAxios({data, baseURLKey, url, method}),
		{
			...rest,
			onSuccess: (res: any, y, z) => {
				onSuccess && onSuccess(res, y, z);
				setResponseData(res?.data);
				setError(null);
			},
			onError: (res: any, y, z) => {
				console.error("ON ERROR", {...res}, y, z);
				onError && onError(res, y, z);
				setError(res);
			},
		},
	) as UseMutationResult<Response, Error, Params, unknown>;
	return {mutation, error, responseData};
};
