import axios from "axios";
import { ATIPS_ROOT_URL } from "../api/constants";

export const getSellers = (arg) => {
	if (arg && !!arg.filters && arg.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if (arg && !arg.limit) {
		arg = { ...arg, limit: 1000 };
	}
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${ATIPS_ROOT_URL}/seller`, {
				params: {
					...arg,
				},
			})
			.then((sellers) => {
				dispatch({
					type: "GET_SELLERS",
					sellers,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};