import { useBaseQuery, useBaseQueryProps } from "../../Actions/shared";
import { RUSSIA_ID } from "../constants";

type City = {
	alias: string;
	frame_id: number;
	id: number;
	parent_id: number;
	statistic_id: number;
	title: string;
	type_: string;
};


type useGetCitiesResult = {
	countries: {
		alias: string;
		attributes: City[];
		id: number;
		title: string;
	};
	[prop: string]: any;
};
export const useGetCountries = (): useGetCitiesResult => {
	const { data, isLoading, ...query } = useBaseQuery<{ data: any }>({
		url: `/frame/11`,
		queryKey: ["countries"],
	});


	return {
		// @ts-ignore
		countries: data?.data?.attributes?.filter(
			// @ts-ignore
			(country) => country?.id === RUSSIA_ID,
		),
	};
};
