export * from "./useGetProduct";
export * from "./useSaveProduct";
export * from "./useCreateProduct";

import { AttributesSettable, FrameAliasEnum } from "../types";
import { FRAMES_ID } from "../constants";

import { isArrEmpty } from "../../utils/helpers/index";

export type groupProductAttrsResult = {
	// [key in FrameAliasEnum]: AttributesSettable[] | AttributesSettable;
	color: AttributesSettable[];
	sizes: AttributesSettable[];
	ages: AttributesSettable[];
	gender: AttributesSettable;
	region: AttributesSettable;
	country: AttributesSettable;
	city: AttributesSettable;
};

export const groupProductAttrs = (
	productAttributes: AttributesSettable[],
): groupProductAttrsResult => {
	const filterAttrs = (alias: keyof typeof FrameAliasEnum) =>
		!isArrEmpty(productAttributes) &&
		productAttributes.filter((atrr) => atrr?.frame_id === FRAMES_ID[alias]);
	let attributes = {
		color: filterAttrs("colors")?.length === 0 ? null : filterAttrs("colors"),
		sizes: filterAttrs("sizes")?.length === 0 ? null : filterAttrs("sizes"),
		ages: filterAttrs("ages"),
		gender: filterAttrs("gender")?.[0],
		region: filterAttrs("region")?.[0],
		regions: filterAttrs("regions"),
		country: filterAttrs("country")?.[0],
		city: filterAttrs("city")?.[0],
		cities: filterAttrs("cities"),
	};
	//@ts-ignore
	return attributes;
};
