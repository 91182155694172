import React from "react";
import "./styles/PolicyPage.scss";
import {AtipsMarketFullLogo} from "../../../public/images/logo";
import {PRIVACY_POLICY} from "../../utils/constants/policies";

const PrivacyPage = () => {
	return (
		<div className='policy-page'>
			<div className='policy-page__header'>
				<AtipsMarketFullLogo />
			</div>

			<div className='policy-page__content'>
				<div className='policy-page__title'>Политика конфиденциальности</div>
				<div
					style={{
						fontSize: "14px",
						lineHeight: "22px",
					}}
					dangerouslySetInnerHTML={{
						__html: PRIVACY_POLICY,
					}}
				/>
			</div>
		</div>
	);
};

export default PrivacyPage;
