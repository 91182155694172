export const numberWithSpaces = (x: number | string) => {
	return Number(x)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const isArr = (item: any) => Array.isArray(item);
export const isArrEmpty = (item: any) => isArr(item) && item?.length === 0;

export const findByKey = <Element extends unknown>({
	arr,
	key,
	value,
}: {
	arr: Element[];
	key: string;
	value: any;
}): Element => {
	if (isArr(arr) && key && value)
		return arr?.find((item) => item[key] === value);
};

export const urlify = ({text}: {text: string}) => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;

	return text?.split(urlRegex).map((part) => {
		if (part.match(urlRegex)) {
			return (
				// @ts-ignore
				<a href={part} target='__blank'>
					{part}
				</a>
			);
		}
		return part;
	});
};

export const sortArrByAlphabet = ({
	arr = [],
	key = "title",
}: {
	arr: any[];
	key?: string;
}) => {
	return (
		isArr(arr) &&
		arr?.sort((a, b) => {
			if (a[key].toLowerCase() < b[key].toLowerCase()) {
				return -1;
			}
			if (a[key].toLowerCase() > b[key].toLowerCase()) {
				return 1;
			}
			return 0;
		})
	);
};
