import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";

const DropDownSaveInfo = ({ saveMoment }) => {
	const [saveFlag, setSaveFlag] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		setSaveFlag(saveMoment);
		if (saveMoment) {
			setTimeout(() => {
				dispatch({ type: "HIDE_SAVE_INFO" });
			}, 3000);
		}
	}, [saveMoment]);

	return (
		<div className={`save-info-block ${saveFlag ? "active" : ""}`}>
			<span>Сохранено</span>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		saveMoment: state.saveMoment,
	};
};

export default connect(mapStateToProps, null)(DropDownSaveInfo);
