import {
	QueryObserverOptions,
	useQuery,
	QueryObserverBaseResult,
} from "react-query";
import {useAxios, useAxiosProps} from "./useAxios";

export type useCustomQueryProps = QueryObserverOptions &
	useAxiosProps & {options?: QueryObserverOptions};

export const useCustomQuery = <TData = unknown, TError = unknown>({
	baseURLKey,
	url,
	queryKey = "",
	options = {},
	...rest
}: useCustomQueryProps) => {
	const query = useQuery(
		queryKey,
		(data) => useAxios({data, baseURLKey, url, method: "get"}),
		{
			notifyOnChangeProps: "tracked",
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			...options,
			...rest,
		},
	);
	return query as QueryObserverBaseResult<TData, TError>;
};
