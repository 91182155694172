import axios from "axios";
import { ATIPS_SELLER_URL } from "../api/constants";

export const getAttributes = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(
				`${ATIPS_ROOT_URL}/domain/frames/attributeslist?frame_ids=1&frame_ids=2&frame_ids=15&frame_ids=16&frame_ids=17&frame_ids=18&frame_ids=19&frame_ids=20&frame_ids=21&frame_ids=22&frame_ids=23&frame_ids=24&frame_ids=25&frame_ids=26&frame_ids=27&frame_ids=28&frame_ids=29&frame_ids=30&frame_ids=31&frame_ids=32&frame_ids=33&frame_ids=34&frame_ids=35&frame_ids=37`
			)
			.then((attr) => {
				dispatch({
					type: "GET_ATTRIBUTES",
					attr,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCategories = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${ATIPS_SELLER_URL}/category`)
			.then((categor) => {
				dispatch({
					type: "GET_CATEGORIES",
					categor,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
