import axios from "axios";
import {ATIPS_SELLER_URL} from "../api/constants";
import {useBaseQuery} from "./shared";
import {
	QueryObserverBaseResult,
	QueryObserverOptions,
} from "react-query";

export const getCitiesQuery = (
	props?: QueryObserverOptions,
): QueryObserverBaseResult =>
	useBaseQuery({
		url: "/city?skip=0",
		queryKey: "cities",
		...props,
	});

export const getCities = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/city`)
			.then((cities) => {
				dispatch({
					type: "GET_CITIES",
					cities,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCountries = () => {
	return async (dispatch) => {
		await axios
			.get(`${ATIPS_SELLER_URL}/region`)
			.then((countries) => {
				dispatch({
					type: "GET_COUNTRIES",
					countries,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
