import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { removeEmpty } from "../../../Actions/ActionsScirpts.js";
import { getStatistic } from "../../../Actions/StatisticActions.js";
import StatisticEditColumnSlide from "./SlidesMenu/StatisticEditColumnSlide.jsx";
import StatisticEditFilterSlide from "./SlidesMenu/StatisticEditFilterSlide.jsx";
import StatisticContent from "./StatisticContent/StatisticContent.jsx";
import StatisticFiltersComponent from "./StatisticFiltersComponent.jsx";
import {
	initialColumnState,
	initialSellerState,
	initialVideoState,
	initialViewerState,
} from "./statisticStatic.js";
import PaginationBlock from "../../universal/PaginationBlock/PaginationBlock.jsx";
import StatisticTitlesComponent from "./StatisticTitlesComponent/StatisticTitlesComponent.jsx";
import "./styles/StatisticPage.scss";
import { getBrightboxes } from "../../../Actions/BrightboxesActions.js";
import { formatDate } from "./scripts";
import { history } from "../../../Store/Store.ts";
import { useLazyQuery } from "@apollo/client";
import { GET_STATISTIC } from "../../../api/statistic/getStatistiqQuery";

import { Loader } from "../../../ui-kit/loader/Loader";
import { getSellerId } from "../../../api/constants";
import { numberWithSpaces } from "../../../utils/helpers/index";

export const normalizeFilters = (arg) => {
	if (!!arg.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			if (arg.filters[key] !== null) {
				arg = { ...arg, [key]: arg.filters[key] };
			}
		}
		delete arg.filters;
	}
	if (!!arg.sideFilters && arg.sideFilters !== null) {
		const clearSideFilters = arg.sideFilters;
		for (let key in clearSideFilters) {
			if (clearSideFilters[key] !== null) {
				arg = { ...arg, [key]: clearSideFilters[key] };
			}
		}
		delete arg.sideFilters;
	}
	if (!("from_datetime" in arg) || !("to_datetime" in arg)) {
		arg["from_datetime"] = "2021-01-01T00:00:00+00:00";
		arg["to_datetime"] = new Date().toISOString();
	}
	if (!("is_click" in arg)) arg.is_click = true;
	if (!("is_show" in arg)) arg.is_show = false;
	return arg;
};

const StatisticPage = ({ title, mainLoader }) => {
	let navigate = useNavigate();
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [statisticOrderType, setStatisticOrderType] = useState(0); // 0 - типы, 1 - видео, 2 - зрители, 3 - рекламодатели
	const [page, setPage] = useState(1);

	const [paramsState, setParamsState] = useState({
		id: searchParams.get("id") !== null ? searchParams.get("id") : null,
		filters:
			searchParams.get("filters") !== null
				? JSON.parse(searchParams.get("filters"))
				: {
						area: [1],
				  },
		// {
		// 		from_datetime: "2021-01-01T00:00:00.878Z",
		// 		to_datetime: new Date().toISOString(),
		//   },
		// sideFilters: {is_show: false, is_click: false},
		sellerName: searchParams.get("sellerName") !== null ? searchParams.get("sellerName") : null,
		page: 1,
	});

	// const {tickets, isLoadingTickets} = useGetTickets({
	// 	from_datetime: paramsState.filters?.from_datetime,
	// 	to_datetime: paramsState.filters?.to_datetime,
	// });

	const lte = paramsState.filters?.to_datetime || new Date().toISOString();
	const gte = paramsState.filters?.from_datetime || "2022-03-01T03:10:34.000Z";
	const owner_id = 1;
	const seller_id = Number(getSellerId()); // Number(getSellerId());

	const isClickFilterOn =
		paramsState.filters?.area?.includes(1) && paramsState.filters?.area?.length === 1;
	const isShowFilterOn =
		paramsState.filters?.area?.includes(2) && paramsState.filters?.area?.length === 1;

	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_STATISTIC, {
		variables: {
			page: page,
			limit: 20,
			user_id: 1,
			lte,
			gte,
			format_id: null,
			category_id: null,
			seller_id,
			order_by: "create_date",
			direction: "DESC",
			sex_id: null,
			age_id: null,
			country_id: null,
			city_id: null,
			click: isClickFilterOn ? 1 : isShowFilterOn ? 0 : null,
			video_id: null,
			genre_id: null,
		},
	});

	const pageInfo = data?.getStatisticPage?.pageInfo;
	const newStatistics = data?.getStatisticPage?.items;
	const totalSum = data?.getStatisticPage?.aggregation?.total_sum;

	const actions = newStatistics;

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setParamsState({
					id: params.get("id") !== null ? params.get("id") : null,
					filters: params.get("filters") !== null ? JSON.parse(params.get("filters")) : {},
					seller: params.get("seller") !== null ? params.get("seller") : null,
					sellerName: params.get("sellerName") !== null ? params.get("sellerName") : null,
				});
				if (
					!searchParams.get("sellerId") &&
					!searchParams.get("sellerName") &&
					!searchParams.get("id") &&
					statisticOrderType !== -1
				) {
					setStatisticOrderType(0);
				}
			}
		});
	}, [history]);

	const [activeColumnState, setActiveColumnState] = useState(initialColumnState);
	const [rightBarActiveCheck, setRightBarActiveCheck] = useState({
		active: false,
		name: "",
	});

	useEffect(() => {
		switch (statisticOrderType) {
			case -1:
				setActiveColumnState(initialColumnState);
				break;
			case 0:
				setActiveColumnState(initialColumnState);
				break;
			case 1:
				setActiveColumnState(initialVideoState);
				break;
			case 2:
				setActiveColumnState(initialViewerState);
				break;
			case 3:
				setActiveColumnState(initialSellerState);
				break;
			default:
				break;
		}
	}, [statisticOrderType]);

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		const filteredState = removeEmpty({ ...paramsState });
		const filteredFilters = removeEmpty(filteredState?.filters);

		if (!filteredState.hasOwnProperty("sideFilters") && Object.keys(filteredState).length !== 0) {
			const resultFilters = normalizeFilters({ ...filteredState, filters: filteredFilters });
			getStatistic({
				variables: {
					page: page,
					limit: 20,
					user_id: 1,
					lte,
					gte,
					format_id: null,
					category_id: null,
					seller_id,
					order_by: resultFilters.order_by ? resultFilters.order_by : "create_date",
					direction: resultFilters.reversed_ ? "ASC" : "DESC",
					sex_id: null,
					age_id: null,
					country_id: null,
					city_id: null,
					click: isClickFilterOn ? 1 : isShowFilterOn ? 0 : null,
					video_id: null,
					genre_id: null,
				},
			});

			if (!firstLoad && lastSearch) {
				setSearchParams({
					...filteredState,
					filters: JSON.stringify({ ...filteredFilters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		} else {
			if (filteredState.hasOwnProperty("sideFilters")) {
				const filteredSideFilters = removeEmpty(filteredState?.sideFilters);
				// getStatistic({
				// 	...filteredState,
				// 	filters: filteredFilters,
				// 	sideFilters: filteredSideFilters,
				// });
				delete filteredState.sideFilters;
			}
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...filteredState,
					filters: JSON.stringify({ ...filteredFilters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		}
	}, [paramsState, page]);

	const handleDowloadLinkClick = () => {
		const linkString = searchParams.toString();
		window.open(`https://atips.io/export?${linkString}`);
	};
	return (
		<section className="personal-area-item statistic">
			{loading && <Loader />}

			{!!searchParams.get("id") ||
			(searchParams.get("seller") && searchParams.get("sellerName")) ? (
				<button
					className="form-page-back statistic-button"
					onClick={() => (navigate(-1), setParamsState({ id: null }))}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
			) : null}
			<header className="personal-area categories-header list-title-text statistic-header">
				{!!searchParams.get("id") ? (
					<h1 className="current-statistic">{`Статистика типса ID ${searchParams.get("id")}`}</h1>
				) : searchParams.get("seller") && searchParams.get("sellerName") ? (
					<h1 className="current-statistic">{`Статистика ${searchParams.get("sellerName")}`}</h1>
				) : (
					<>
						<h1>
							<span>Статистика</span>
							<span className="list-title-text_counter">{pageInfo?.itemsCount || 0}</span>
							{/* <sup>{totalRows ? totalRows : 0}</sup> */}
						</h1>
					</>
				)}
				{/* <button
					className='button_add-category'
					onClick={() => handleDowloadLinkClick()}>
					<img src='images/icons/ui/files/white/upload.svg' alt='плюс' />
					Выгрузить
				</button> */}
			</header>

			<div className="videos-content-wrapper content-wrapper">
				<StatisticFiltersComponent
					filterState={paramsState}
					setFilterState={(state) => {
						setPage(1);
						setParamsState(state);
					}}
				/>
				<div id="statistics-filter-buttons">
					{/* <div
						className='statistics-all-filter-button action-button'
						onClick={() =>
							setRightBarActiveCheck({
								active: true,
								name: "filter",
							})
						}>
						Все фильтры
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M15 11L15 8L2 8V6L15 6V3H17V6L22 6V8L17 8V11H15ZM7 18V21H9V18H22L22 16L9 16L9 13H7L7 16H2V18H7Z'
								fill='#212121'></path>
						</svg>
					</div> */}
					<div
						className="statistics-column-button action-button"
						onClick={() =>
							setRightBarActiveCheck({
								active: true,
								name: "column",
							})
						}
					>
						Управление столбцами
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z"
								fill="#212121"
							></path>
						</svg>
					</div>
				</div>
				<StatisticTitlesComponent
					activeColumnState={activeColumnState}
					paramsState={paramsState}
					setParamsState={setParamsState}
				/>
				<StatisticContent
					activeColumnState={activeColumnState}
					paramsState={paramsState}
					setParamsState={setParamsState}
					setStatisticOrderType={setStatisticOrderType}
					isLoading={mainLoader}
					statisticList={{ list: actions }}
					actions={actions}
				/>
				{/* {statisticOrderType === 0 || statisticOrderType === -1 ? (
					<StatisticContent
						activeColumnState={activeColumnState}
						paramsState={paramsState}
						setParamsState={setParamsState}
						setStatisticOrderType={setStatisticOrderType}
						isLoading={mainLoader}
						statisticList={{list: actions}}
						actions={actions}
					/>
				) : statisticOrderType === 1 ? (
					<StatisticVideoContent
						activeColumnState={activeColumnState}
						paramsState={paramsState}
						setParamsState={setParamsState}
						setStatisticOrderType={setStatisticOrderType}
						isLoading={mainLoader}
						actions={actions}
					/>
				) : statisticOrderType === 2 ? (
					<StatisticViewerContent
						activeColumnState={activeColumnState}
						paramsState={paramsState}
						setParamsState={setParamsState}
						setStatisticOrderType={setStatisticOrderType}
						isLoading={mainLoader}
						statisticList={{list: actions}}
						actions={actions}
					/>
				) : (
					<StatisticSellerContent
						activeColumnState={activeColumnState}
						paramsState={paramsState}
						setParamsState={setParamsState}
						setStatisticOrderType={setStatisticOrderType}
						isLoading={mainLoader}
						statisticList={{list: actions}}
						actions={actions}
					/>
				)} */}
			</div>
			<StatisticEditColumnSlide
				status={rightBarActiveCheck}
				setStatus={setRightBarActiveCheck}
				columns={activeColumnState}
				setColumns={setActiveColumnState}
			/>
			<StatisticEditFilterSlide
				status={rightBarActiveCheck}
				setStatus={setRightBarActiveCheck}
				paramsState={paramsState}
				setParamsState={(state) => {
					setPage(1);
					setParamsState(state);
				}}
			/>
			<div
				className={`statistics-filter-bg ${rightBarActiveCheck.active ? "active" : ""}`}
				onClick={() => setRightBarActiveCheck(false, "")}
			></div>

			<PaginationBlock
				page={page}
				total={Math.ceil(pageInfo?.itemsCount / 20)}
				setPage={(pageNumber) => {
					setPage(pageNumber);
				}}
			/>

			<div id="fixed-statistics-itog" className="animte-menu">
				<div className="fixed-staistics-data">
					<span>Всего: {pageInfo?.itemsCount}</span>
					<span>{`Сумма: ${numberWithSpaces(totalSum || 0)} ₽`}</span>
				</div>
				<div className="fixed-staistics-data">
					<span>
						{String(formatDate(new Date("2022/06/1").getTime()))} —{" "}
						{formatDate(new Date().getTime())}
					</span>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		statisticList: state.statisticList,
		mainLoader: state.mainLoader,
	};
};

const mapDispatchToProps = {
	getStatistic,
	getBrightboxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticPage);
