import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import {connect} from "react-redux";
import Authorization from "../components/Index/Authorization.jsx";

import Registration from "../components/Index/Registration.jsx";
import PrivacyPage from "../components/Index/PrivacyPage";
import LegalPage from "../components/Index/LegalPage";
import PersonalPolicyPage from "../components/Index/PersonalPolicyPage";

import {ModalProvider} from "../components/modal/ModalContext/ModalContext";
import "../../public/fonts.css";
import {PrivateRoute} from "./PrivateRoute.tsx";
import {ChechAuth} from "./ChechAuth.tsx";

import PersonalArea from "../components/sellerArea/PersonalArea.jsx";
import {AsideToggleProvider} from "../components/sellerArea/context/AsideToggleContext.js";
import "../styles/allStyles.scss";
// import {history} from "../Store/Store";
// TODO
// страницу услуги
// refetch after product create
// refetch after add create

// delete product
// update icon button

// приостановка рекламы
const App = ({login}) => {
	return (
		<Router>
			<ModalProvider>
				<AsideToggleProvider>
					<Routes>
						<Route
							exact
							path='*'
							element={
								<PrivateRoute login={{token: localStorage.getItem("token")}} />
							}>
							<Route exact path='*' login={login} element={<PersonalArea />} />
							{/* <div>HEY</div> */}
						</Route>

						<Route path='/auth/complete' element={<Authorization />} />
						<Route exact path='/privacy' element={<PrivacyPage />} />
						<Route exact path='/legal' element={<LegalPage />} />
						<Route exact path='/personal' element={<PersonalPolicyPage />} />

						<Route
							exact
							element={
								<ChechAuth login={{token: localStorage.getItem("token")}} />
							}>
							<Route exact path='/sign-in' element={<Authorization />} />
							<Route exact path='/sign_up' element={<Registration />} />
							<Route path='/reset-password' element={<Authorization />} />
							<Route path='/forgot-password' element={<Authorization />} />{" "}
						</Route>
					</Routes>
				</AsideToggleProvider>
			</ModalProvider>
		</Router>
	);
};

const mapStateToProps = (state) => {
	return {
		login: state.login,
	};
};

export default connect(mapStateToProps, null)(App);

// TODO
// обновление пользователя
// смена пароля
// забыл пароль
