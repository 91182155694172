import { useBaseQuery } from "../../Actions/shared";
import { Product, Options } from "../types";
import { groupProductAttrs } from "./index";
import { useGetPlatforms } from "../shared/useGetPlatforms";

type useGetProductProps = Options & { id: any };

export const useGetProduct = ({ id, ...props }: useGetProductProps) => {
	const { data, ...query } = useBaseQuery<{ data: Product }>({
		url: `/product/${id}`,
		queryKey: [`product`, id],
		refetchOnMount: true,
		...props,
	});
	const product = data?.data;
	const attr = product?.attributes_settable;
	const link = product?.URL_SOURCE;
	const atrrs = (attr && groupProductAttrs(attr)) || {};
	const pred_attr = attr ? attr.filter((elem) => elem.frame_id <= 2 || elem.frame_id > 13) : [];
	return {
		product: product
			? {
				...product,
				...atrrs,
				attributes_settable: pred_attr,
				isProduct: product?.section === "Категории товаров",
			}
			: null,
		isProduct: product?.section === "Категории товаров",
		refetchProduct: query.refetch,
		...query,
	};
};
