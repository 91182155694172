export const initialSideFiltersState = {
    category: null,
    genres: null,
    gender: null,
    ageFrom: null,
    ageTo: null,
    country: null,
    region: null,
    is_show: null,
    is_click: null,

};